import React, { useState, useEffect, useRef } from "react";
import { Avatar, Modal, Checkbox, Tooltip, Row, Col, Form, Input, Space, Spin } from "antd";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import FeatherIcon from "feather-icons-react";
import { InfoWraper, NavAuth, UserDropDwon } from "./auth-info-style";
import Message from "./message";
import Notification from "./notification";
import { Popover } from "../../popup/popup";
import Support from "./support";
import { Dropdown } from "../../dropdown/dropdown";
import { logOut, LockRegister } from "../../../redux/authentication/actionCreator";
import Heading from "../../heading/heading";
import { Button } from "../../buttons/buttons";
import { getAllRegisterList, SwitchRegister } from "../../../redux/register/actionCreator";
import { getItem, setItem } from "../../../utility/localStorageControl";
import { QuestionCircleOutlined, CloseOutlined, PlusOutlined, DeleteOutlined, UserOutlined, LoadingOutlined } from "@ant-design/icons";
import { getApplicationById } from "../../../redux/applicatons/actionCreator";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AuthInfo = ({}) => {
    let isMounted = useRef(true);
    const dispatch = useDispatch();
    const history = useHistory();
    const { path } = useRouteMatch();
    const [RegisterListData, setRegisterListData] = useState([]);
    const [modalVisible, setModelVisible] = useState(false);
    const [modalVisibleLock, setModelVisibleLock] = useState(false);
    const [RegisterId, setRegisterId] = useState("");
    const offLineMode = useSelector((state) => state.auth.offlineMode);
    const [ActiveRegister, setActiveRegister] = useState("");
    const userDetails = getItem("userDetails");
    const username = getItem("username");
    const role = getItem("role");
    const [showForm, setShowForm] = React.useState(false);
    const [offLineModeCheck, setOfflineModeCheck] = useState(false);
    const [show, setShow] = React.useState(false);
    const [denominationValueArray, setDenominationValueArray] = useState([]);
    const [denominationCountArray, setDenominationCountArray] = useState([]);
    const [finalTotal, setFinalTotal] = useState("");
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [type, setType] = useState("normal");
    const [shiftCloseLoader, setShiftClose] = useState(false);
    let pathName = window.location.pathname;
    if (pathName.split(":").length > 1) {
        pathName = pathName.split(":")[1];
    }
    useEffect(() => {
        if (userDetails) {
            dispatch(getAllRegisterList("sell"));
        }
    }, []);

    const { RegisterList, hotelRegisterList } = useSelector(
        (state) => ({
            RegisterList: state.register.RegisterList.filter((val) => (pathName == "/app/appstore" ? val : val.type != "hotel")),
            hotelRegisterList: state.register.RegisterList.filter((val) => val.type == "hotel"),
        }),
        shallowEqual
    );
    const [RegisterName, setRegisterName] = useState("");
    useEffect(() => {
        if (RegisterList?.length) {
            setRegisterName(RegisterList?.find((val) => val.active)?.register_name);
        }
    }, [RegisterList]);
    const [state, setState] = useState({
        flag: "english",
    });
    const { flag } = state;

    const SignOut = (e) => {
        if (offLineMode) {
            setOfflineModeCheck(true);
            return;
        }
        e.preventDefault();
        dispatch(logOut(history));
    };

    const HandleswitchRegister = async () => {
        setLoading(true);
        if (type == "normal") {
            localStorage.removeItem("active_cart");
        }
        let localDataDetails = getItem("setupCache");
        if (localDataDetails != null && localDataDetails.register) {
            localDataDetails.register = localDataDetails.register.map((value) => {
                if (value._id == RegisterId) {
                    if (type == "hotel") {
                        value.activeHotel = true;
                    } else {
                        value.active = true;
                    }
                } else {
                    if (type == "hotel") {
                        value.activeHotel = false;
                    } else {
                        value.active = false;
                    }
                }
                return value;
            });
            setItem("setupCache", localDataDetails);

            if (type == "normal") {
                let curretAppData = await dispatch(getApplicationById({ _id: RegisterId }));
                if (curretAppData) {
                    if (curretAppData.bingage_enable) {
                        setItem("bingage_enable", curretAppData.bingage_enable.enable);
                    }
                    if (curretAppData.create_receipt_while_fullfilling_booking) {
                        setItem("create_receipt_while_fullfilling_booking", curretAppData.create_receipt_while_fullfilling_booking.enable);
                    }
                    if (curretAppData.dyno_api_enable) {
                        setItem("dyno_api_enable", curretAppData.dyno_api_enable.enable);
                    }
                    if (curretAppData.hotel_enable) {
                        setItem("hotel_enable", curretAppData.hotel_enable.enable);
                    }
                    if (curretAppData.pettycash_enable) {
                        setItem("pettycash_enable", curretAppData.pettycash_enable.enable);
                    }
                    if (curretAppData.pricebook_enable) {
                        setItem("pricebook_enable", curretAppData.pricebook_enable.enable);
                    }
                    if (curretAppData.qrCode_enable) {
                        setItem("qrCode_enable", curretAppData.qrCode_enable.enable);
                    }
                    if (curretAppData.waiter_app_enable) {
                        setItem("waiter_app_enable", curretAppData.waiter_app_enable.enable);
                    }
                    if (curretAppData.inventrory_app_enable) {
                        setItem("inventrory_app_enable", curretAppData.inventrory_app_enable.enable);
                    }
                    if (curretAppData.report_analytics) {
                        setItem("report_analytics", curretAppData.report_analytics.enable);
                    }
                } else {
                    setItem("bingage_enable", false);
                    setItem("inventrory_app_enable", false);
                    setItem("create_receipt_while_fullfilling_booking", false);
                    setItem("dyno_api_enable", false);
                    setItem("hotel_enable", false);
                    setItem("pettycash_enable", false);
                    setItem("pricebook_enable", false);
                    setItem("qrCode_enable", false);
                    setItem("waiter_app_enable", false);
                    setItem("report_analytics", false);
                }
                const ApiCalling = await dispatch(getAllRegisterList());
                if (ApiCalling) {
                    setLoading(false);
                }
                if (getItem("localReceipt") === true) {
                    setItem("isStartSellingFromThisDevice", true);
                }
                if (getItem("waiter_app_enable") || getItem("qrCode_enable")) {
                    window.location.reload();
                }
            } else {
                const ApiCalling = await dispatch(getAllRegisterList());
                if (ApiCalling) {
                    setLoading(false);
                }
            }

            setModelVisible(false);
        }
    };

    const handleCancel = (e) => {
        setModelVisible(false);
        setModelVisibleLock(false);
    };

    const showModal = (id = "", type) => {
        setType(type);
        let obj = type == "hotel" ? hotelRegisterList.find((o) => o._id === id) : RegisterList.find((o) => o._id === id);
        setRegisterName(obj.register_name);
        if (id && id != "") {
            setRegisterId(id);
        }
        setModelVisible(true);
    };

    const HandleEndShift = () => {
        if (showForm !== true) {
            setShowForm(true);
        } else {
            setShowForm(false);
        }
    };

    const userContent = (
        <UserDropDwon
            onClick={(e) => {
                e.preventDefault();
                setShow(false);
            }}
            onBlur={() => {
                setShow(false);
            }}
        >
            <div className="user-dropdwon">
                <figure className="user-dropdwon__info">
                    <Avatar
                        src={getItem("setupCache") && getItem("setupCache").shopDetails && getItem("setupCache").shopDetails.shop_logo}
                        size={50}
                        icon={<UserOutlined />}
                        style={{ marginLeft: "1px" }}
                    />

                    <figcaption style={{ margin: "0 10px" }}>
                        <Heading as="h5">{username}</Heading>
                        <p>{role === "restaurant" ? "Admin" : role?.toUpperCase()}</p>
                    </figcaption>
                </figure>
                <ul className="user-dropdwon__links">
                    {/* {userDetails && userDetails.is_shop && userDetails.role === "restaurant" ? (
                        <li>
                            <NavLink to={`${path}settings/shop`}>
                                <FeatherIcon icon="settings" /> Settings
                            </NavLink>
                        </li>
                    ) : (
                        ""
                    )} */}
                    <li>
                        <NavLink to={`${path}billing`}>
                            <FeatherIcon icon="dollar-sign" /> Billing
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="#" onClick={() => window.open("https://help.posease.com/")}>
                            <FeatherIcon icon="bell" /> Help
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to="#" onClick={() => setModelVisibleLock(true)}>
                            <FeatherIcon icon="lock" /> Lock Register
                        </NavLink>
                    </li>
                </ul>
                {userDetails?.role === "cashier" ? (
                    ""
                ) : (
                    <NavLink className="user-dropdwon__bottomAction" onClick={SignOut} to="#">
                        <FeatherIcon icon="log-out" /> Sign Out
                    </NavLink>
                )}
            </div>
        </UserDropDwon>
    );

    let registercontent = "";
    let active_register = "";
    if (RegisterList.length > 0) {
        registercontent = RegisterList.map((value, i) => {
            if (value.active === true) {
                active_register += value.register_name;
            }
            return (
                <>
                    <NavAuth key={value._id}>
                        <NavLink
                            onClick={RegisterList.length !== 1 && value.register_name !== active_register ? showModal.bind("", value._id, "normal") : ""}
                            to="#"
                        >
                            <span>{value.register_name}</span>
                            <span style={{ display: "none" }} data-id={value._id}>
                                {value._id}
                            </span>
                        </NavLink>
                    </NavAuth>
                </>
            );
        });
    }
    let hotelregistercontent = "";
    let hotel_active_register = "";
    if (hotelRegisterList.length > 0) {
        hotelregistercontent = hotelRegisterList.map((value, i) => {
            if (value.activeHotel === true) {
                hotel_active_register += value.register_name;
            }
            return (
                <>
                    <NavAuth key={value._id}>
                        <NavLink
                            onClick={
                                hotelRegisterList.length !== 1 && value.register_name !== hotel_active_register ? showModal.bind("", value._id, "hotel") : ""
                            }
                            to="#"
                        >
                            <span>{value.register_name}</span>
                            <span style={{ display: "none" }} data-id={value._id}>
                                {value._id}
                            </span>
                        </NavLink>
                    </NavAuth>
                </>
            );
        });
    }
    const denominationValue = (event, index) => {
        denominationValueArray[index + 1] = event.target.value;
        setDenominationValueArray([...denominationValueArray]);
    };

    const denominationCount = (event, index) => {
        denominationCountArray[index + 1] = event.target.value;
        setDenominationCountArray([...denominationCountArray]);
    };

    useEffect(() => {
        calculateFinalValue(denominationValueArray, denominationCountArray);
    }, [denominationValueArray]);

    useEffect(() => {
        calculateFinalValue(denominationValueArray, denominationCountArray);
    }, [denominationCountArray]);

    const calculateFinalValue = (denominationValueArray, denominationCountArray) => {
        let getTotalOfCount = 0;
        for (let i = 0; i < denominationValueArray.length; i++) {
            let denominationValue = denominationValueArray[i] ? +denominationValueArray[i] : 1;
            let denominationCount = denominationCountArray[i] ? +denominationCountArray[i] : 1;
            getTotalOfCount = getTotalOfCount + denominationValue * denominationCount;

            if (getTotalOfCount != 1) {
                setFinalTotal(getTotalOfCount);
            }
        }
    };

    const removeRowForCalculation = (index) => {
        denominationValueArray.splice(index + 1, 1);
        setDenominationValueArray([...denominationValueArray]);
        denominationCountArray.splice(index + 1, 1);
        setDenominationCountArray([...denominationCountArray]);
    };

    const handleSubmit = async () => {
        if (shiftCloseLoader == false) {
            setShiftClose(true);
            let response = await dispatch(LockRegister(history, finalTotal, username));
            if (response) {
                setShiftClose(false);
            }
        }
    };

    useEffect(() => {
        document.documentElement.scrollTop = 0;
    }, [pathName]);

    const HotelRegisterShow = () => {
        return (
            <Dropdown placement="bottomRight" content={<div style={{ maxHeight: "45vh", overflow: "auto" }}>{hotelregistercontent}</div>} action="click">
                <NavLink to="#" className="head-example">
                    <Button
                        type="default"
                        size="small"
                        // className="auth-box-button"
                        disabled={userDetails && userDetails.role == "cashier" ? true : false}
                        className={userDetails && userDetails.role == "cashier" ? "auth-box-button" : getItem("dark_mode") ? "darks_btnThe" : "emyty"}
                        style={
                            userDetails && userDetails.role == "cashier"
                                ? { color: "black", background: "#f4f5f700" }
                                : getItem("dark_mode")
                                ? { background: "#272b41", color: "#A8AAB3" }
                                : {}
                        }
                    >
                        <FeatherIcon
                            icon="arrow-down-circle"
                            style={
                                userDetails && userDetails.role == "cashier"
                                    ? getItem("dark_mode")
                                        ? {
                                              color: "#f5f5f5",
                                              background: "#f4f5f700",
                                              marginLeft: "3px",
                                          }
                                        : {}
                                    : getItem("dark_mode")
                                    ? {
                                          background: "#272b41",
                                          color: "#A8AAB3",
                                          marginLeft: "3px",
                                      }
                                    : { marginLeft: "3px" }
                            }
                        />{" "}
                        <span
                            style={
                                userDetails && userDetails.role == "cashier"
                                    ? getItem("dark_mode")
                                        ? {
                                              color: "#f5f5f5",
                                              background: "#f4f5f700",
                                              marginLeft: "3px",
                                          }
                                        : {}
                                    : getItem("dark_mode")
                                    ? {
                                          background: "#272b41",
                                          color: "#A8AAB3",
                                          marginLeft: "3px",
                                      }
                                    : { marginLeft: "3px" }
                            }
                        >
                            {hotel_active_register}
                        </span>{" "}
                    </Button>
                </NavLink>
            </Dropdown>
        );
    };

    const POSRegisterShow = () => {
        return (
            <Dropdown placement="bottomRight" content={<div style={{ maxHeight: "45vh", overflow: "auto" }}>{registercontent}</div>} action="click">
                <NavLink to="#" className="head-example">
                    <Button
                        type="default"
                        size="small"
                        // className="auth-box-button"
                        disabled={userDetails && userDetails.role == "cashier" ? true : false}
                        className={userDetails && userDetails.role == "cashier" ? "auth-box-button" : getItem("dark_mode") ? "darks_btnThe" : "emyty"}
                        style={
                            userDetails && userDetails.role == "cashier"
                                ? { color: "black", background: "#f4f5f700" }
                                : getItem("dark_mode")
                                ? { background: "#272b41", color: "#A8AAB3" }
                                : {}
                        }
                    >
                        <FeatherIcon
                            icon="arrow-down-circle"
                            style={
                                userDetails && userDetails.role == "cashier"
                                    ? getItem("dark_mode")
                                        ? {
                                              color: "#f5f5f5",
                                              background: "#f4f5f700",
                                              marginLeft: "3px",
                                          }
                                        : {}
                                    : getItem("dark_mode")
                                    ? {
                                          background: "#272b41",
                                          color: "#A8AAB3",
                                          marginLeft: "3px",
                                      }
                                    : { marginLeft: "3px" }
                            }
                        />{" "}
                        <span
                            style={
                                userDetails && userDetails.role == "cashier"
                                    ? getItem("dark_mode")
                                        ? {
                                              color: "#f5f5f5",
                                              background: "#f4f5f700",
                                              marginLeft: "3px",
                                          }
                                        : {}
                                    : getItem("dark_mode")
                                    ? {
                                          background: "#272b41",
                                          color: "#A8AAB3",
                                          marginLeft: "3px",
                                      }
                                    : { marginLeft: "3px" }
                            }
                        >
                            {active_register}
                        </span>{" "}
                    </Button>
                </NavLink>
            </Dropdown>
        );
    };
    return (
        <InfoWraper>
            <Modal
                title="You are Offline"
                visible={offLineModeCheck}
                onOk={() => setOfflineModeCheck(false)}
                onCancel={() => setOfflineModeCheck(false)}
                width={600}
            >
                <p>You are not allowd to sign out while you are offline.</p>
            </Modal>
            <Modal
                title="Lock Register"
                visible={modalVisibleLock}
                onOk={form.submit}
                onCancel={handleCancel}
                width={600}
                okText={
                    shiftCloseLoader ? (
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 16,
                                        color: "white",
                                        margin: "0px 8px",
                                    }}
                                    spin
                                />
                            }
                        />
                    ) : (
                        "Save"
                    )
                }
            >
                <Form autoComplete="off" form={form} size="large" onFinish={handleSubmit}>
                    <p>Once you lock the register you need the Owner / Cashier / App User PIN to unlock, do you want to continue?</p>
                    <div className="auth-form-action">
                        <Checkbox onChange={HandleEndShift} style={{ marginBottom: 10 }}>
                            End shift for {RegisterName} &nbsp;
                            <Tooltip title="Trackig shifts will report sales and payments during a cashier shift and useful to verify cash balance.">
                                <QuestionCircleOutlined style={{ cursor: "pointer" }} />
                            </Tooltip>
                        </Checkbox>
                    </div>
                    {showForm === true ? (
                        <>
                            <Row>
                                <Col xs={24} xl={10} lg={10} className="gutter-box">
                                    <Form.Item name="denomination_value" id="denomination_value">
                                        <Input
                                            type="number"
                                            min={0}
                                            initialValue={0}
                                            placeholder="Denomination Value"
                                            onChange={(e) => denominationValue(e, -1)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Space></Space>
                                <Col xs={24} xl={2} lg={2} className="gutter-box">
                                    <Form.Item className="action-class">
                                        <CloseOutlined />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} xl={10} lg={10} className="gutter-box">
                                    <Form.Item name="denomination_count">
                                        <Input
                                            type="number"
                                            id="denomination_count"
                                            min={0}
                                            initialValue={0}
                                            placeholder="Denomination count"
                                            onChange={(e) => denominationCount(e, -1)}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} xl={2} lg={2} className="gutter-box"></Col>
                            </Row>
                            <Form.List name="start">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map((field, index) => (
                                            <Row key={field.key} style={{ marginBottom: 8 }}>
                                                <Form.Item
                                                    noStyle
                                                    shouldUpdate={(prevValues, curValues) =>
                                                        prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                    }
                                                >
                                                    {() => (
                                                        <>
                                                            <Col xs={24} xl={10} lg={10} className="gutter-box">
                                                                <Form.Item
                                                                    {...field}
                                                                    name={[field.name, "denomination_value"]}
                                                                    fieldKey={[field.fieldKey, "denomination_value"]}
                                                                >
                                                                    <Input
                                                                        placeholder="Denomination Value"
                                                                        type="number"
                                                                        min={0}
                                                                        initialValue={0}
                                                                        onChange={(e) => denominationValue(e, index)}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} xl={2} lg={2} className="gutter-box">
                                                                <Form.Item className="action-class">
                                                                    <CloseOutlined />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} xl={10} lg={10} className="gutter-box">
                                                                <Form.Item
                                                                    {...field}
                                                                    fieldKey={[field.fieldKey, "Denomination_count"]}
                                                                    name={[field.name, "Denomination_count"]}
                                                                >
                                                                    <Input
                                                                        placeholder="Denomination Count"
                                                                        type="number"
                                                                        min={0}
                                                                        initialValue={0}
                                                                        onChange={(e) => denominationCount(e, index)}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                            <Col xs={24} xl={2} lg={2} className="gutter-box">
                                                                <Form.Item {...field} className="action-class">
                                                                    <DeleteOutlined
                                                                        onClick={() => {
                                                                            removeRowForCalculation(index);
                                                                            remove(field.name);
                                                                        }}
                                                                    />
                                                                </Form.Item>
                                                            </Col>
                                                        </>
                                                    )}
                                                </Form.Item>
                                            </Row>
                                        ))}
                                        <div style={{ marginLeft: 9 }}>
                                            {/* <Form.Item> */}
                                            <NavLink
                                                type="link"
                                                to="#"
                                                style={{
                                                    color: "#008cba",
                                                    border: "none",
                                                }}
                                                onClick={() => add()}
                                            >
                                                Add Denomination
                                            </NavLink>
                                            {/* </Form.Item> */}
                                        </div>
                                    </>
                                )}
                            </Form.List>
                            <Form.Item>
                                <Col xs={24} xl={24} lg={24} className="gutter-box">
                                    <Form.Item
                                        name="final_value"
                                        rules={
                                            finalTotal === ""
                                                ? [
                                                      {
                                                          message: "Closing cash balance is required to end a shift.",
                                                          required: true,
                                                      },
                                                  ]
                                                : ""
                                        }
                                    >
                                        <p style={{ display: "none" }}>{finalTotal}</p>
                                        <Input
                                            type="number"
                                            value={finalTotal}
                                            placeholder={finalTotal != "" ? finalTotal : "Enter closing cash balance"}
                                            onChange={(e) => setFinalTotal(e.target.value)}
                                        />
                                    </Form.Item>
                                </Col>
                            </Form.Item>
                        </>
                    ) : (
                        ""
                    )}
                </Form>
            </Modal>
            <Modal
                title={"Switch to " + RegisterName}
                okText={
                    loading ? (
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    style={{
                                        fontSize: 16,
                                        color: "white",
                                        margin: "0px 23px",
                                    }}
                                    spin
                                />
                            }
                        />
                    ) : (
                        "Switch Register"
                    )
                }
                visible={modalVisible}
                onOk={HandleswitchRegister}
                okButtonProps={{
                    disabled: getItem("enable_billing_only_when_shift_is_opened") ? true : false,
                }}
                onCancel={handleCancel}
                width={600}
            >
                <p>If you are in an active shift, you might want to end the shift before switching to another register.</p>
            </Modal>
            {userDetails && userDetails.role == "cashier"
                ? null
                : (pathName == "/app/appstore" ||
                      pathName == "/app/receipts" ||
                      pathName == "/app/products/pricebook" ||
                      pathName.includes("vendor") ||
                      pathName == "/app/pettycash" ||
                      pathName == "/app/sell") && <Support />}
            <div className="nav-author">
                {(pathName == "/app/reservation" ||
                    pathName == "/app/receipts" ||
                    pathName == "/app/reports/master" ||
                    pathName == "/app/housekeeping" ||
                    pathName == "/app/rate-inventory" ||
                    pathName.toString().includes("room")) &&
                    userDetails.business_type == "hotel_management" && (
                        <div style={{ maxHeight: "100px", overflow: "auto" }}>
                            {userDetails.role == "cashier" ? (
                                userDetails.casier_business_type == "hotel_management" ? (
                                    <HotelRegisterShow />
                                ) : (
                                    userDetails?.hotel_register_assigned_to && userDetails.allow_other_register && <HotelRegisterShow />
                                )
                            ) : (
                                <HotelRegisterShow />
                            )}
                        </div>
                    )}
                {(pathName == "/app/appstore" ||
                    pathName == "/app/receipts" ||
                    pathName == "/app/products/pricebook" ||
                    pathName == "/app/pettycash" ||
                    pathName == "/app/sell" ||
                    pathName.includes("vendor")) && (
                    <div>
                        {userDetails.business_type == "hotel_management" ? (
                            userDetails.role == "cashier" ? (
                                userDetails.casier_business_type == "food_and_drink" ? (
                                    <POSRegisterShow />
                                ) : (
                                    userDetails?.register_assigned_to && userDetails.allow_other_register && <POSRegisterShow />
                                )
                            ) : (
                                <POSRegisterShow />
                            )
                        ) : (
                            <POSRegisterShow />
                        )}
                    </div>
                )}
            </div>
            {/* <FeatherIcon
                icon="settings"
                style={
                    userDetails && userDetails.role == "cashier"
                        ? getItem("dark_mode")
                            ? {
                                  color: "#f5f5f5",
                                  background: "#f4f5f700",
                                  marginLeft: "3px",
                              }
                            : {}
                        : getItem("dark_mode")
                        ? {
                              background: "#272b41",
                              color: "#A8AAB3",
                              marginLeft: "3px",
                          }
                        : { marginLeft: "3px" }
                }
            /> */}

            {/* <Button
                type="default"
                size="small"
                className={userDetails && userDetails.role == "cashier" ? "auth-box-button" : getItem("dark_mode") ? "darks_btnThe" : "emyty"}
                style={
                    userDetails && userDetails.role == "cashier"
                        ? { color: "black", background: "#f4f5f700", padding: "0px 10px 0px" }
                        : getItem("dark_mode")
                        ? { background: "#272b41", color: "#A8AAB3", padding: "0px 10px 0px" }
                        : { padding: "0px 10px 0px" }
                }
                onClick={() => {
                    window.location.reload();
                }}
            >
                <FeatherIcon
                    icon="refresh-ccw"
                    style={
                        userDetails && userDetails.role == "cashier"
                            ? getItem("dark_mode")
                                ? {
                                      color: "#f5f5f5",
                                      background: "#f4f5f700",
                                      marginLeft: "3px",
                                  }
                                : {}
                            : getItem("dark_mode")
                            ? {
                                  background: "#272b41",
                                  color: "#A8AAB3",
                                  marginLeft: "3px",
                              }
                            : { marginLeft: "3px" }
                    }
                />
            </Button> */}

            {userDetails && userDetails.is_shop && userDetails.role === "restaurant" ? (
                <Button
                    type="default"
                    size="small"
                    className={userDetails && userDetails.role == "cashier" ? "auth-box-button" : getItem("dark_mode") ? "darks_btnThe" : "emyty"}
                    style={
                        userDetails && userDetails.role == "cashier"
                            ? { color: "black", background: "#f4f5f700", padding: "0px 10px 0px" }
                            : getItem("dark_mode")
                            ? { background: "#272b41", color: "#A8AAB3", padding: "0px 10px 0px" }
                            : { padding: "0px 10px 0px" }
                    }
                    onClick={() => {
                        history.push(`${path}settings/shop`);
                    }}
                >
                    <FeatherIcon
                        icon="settings"
                        style={
                            userDetails && userDetails.role == "cashier"
                                ? getItem("dark_mode")
                                    ? {
                                          color: "#f5f5f5",
                                          background: "#f4f5f700",
                                          marginLeft: "3px",
                                      }
                                    : {}
                                : getItem("dark_mode")
                                ? {
                                      background: "#272b41",
                                      color: "#A8AAB3",
                                      marginLeft: "3px",
                                  }
                                : { marginLeft: "3px" }
                        }
                    />
                </Button>
            ) : (
                ""
            )}
            <div className="nav-author">
                <Popover placement="bottomRight" content={userContent} trigger="click" visible={show}>
                    <NavLink
                        to="#"
                        className="head-example"
                        onClick={(e) => {
                            e.preventDefault();
                            setShow(true);
                        }}
                        onBlur={() => {
                            setShow(false);
                        }}
                        tabIndex={0}
                    >
                        <Avatar
                            src={getItem("setupCache") && getItem("setupCache").shopDetails && getItem("setupCache").shopDetails.shop_logo}
                            icon={<UserOutlined style={{ marginTop: "5px" }} />}
                        />
                    </NavLink>
                </Popover>
            </div>
        </InfoWraper>
    );
};

export default AuthInfo;
