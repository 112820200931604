import React, { Suspense, useEffect, useState, useRef } from "react";
import { Row, Col, Skeleton, Empty, Divider, Button, Calendar, Select, Modal, Descriptions, Spin } from "antd";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { getAlldashboradData } from "../../redux/dashboard/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { ViewSummary } from "./ViewSummary";
import commonFunction from "../../utility/commonFunctions";
import "./ecommerce.css";
import { DailyOverview } from "./overview/performance/DailyOverview";
import { WebsitePerformance } from "./overview/performance/WebsitePerformance";
import { TopSellingProduct } from "./overview/ecommerce/TopSellingProduct";
import { ReservationType } from "./overview/ecommerce/ReservationType";
import { getItem } from "../../utility/localStorageControl";
import { Figure7, BannerNormal } from "../Application/Style";
import { ArrowDownOutlined } from "@ant-design/icons";
import { useHistory, NavLink } from "react-router-dom";
import AvailabilityCalendar from "../hotelDashboard/AvailabilityCalendar";
import { getAvailability, getRevenueDashboard, getRoomDashBoardData } from "../../redux/reservation/actionCreator";
import { triggerFocus } from "antd/lib/input/Input";

const Ecommerce = () => {
    const dispatch = useDispatch();
    const { Option } = Select;

    const [roomDashBoardObj, setRoomDashBoardObj] = useState();
    const { currentRegisterData } = useSelector((state) => {
        return {
            currentRegisterData: state.register.RegisterList?.length > 0 && state.register.RegisterList?.find((val) => val.activeHotel),
        };
    });
    const { hotelRegisterList } = useSelector((state) => {
        return {
            hotelRegisterList: state.register.RegisterList?.length > 0 && state.register.RegisterList?.filter((val) => val.type == "hotel")?.reverse(),
        };
    });
    const [hotelId, setHotelId] = useState();

    const userDetails = getItem("userDetails");
    const [dashBoardData, setDashBoardData] = useState();
    let isMounted = useRef(true);
    const viewSummaryHideAndShow = useRef();
    const [modalVisible, setModelVisible] = useState({
        visible: false,
        date: "",
        hotelId: "",
    });
    const [dataTypeObj, setDatatypeObj] = useState({
        list: [],
        loader: false,
    });
    const getReservationDetils = async () => {
        let response = await dispatch(getRoomDashBoardData(userDetails));
        if (!response.error && response.data) {
            setRoomDashBoardObj(response.data);
        }
    };
    const [rsSymbol, setRsSymbol] = useState(
        getItem("setupCache")?.shopDetails?.rs_symbol
            ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)?.length > 0
                ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
                : getItem("setupCache").shopDetails.rs_symbol
            : "₹"
    );
    let [viewSummaryData, setViewSummaryData] = useState();
    useEffect(() => {
        async function fetchDashboardDetails() {
            const getDashboardData = await dispatch(getAlldashboradData());
            if (isMounted.current && getDashboardData && getDashboardData.dashboardDetails) {
                let check =
                    userDetails && userDetails.business_type && userDetails.business_type == "hotel_management"
                        ? userDetails.role == "cashier"
                            ? userDetails.casier_business_type == "hotel_management"
                                ? true
                                : userDetails.allow_other_register
                                ? true
                                : false
                            : true
                        : false;
                if (check) {
                    let register = userDetails.role == "cashier" ? userDetails.hotel_register_assigned_to : "allRegister";
                    let response = await dispatch(getRevenueDashboard("today", register));

                    if (response && response.data && response.data.totalRevenue && getDashboardData.dashboardDetails) {
                        getDashboardData.dashboardDetails.total_sales = getDashboardData.dashboardDetails.total_sales + response.data.totalRevenue;
                    }
                    if (response && response.data) {
                        getDashboardData.dashboardDetails.reservationtype = {
                            direct: response.data.direct,
                            ota: response.data.ota,
                            agent: response.data.agent,
                            corporate: response.data.corporate,
                        };
                    }
                }
                setDashBoardData({ ...getDashboardData.dashboardDetails });
            }
        }
        if (isMounted.current) {
            fetchDashboardDetails();

            getReservationDetils();
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    const checkAvailability = async (id, date) => {
        setDatatypeObj({ loader: true, list: [] });
        let reponse = await dispatch(getAvailability(date, id));
        if (reponse && reponse.data) {
            setDatatypeObj({
                loader: false,
                list: reponse.data,
            });
        }
    };

    const handleOpenModal = async (val) => {
        let date = commonFunction.convertToDate(new Date(val), "MMM DD, Y");
        let id = hotelRegisterList[0]?._id;
        setModelVisible({
            date: date,
            visible: true,
            hotelId: id,
        });
        checkAvailability(id, date);
    };

    const handleCancel = (e) => {
        setModelVisible({
            date: "",
            visible: false,
            hotelId: "",
        });
    };
    let PMSTopSelling = (
        <Row gutter={15}>
            <Col xxl={12} xl={12} lg={12} xs={24} md={24} className="need-help">
                <Suspense
                    fallback={
                        <Cards headless>
                            <Skeleton active />
                        </Cards>
                    }
                >
                    <ReservationType dashBoardDataDetails={dashBoardData} />
                </Suspense>
            </Col>
            <Col xxl={12} xl={12} lg={12} xs={24} md={24} className="need-help">
                <Suspense
                    fallback={
                        <Cards headless>
                            <Skeleton active />
                        </Cards>
                    }
                >
                    <TopSellingProduct dashBoardDataDetails={dashBoardData} />
                </Suspense>
            </Col>
        </Row>
    );
    let PMSOnlyTopSelling = (
        <Row gutter={15}>
            <Col xxl={24} xl={24} lg={12} xs={24} md={24} className="need-help">
                <Suspense
                    fallback={
                        <Cards headless>
                            <Skeleton active />
                        </Cards>
                    }
                >
                    <ReservationType dashBoardDataDetails={dashBoardData} />
                </Suspense>
            </Col>
        </Row>
    );

    let PMSOnlyRecent = (
        <Row gutter={15}>
            <Col xxl={24} xl={24} lg={12} xs={24} md={24} className="need-help">
                <div className="full-width-table to-sel-pad top_selltab">
                    <Cards title="Availability">
                        <div
                            style={{
                                border: "1px solid #d9d9d9",
                                borderRadius: 4,
                            }}
                        >
                            <Calendar fullscreen={false} mode="month" onSelect={handleOpenModal} />
                        </div>
                    </Cards>
                </div>
            </Col>
        </Row>
    );
    let PMSRecent = (
        <Row gutter={15}>
            <Col xxl={12} xl={12} lg={12} xs={24} md={24} className="need-help">
                <div className="full-width-table to-sel-pad top_selltab">
                    <Cards title="Availability">
                        <div
                            style={{
                                border: "1px solid #d9d9d9",
                                borderRadius: 4,
                            }}
                        >
                            <Calendar fullscreen={false} mode="month" onSelect={handleOpenModal} />
                        </div>
                    </Cards>
                </div>
            </Col>
            <Col xxl={12} xl={12} lg={12} xs={24} md={24} className="need-help">
                <div className="full-width-table to-sel-pad top_selltab">
                    <Cards title="Recent Activity ">
                        {dashBoardData && dashBoardData.recent_activity && dashBoardData.recent_activity.length == 0 ? (
                            <div
                                style={{
                                    display: "table",
                                    margin: "54px auto",
                                }}
                            >
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        ) : (
                            dashBoardData &&
                            dashBoardData.recent_activity &&
                            dashBoardData.recent_activity.map((item, index) => {
                                return (
                                    <>
                                        <div key={index} className="recent_activity">
                                            <Row className="recent_activity_title">
                                                <Col xxl={12} lg={12} md={12} xs={12}>
                                                    <h3>{item.action == "close" ? "Shift Closed" : "Shift Open"}</h3>
                                                </Col>
                                                <Col xxl={12} lg={12} md={12} xs={12}>
                                                    <div>
                                                        <small style={{ float: "right" }}>
                                                            {" "}
                                                            {commonFunction.convertToDate(item.actual_time, "MMM DD, Y, h:mm A")}
                                                        </small>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div>
                                                <small>
                                                    {item.register_id?.register_name} | {rsSymbol}
                                                    {Number(item.closing_balance != undefined ? item.closing_balance : item.opening_balance).toFixed(2)} by{" "}
                                                    {item.userName}
                                                </small>
                                                {item.action == "close" && (
                                                    <small
                                                        className="folat-right"
                                                        onClick={() => {
                                                            setViewSummaryData(item);
                                                            viewSummaryHideAndShow.current.showModal();
                                                        }}
                                                    >
                                                        View Summary
                                                    </small>
                                                )}
                                            </div>
                                            <Divider style={{ margin: "10px 0px" }} />
                                        </div>
                                    </>
                                );
                            })
                        )}
                    </Cards>
                </div>
            </Col>
        </Row>
    );
    const POSTopSelling = (
        <Col xxl={24} xl={24} lg={12} xs={24} md={24}>
            <Suspense
                fallback={
                    <Cards headless>
                        <Skeleton active />
                    </Cards>
                }
            >
                <TopSellingProduct dashBoardDataDetails={dashBoardData} />
            </Suspense>
        </Col>
    );

    const POSRecent = (
        <Col xxl={24} xl={24} lg={12} xs={24} md={24} className="need-help">
            <Suspense
                fallback={
                    <Cards headless>
                        <Skeleton active />
                    </Cards>
                }
            >
                <div className="full-width-table to-sel-pad top_selltab">
                    <Cards title="Recent Activity ">
                        {dashBoardData && dashBoardData.recent_activity && dashBoardData.recent_activity.length == 0 ? (
                            <div style={{ display: "table", margin: "54px auto" }}>
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            </div>
                        ) : (
                            dashBoardData &&
                            dashBoardData.recent_activity &&
                            dashBoardData.recent_activity.map((item, index) => {
                                return (
                                    <>
                                        <div key={index} className="recent_activity">
                                            <Row className="recent_activity_title">
                                                <Col xxl={12} lg={12} md={12} xs={12}>
                                                    <h3>{item.action == "close" ? "Shift Closed" : "Shift Open"}</h3>
                                                </Col>
                                                <Col xxl={12} lg={12} md={12} xs={12}>
                                                    <div>
                                                        <small style={{ float: "right" }}>
                                                            {" "}
                                                            {commonFunction.convertToDate(item.actual_time, "MMM DD, Y, h:mm A")}
                                                        </small>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div>
                                                <small>
                                                    {item.register_id?.register_name} | {rsSymbol}
                                                    {Number(item.closing_balance != undefined ? item.closing_balance : item.opening_balance).toFixed(2)} by{" "}
                                                    {item.userName}
                                                </small>
                                                {item.action == "close" && (
                                                    <small
                                                        className="folat-right"
                                                        onClick={() => {
                                                            setViewSummaryData(item);
                                                            viewSummaryHideAndShow.current.showModal();
                                                        }}
                                                    >
                                                        View Summary
                                                    </small>
                                                )}
                                            </div>
                                            <Divider style={{ margin: "10px 0px" }} />
                                        </div>
                                    </>
                                );
                            })
                        )}
                    </Cards>
                </div>
            </Suspense>
        </Col>
    );

    console.log("fasaasfa794719494791794", userDetails);
    let { business_type, role, casier_business_type } = userDetails;
    return (
        <>
            <Main
                className="padding-top-form mobilepad_frm"
                style={{
                    margin: "95px 0 0 0",
                }}
            >
                <Row justify="center" gutter={25} type="flex">
                    <Col xxl={12} xl={12} lg={12} xs={24} style={{ paddingBottom: "25px" }}>
                        <Suspense
                            fallback={
                                <Cards headless>
                                    <Skeleton active />
                                </Cards>
                            }
                        >
                            <DailyOverview dashBoardDataDetails={dashBoardData} roomDashBoard={roomDashBoardObj} />
                        </Suspense>
                    </Col>
                    <Col xxl={12} xl={12} lg={12} xs={24} style={{ paddingBottom: "25px" }}>
                        <Suspense
                            fallback={
                                <Cards headless>
                                    <Skeleton active />
                                </Cards>
                            }
                        >
                            <WebsitePerformance dashBoardData={dashBoardData} />
                        </Suspense>
                    </Col>
                    {userDetails && (userDetails.business_type == null || (userDetails.business_type && userDetails.business_type != "hotel_management")) && (
                        <Col xxl={12} xl={12} lg={12} xs={24} md={24}>
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton active />
                                    </Cards>
                                }
                            >
                                <TopSellingProduct dashBoardDataDetails={dashBoardData} />
                            </Suspense>
                        </Col>
                    )}
                    {userDetails && (userDetails.business_type == null || (userDetails.business_type && userDetails.business_type != "hotel_management")) && (
                        <Col xxl={12} xl={12} lg={12} xs={24} md={24} className="need-help">
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton active />
                                    </Cards>
                                }
                            >
                                <div className="full-width-table to-sel-pad top_selltab">
                                    <Cards title="Recent Activity ">
                                        {dashBoardData && dashBoardData.recent_activity && dashBoardData.recent_activity.length == 0 ? (
                                            <div style={{ display: "table", margin: "54px auto" }}>
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                            </div>
                                        ) : (
                                            dashBoardData &&
                                            dashBoardData.recent_activity &&
                                            dashBoardData.recent_activity.map((item, index) => {
                                                return (
                                                    <>
                                                        <div key={index} className="recent_activity">
                                                            <Row className="recent_activity_title">
                                                                <Col xxl={12} lg={12} md={12} xs={12}>
                                                                    <h3>{item.action == "close" ? "Shift Closed" : "Shift Open"}</h3>
                                                                </Col>
                                                                <Col xxl={12} lg={12} md={12} xs={12}>
                                                                    <div>
                                                                        <small style={{ float: "right" }}>
                                                                            {" "}
                                                                            {commonFunction.convertToDate(item.actual_time, "MMM DD, Y, h:mm A")}
                                                                        </small>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                            <div>
                                                                <small>
                                                                    {item.register_id?.register_name} | {rsSymbol}
                                                                    {Number(
                                                                        item.closing_balance != undefined ? item.closing_balance : item.opening_balance
                                                                    ).toFixed(2)}{" "}
                                                                    by {item.userName}
                                                                </small>
                                                                {item.action == "close" && (
                                                                    <small
                                                                        className="folat-right"
                                                                        onClick={() => {
                                                                            setViewSummaryData(item);
                                                                            viewSummaryHideAndShow.current.showModal();
                                                                        }}
                                                                    >
                                                                        View Summary
                                                                    </small>
                                                                )}
                                                            </div>
                                                            <Divider style={{ margin: "10px 0px" }} />
                                                        </div>
                                                    </>
                                                );
                                            })
                                        )}
                                    </Cards>
                                </div>
                            </Suspense>
                        </Col>
                    )}

                    {userDetails && userDetails.business_type && userDetails.business_type == "hotel_management" && (
                        <Col xxl={12} xl={24} lg={24} xs={24} md={24}>
                            {role == "cashier"
                                ? casier_business_type == "hotel_management"
                                    ? userDetails.allow_other_register
                                        ? PMSTopSelling
                                        : PMSOnlyTopSelling
                                    : userDetails.allow_other_register
                                    ? PMSTopSelling
                                    : POSTopSelling
                                : PMSTopSelling}
                        </Col>
                    )}
                    {userDetails && userDetails.business_type && userDetails.business_type == "hotel_management" && (
                        <Col xxl={12} xl={24} lg={24} xs={24} md={24} className="need-help">
                            <Suspense
                                fallback={
                                    <Cards headless>
                                        <Skeleton active />
                                    </Cards>
                                }
                            >
                                {role == "cashier"
                                    ? casier_business_type == "hotel_management"
                                        ? userDetails.allow_other_register
                                            ? PMSRecent
                                            : PMSOnlyRecent
                                        : userDetails.allow_other_register
                                        ? PMSRecent
                                        : POSRecent
                                    : PMSRecent}
                            </Suspense>
                        </Col>
                    )}
                </Row>
                {modalVisible.visible && (
                    <Modal
                        title={"Availability for " + modalVisible.date}
                        visible={modalVisible.visible}
                        onCancel={handleCancel}
                        onOk={handleCancel}
                        width={600}
                    >
                        <div>
                            <div
                                style={{
                                    textAlign: "center",
                                    alignContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Select
                                    style={{
                                        width: "50%",
                                        // marginBottom: 20,
                                        textAlign: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                    onChange={(val) => {
                                        setModelVisible({ ...modalVisible, hotelId: val });
                                        checkAvailability(val, modalVisible.date);
                                    }}
                                    value={modalVisible.hotelId}
                                >
                                    {hotelRegisterList?.reverse()?.map((val) => {
                                        return <Option value={val._id}>{val.register_name}</Option>;
                                    })}
                                </Select>
                            </div>

                            {dataTypeObj.loader ? (
                                <Spin
                                    style={{
                                        color: "#BD025D",
                                        marginLeft: "48%",
                                        marginTop: "21%",
                                        transform: "translate(-50%,-50%)",
                                    }}
                                />
                            ) : dataTypeObj.list.length == 0 ? (
                                <div
                                    style={{
                                        marginLeft: "55%",
                                        marginTop: "10%",
                                        transform: "translate(-50%,-50%)",
                                    }}
                                >
                                    {" "}
                                    Room type is not available
                                </div>
                            ) : (
                                dataTypeObj.list.map((val, i) => {
                                    console.log("bdjhasdgjdagsgajgasgdadgadaa", val);
                                    return (
                                        <div className="extraRateDetail">
                                            <div style={{ display: "flex", justifyContent: "center" }}>
                                                <h4 style={{ fontWeight: 600, marginBottom: "10px" }}>{val?.name}</h4>
                                            </div>
                                            <Descriptions bordered size="small" labelStyle={{ width: "300px" }}>
                                                <Descriptions.Item label="Total Rooms" span={3}>
                                                    {val?.numberOfRooms}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Occupied Rooms" span={3}>
                                                    {val?.booking}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Available Rooms" span={3}>
                                                    {val?.outstanding}
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </div>
                                    );
                                })
                            )}
                        </div>
                    </Modal>
                )}

                <Figure7
                    style={{
                        backgroundColor: "white",
                        padding: "20px",
                        borderRadius: "15px",
                    }}
                >
                    <img height="200px" width="300px" style={{ marginRight: "15px" }} src={require("../../static/img/app/sagar5.jpeg")} alt="" />

                    <figcaption>
                        <h2>Download Posease For Desktop</h2>
                        <p style={{ marginTop: "10px" }}>
                            Download the PosEase app for Windows and Mac on the desktop <br />
                            and enhance unique features like waiter app, multiple printers etc.
                        </p>
                        <div style={{ display: "inline" }}>
                            <a href="https://api.posease.com/desktop/poseaseWindows.exe.zip" download>
                                <Button
                                    size="middle"
                                    outlined
                                    style={{
                                        marginRight: "10px",
                                        color: "#bd025d",
                                        marginTop: "10px",
                                    }}
                                >
                                    <ArrowDownOutlined />
                                    Windows
                                </Button>
                            </a>
                            <a href="https://api.posease.com/desktop/poseaseMac.zip" download style={{ marginRight: "10px" }}>
                                <Button
                                    size="middle"
                                    outlined
                                    style={{
                                        marginRight: "10px",
                                        color: "#bd025d",
                                    }}
                                >
                                    <ArrowDownOutlined />
                                    Mac
                                </Button>
                            </a>
                        </div>
                    </figcaption>
                </Figure7>
                <ViewSummary ref={viewSummaryHideAndShow} viewSummaryData={viewSummaryData} />
            </Main>
        </>
    );
};

export default Ecommerce;
