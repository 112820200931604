import React, { useEffect, useState } from "react";
import { Menu, Modal, Button } from "antd";
import { useDispatch } from "react-redux";
import { NavLink, useRouteMatch, useHistory } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import propTypes from "prop-types";
import { getItem } from "../utility/localStorageControl";
import { logOut, LockRegister } from "../redux/authentication/actionCreator";
import { DarkModeAvailable } from "../redux/preference/actionCreator";

const { SubMenu } = Menu;

const MenuItems = ({ darkMode, toggleCollapsed, topMenu, events }) => {
    const userDetails = getItem("userDetails");
    const dispatch = useDispatch();
    const history = useHistory();
    const { path } = useRouteMatch();
    const pathName = window.location.pathname;
    const pathArray = pathName.split(path);
    const mainPath = pathArray[1];
    const mainPathSplit = mainPath.split("/");
    const [inventoryList, setInventoryList] = useState([]);
    const [openKeys, setOpenKeys] = React.useState(!topMenu ? [`${mainPathSplit.length > 2 ? mainPathSplit[1] : "dashboard"}`] : []);

    useEffect(() => {
        dispatch(DarkModeAvailable(getItem("dark_mode")));
    }, []);

    const onOpenChange = (keys) => {
        setOpenKeys([keys.length && keys[keys.length - 1]]);
    };
    const onClick = (item) => {
        if (window.outerWidth <= 990) {
            toggleCollapsed(true);
        }
        if (item.keyPath.length === 1) setOpenKeys([]);
    };

    const style = {
        position: "absolute",
        bottom: 0,
        borderTop: "2px solid #f5f5f5",
    };

    const [modalVisibleLock, setModelVisibleLock] = useState(false);

    const handleLock = () => {
        dispatch(LockRegister(history));
    };

    const SignOut = (e) => {
        e.preventDefault();
        dispatch(logOut(history));
    };

    const handleCancel = (e) => {
        setModelVisibleLock(false);
    };

    return (
        <>
            <Modal
                title="Sign out"
                destroyOnClose={true}
                visible={modalVisibleLock}
                footer={
                    userDetails && userDetails.role !== "admin"
                        ? [
                              <Button key="1" onClick={handleCancel} type="default">
                                  Cancel
                              </Button>,
                              <Button key="2" onClick={handleLock} type="default">
                                  Lock
                              </Button>,
                              <Button key="3" onClick={SignOut} type="danger">
                                  Sign Out
                              </Button>,
                          ]
                        : [
                              <Button key="1" onClick={handleCancel} type="default">
                                  Cancel
                              </Button>,
                              <Button key="3" onClick={SignOut} type="danger">
                                  Sign Out
                              </Button>,
                          ]
                }
                width={600}
            >
                <p>Are you sure you want to sign out of posEase?</p>
            </Modal>
            <Menu
                onOpenChange={onOpenChange}
                onClick={onClick}
                mode={!topMenu || window.outerWidth <= 991 ? "inline" : "horizontal"}
                theme={darkMode && "dark"}
                // selectedKeys={!topMenu ? [`${"pricebook"}`] : []}
                defaultOpenKeys={!topMenu ? [`sell`] : []}
                defaultSelectedKeys={[userDetails && userDetails.role === "admin" ? "dashboard" : "sell"]}
                // overflowedIndicator={<FeatherIcon icon="more-vertical" />}
                openKeys={openKeys}
            >
                {/*When user logged in with restaurant role */}
                {userDetails && userDetails.is_shop && userDetails.role === "restaurant" ? (
                    <>
                        <Menu.Item key="dashboard" onClick={() => history.push(`${path}dashboard`)} icon={!topMenu && <FeatherIcon icon="home" />}>
                            <NavLink to={`${path}dashboard`}>Dashboard</NavLink>
                        </Menu.Item>
                        <Menu.Item key="sell" onClick={() => history.push(`${path}sell`)} icon={!topMenu && <FeatherIcon icon="shopping-cart" />}>
                            <NavLink to={`${path}sell`}>
                                {userDetails.business_type && userDetails.business_type == "hotel_management" ? "POS" : "Sell"}
                            </NavLink>
                        </Menu.Item>
                        {userDetails && userDetails.business_type && userDetails.business_type == "hotel_management" && (
                            <Menu.Item
                                key="room-reservation"
                                onClick={() => history.push(`${path}reservation`)}
                                icon={!topMenu && <FeatherIcon icon="package" />}
                            >
                                <NavLink to={`${path}reservation`}>PMS</NavLink>
                            </Menu.Item>
                        )}
                        <Menu.Item key="receipts" onClick={() => history.push(`${path}receipts`)} icon={!topMenu && <FeatherIcon icon="file-text" />}>
                            <NavLink to={`${path}receipts`}>Receipts</NavLink>
                        </Menu.Item>

                        <Menu.Item key="customers" onClick={() => history.push(`${path}customers`)} icon={!topMenu && <FeatherIcon icon="users" />}>
                            <NavLink to={`${path}customers`}>Customers</NavLink>
                        </Menu.Item>
                        <Menu.Item key="reports" onClick={() => history.push(`${path}reports`)} icon={!topMenu && <FeatherIcon icon="bar-chart" />}>
                            <NavLink to={`${path}reports`}>Analytics</NavLink>
                        </Menu.Item>
                        <SubMenu key="products" icon={!topMenu && <FeatherIcon icon="tag" />} title="Setup">
                            <Menu.Item
                                key="Menu"
                                // style={{ paddingLeft: 48 + "!important" }}
                                // className="child-menu"
                            >
                                <NavLink to={`${path}products`}>Menu</NavLink>
                            </Menu.Item>
                            {getItem("pricebook_enable") && (
                                <Menu.Item key="pricebook">
                                    <NavLink to={`${path}products/pricebook`}>Price Book</NavLink>
                                </Menu.Item>
                            )}
                            <Menu.Item key="product-categories">
                                <NavLink to={`${path}product-categories`}> Menu Categories</NavLink>
                            </Menu.Item>
                            <Menu.Item key="product-options">
                                <NavLink to={`${path}product-options`}> Menu Options</NavLink>
                            </Menu.Item>
                            {userDetails && userDetails.business_type && userDetails.business_type == "hotel_management" && (
                                <Menu.Item key="rooms">
                                    <NavLink to={`${path}rooms`}>Rooms</NavLink>
                                </Menu.Item>
                            )}
                            {userDetails && userDetails.business_type && userDetails.business_type == "hotel_management" && (
                                <Menu.Item key="room-location">
                                    <NavLink to={`${path}room-location`}>Room Location</NavLink>
                                </Menu.Item>
                            )}
                            {userDetails && userDetails.business_type && userDetails.business_type == "hotel_management" && (
                                <Menu.Item key="room-type">
                                    <NavLink to={`${path}room-type`}>Room Type</NavLink>
                                </Menu.Item>
                            )}
                        </SubMenu>

                        {getItem("inventrory_app_enable") && (
                            <SubMenu key="purchase" icon={!topMenu && <FeatherIcon icon="shopping-bag" />} title="Purchase">
                                <Menu.Item key="purchase" style={{ paddingLeft: 48 + "!important" }} className="child-menu">
                                    <NavLink to={`${path}vendor/items`}>Items</NavLink>
                                </Menu.Item>
                                <Menu.Item key="vendors">
                                    <NavLink to={`${path}vendors`}>Vendors</NavLink>
                                </Menu.Item>
                                <Menu.Item key="bills">
                                    <NavLink to={`${path}vendor/bills`}>Bills</NavLink>
                                </Menu.Item>
                                <Menu.Item key="expenses">
                                    <NavLink to={`${path}vendor/expenses`}>Expenses</NavLink>
                                </Menu.Item>
                                <Menu.Item key="inventory">
                                    <NavLink to={`${path}inventory`}>Inventory</NavLink>
                                </Menu.Item>
                            </SubMenu>
                        )}
                        {/* {getItem("pettycash_enable") && (
              <Menu.Item
                key="petty"
                onClick={() => history.push(`${path}pettycash`)}
                icon={!topMenu && <FeatherIcon icon="briefcase" />}
              >
                <NavLink to={`${path}pettycash`}>Petty Cash</NavLink>
              </Menu.Item>
            )} */}
                        {getItem("pettycash_enable") && (
                            <Menu.Item key="petty" onClick={() => history.push(`${path}pettycash`)} icon={!topMenu && <FeatherIcon icon="briefcase" />}>
                                <NavLink to={`${path}pettycash`}>Petty cash</NavLink>
                            </Menu.Item>
                        )}
                        {getItem("housekeeping_app_enable") && (
                            <Menu.Item
                                key="housekeeping"
                                onClick={() => history.push(`${path}housekeeping`)}
                                icon={!topMenu && <FeatherIcon icon="check-square" />}
                            >
                                <NavLink to={`${path}housekeeping`}>Housekeeping</NavLink>
                            </Menu.Item>
                        )}
                        {getItem("rate_app_enable") && (
                            <Menu.Item
                                key="rate-inventory"
                                onClick={() => history.push(`${path}rate-inventory`)}
                                icon={!topMenu && <FeatherIcon icon="dollar-sign" />}
                            >
                                <NavLink to={`${path}rate-inventory`}>Rates & Availability</NavLink>
                            </Menu.Item>
                        )}

                        <Menu.Item key="all" onClick={() => history.push(`${path}appstore`)} icon={!topMenu && <FeatherIcon icon="list" />}>
                            <NavLink to={`${path}appstore`}>App Store</NavLink>
                        </Menu.Item>
                        {/* {userDetails &&
              userDetails.business_type &&
              userDetails.business_type == "hotel_management" && (
                <Menu.Item
                  key="room-receipts"
                  onClick={() => history.push(`${path}room-receipts`)}
                  icon={
                    !topMenu && (
                      <FeatherIcon
                        icon="file-text"
                        onClick={() => history.push(`${path}room-receipts`)}
                      />
                    )
                  }
                >
                  <NavLink to={`${path}room-receipts`}>Room receipt</NavLink>
                </Menu.Item>
              )} */}
                        {/* 
            {userDetails &&
              userDetails.business_type &&
              userDetails.business_type == "hotel_management" && (
                <SubMenu
                  key="rooms"
                  icon={!topMenu && <FeatherIcon icon="tag" />}
                  title="Manage Room"
                >
                  <Menu.Item
                    key="rooms"
                    style={{ paddingLeft: 48 + "!important" }}
                    className="child-menu"
                  >
                    <NavLink to={`${path}rooms`}>Rooms</NavLink>
                  </Menu.Item>
                  <Menu.Item key="room-location">
                    <NavLink to={`${path}room-location`}>Room Location</NavLink>
                  </Menu.Item>
                  <Menu.Item key="room-type">
                    <NavLink to={`${path}room-type`}>Room Type</NavLink>
                  </Menu.Item>
                </SubMenu>
              )} */}
                    </>
                ) : (
                    ""
                )}
                {/*When user logged in with admin role */}
                {userDetails && userDetails.role === "admin" ? (
                    <>
                        {/* <Menu.Item
              key="dashboard"
              onClick={() => history.push(`${path}`)}
              icon={
                !topMenu && (
                  <FeatherIcon
                    icon="home"
                    onClick={() => history.push(`${path}`)}
                  />
                )
              }
            >
              <NavLink to={`${path}`}>Dashboard</NavLink>
            </Menu.Item>
            <Menu.Item
              key="enquiry"
              onClick={() => history.push(`${path}/enquiry-management`)}
              icon={
                !topMenu && (
                  <FeatherIcon
                    icon="file-text"
                    onClick={() => history.push(`${path}/enquiry-management`)}
                  />
                )
              }
            >
              <NavLink to={`${path}/enquiry-management`}>
                Enquiry Management
              </NavLink>
            </Menu.Item> */}
                        <Menu.Item
                            key="register-management"
                            onClick={() => history.push(`${path}/register-management`)}
                            icon={!topMenu && <FeatherIcon icon="home" onClick={() => history.push(`${path}/register-management`)} />}
                        >
                            <NavLink to={`${path}/user-management`}>Register Management</NavLink>
                        </Menu.Item>
                        <Menu.Item
                            key="user-management"
                            onClick={() => history.push(`${path}/user-management`)}
                            icon={!topMenu && <FeatherIcon icon="briefcase" onClick={() => history.push(`${path}/user-management`)} />}
                        >
                            <NavLink to={`${path}/user-management`}>User Management</NavLink>
                        </Menu.Item>

                        <Menu.Item style={style} key="signout" icon={!topMenu && <FeatherIcon icon="log-out" onClick={() => setModelVisibleLock(true)} />}>
                            <NavLink to="#" onClick={() => setModelVisibleLock(true)}>
                                {getItem("username")} |{" "}
                                <span
                                    style={{
                                        fontSize: "9px",
                                        fontWeight: "bolder",
                                        marginLeft: "-16px",
                                    }}
                                >
                                    Sign Out
                                </span>
                            </NavLink>
                        </Menu.Item>
                    </>
                ) : (
                    ""
                )}
                {/*When user logged in with cashier role */}
                {userDetails && userDetails.role === "cashier" ? (
                    <>
                        {userDetails.has_manager_permission && (
                            <Menu.Item
                                key="dashboard"
                                onClick={() => history.push(`${path}dashboard`)}
                                icon={!topMenu && <FeatherIcon icon="home" onClick={() => history.push(`${path}dashboard`)} />}
                            >
                                <NavLink to={`${path}dashboard`}>Dashboard</NavLink>
                            </Menu.Item>
                        )}

                        {userDetails &&
                            userDetails.casier_business_type == "hotel_management" &&
                            userDetails.allow_other_register &&
                            userDetails?.register_assigned_to && (
                                <Menu.Item
                                    key="sell"
                                    onClick={() => history.push(`${path}sell`)}
                                    icon={!topMenu && <FeatherIcon icon="shopping-cart" onClick={() => history.push(`${path}sell`)} />}
                                >
                                    <NavLink to={`${path}sell`}>POS</NavLink>
                                </Menu.Item>
                            )}
                        {userDetails && userDetails.casier_business_type != "hotel_management" && userDetails?.register_assigned_to && (
                            <Menu.Item
                                key="sell"
                                onClick={() => history.push(`${path}sell`)}
                                icon={!topMenu && <FeatherIcon icon="shopping-cart" onClick={() => history.push(`${path}sell`)} />}
                            >
                                <NavLink to={`${path}sell`}>POS</NavLink>
                            </Menu.Item>
                        )}
                        {userDetails && userDetails.casier_business_type == "hotel_management" && userDetails.hotel_register_assigned_to && (
                            <Menu.Item
                                key="room-reservation"
                                onClick={() => history.push(`${path}reservation`)}
                                icon={!topMenu && <FeatherIcon icon="package" />}
                            >
                                <NavLink to={`${path}reservation`}>PMS</NavLink>
                            </Menu.Item>
                        )}

                        {userDetails &&
                            userDetails.business_type == "hotel_management" &&
                            userDetails.casier_business_type == "food_and_drink" &&
                            userDetails.hotel_register_assigned_to &&
                            userDetails.allow_other_register && (
                                <Menu.Item
                                    key="room-reservation"
                                    onClick={() => history.push(`${path}reservation`)}
                                    icon={!topMenu && <FeatherIcon icon="package" />}
                                >
                                    <NavLink to={`${path}reservation`}>PMS</NavLink>
                                </Menu.Item>
                            )}
                        <Menu.Item
                            key="receipts"
                            onClick={() => history.push(`${path}receipts`)}
                            icon={!topMenu && <FeatherIcon icon="file-text" onClick={() => history.push(`${path}receipts`)} />}
                        >
                            <NavLink to={`${path}receipts`}>Receipts</NavLink>
                        </Menu.Item>

                        {userDetails.business_type == "hotel_management"
                            ? userDetails.allow_other_register &&
                              getItem("pettycash_enable") && (
                                  <Menu.Item
                                      key="petty"
                                      onClick={() => history.push(`${path}petty`)}
                                      icon={!topMenu && <FeatherIcon icon="briefcase" onClick={() => history.push(`${path}petty`)} />}
                                  >
                                      <NavLink to={`${path}petty`}>Petty Cash</NavLink>
                                  </Menu.Item>
                              )
                            : getItem("pettycash_enable") && (
                                  <Menu.Item
                                      key="petty"
                                      onClick={() => history.push(`${path}petty`)}
                                      icon={!topMenu && <FeatherIcon icon="briefcase" onClick={() => history.push(`${path}petty`)} />}
                                  >
                                      <NavLink to={`${path}petty`}>Petty Cash</NavLink>
                                  </Menu.Item>
                              )}

                        {userDetails.has_manager_permission && (
                            <Menu.Item
                                key="customers"
                                onClick={() => history.push(`${path}customers`)}
                                icon={!topMenu && <FeatherIcon icon="users" onClick={() => history.push(`${path}customers`)} />}
                            >
                                <NavLink to={`${path}customers`}>Customers</NavLink>
                            </Menu.Item>
                        )}

                        {userDetails.business_type == "hotel_management"
                            ? userDetails.casier_business_type == "food_and_drink" &&
                              userDetails.has_manager_permission &&
                              getItem("inventrory_app_enable") && (
                                  <SubMenu key="purchase" icon={!topMenu && <FeatherIcon icon="shopping-bag" />} title="Purchase">
                                      <Menu.Item key="purchase" style={{ paddingLeft: 48 + "!important" }} className="child-menu">
                                          <NavLink to={`${path}vendor/items`}>Items</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="vendors">
                                          <NavLink to={`${path}vendors`}>Vendors</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="bills">
                                          <NavLink to={`${path}vendor/bills`}>Bills</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="expenses">
                                          <NavLink to={`${path}vendor/expenses`}>Expenses</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="inventory">
                                          <NavLink to={`${path}inventory`}>Inventory</NavLink>
                                      </Menu.Item>
                                  </SubMenu>
                              )
                            : userDetails.has_manager_permission &&
                              getItem("inventrory_app_enable") && (
                                  <SubMenu key="purchase" icon={!topMenu && <FeatherIcon icon="shopping-bag" />} title="Purchase">
                                      <Menu.Item key="purchase" style={{ paddingLeft: 48 + "!important" }} className="child-menu">
                                          <NavLink to={`${path}vendor/items`}>Items</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="vendors">
                                          <NavLink to={`${path}vendors`}>Vendors</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="bills">
                                          <NavLink to={`${path}vendor/bills`}>Bills</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="expenses">
                                          <NavLink to={`${path}vendor/expenses`}>Expenses</NavLink>
                                      </Menu.Item>
                                      <Menu.Item key="inventory">
                                          <NavLink to={`${path}inventory`}>Inventory</NavLink>
                                      </Menu.Item>
                                  </SubMenu>
                              )}
                    </>
                ) : (
                    ""
                )}
                {/*When user logged in with App user role */}
                {userDetails && userDetails.role === "app_user" ? (
                    <>
                        <Menu.Item key="sell" icon={!topMenu && <FeatherIcon icon="list" />}>
                            <NavLink to={`${path}appstore`}>App Store</NavLink>
                        </Menu.Item>
                        <Menu.Item key="receipts" icon={!topMenu && <FeatherIcon icon="message-circle" />}>
                            <NavLink to={`${path}app/sms-receipts`}>SMS Receipts</NavLink>
                        </Menu.Item>
                    </>
                ) : (
                    ""
                )}
                {userDetails && userDetails.role === "housekeeping" ? (
                    <>
                        {getItem("housekeeping_app_enable") && (
                            <Menu.Item key="housekeeping" onClick={() => history.push(`${path}housekeeping`)} icon={!topMenu && <FeatherIcon icon="slack" />}>
                                <NavLink to={`${path}housekeeping`}>Housekeeping</NavLink>
                            </Menu.Item>
                        )}
                    </>
                ) : (
                    ""
                )}
            </Menu>
        </>
    );
};

MenuItems.propTypes = {
    darkMode: propTypes.bool,
    topMenu: propTypes.bool,
    toggleCollapsed: propTypes.func,
    events: propTypes.object,
};

export default React.memo(MenuItems);
