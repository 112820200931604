import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Input, Skeleton, message } from "antd";
import { SellModuleNav } from "../../Sell/Style";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getItem, setItem } from "../../../utility/localStorageControl";
import { AllRoom } from "./AllRoom";
import { FloorRom } from "./Floor";
import { getAllRoomList } from "../../../redux/room/actionCreator";
import moment from "moment";
import { getReservationList } from "../../../redux/reservation/actionCreator";
import "./abs.css";
const Reservation = (props) => {
    let { tabChangeToCurrentFunction, currentRegisterData } = props;
    const dispatch = useDispatch();
    const [currentStatus, setStatus] = useState("ALL");
    const [roomListData, setRoomListData] = useState([]);
    const [floorList, setFloorList] = useState([]);
    const [loader, setLoader] = useState(true);
    const [searchText, setSearhRooms] = useState("");
    const [allRoomList, setAllRoomList] = useState([]);

    const [windowWidth, setWindowWidth] = useState(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);

    async function fetchRoomList(id, check) {
        setLoader(true);
        let getReservationListData = await dispatch(getReservationList(currentRegisterData._id, "room"));
        if (getReservationListData?.error == false && getReservationListData?.data) {
            let { data } = getReservationListData;
            let roomObj = {};
            data.reverse().map((k) => {
                k.roomInfo.map((o) => {
                    let startDate = moment();
                    if (
                        o.status != "checkout" &&
                        (startDate.isSame(o.reservationInDateAndTime, "day") ||
                            startDate.isSame(o.reservationOutDateAndTime, "day") ||
                            startDate.isBetween(o.reservationInDateAndTime, o.reservationOutDateAndTime, "day"))
                    ) {
                        if (roomObj[o.roomId]) {
                            if (roomObj[o.roomId].status == "checkin") {
                                roomObj[o.roomId] = {
                                    ...roomObj[o.roomId],
                                };
                            } else {
                                roomObj[o.roomId] = {
                                    reservationObjectId: k,
                                    status: o.status,
                                    roomInfoId: o._id,
                                };
                            }
                        } else {
                            roomObj[o.roomId] = {
                                reservationObjectId: k,
                                status: o.status,
                                roomInfoId: o._id,
                            };
                        }
                    }
                });
            });

            const getRoomList = await dispatch(getAllRoomList(id ? id : currentRegisterData._id, check == "online" ? "" : "sell"));

            if (getRoomList && getRoomList.error == false) {
                setLoader(false);
                let filter = [];
                console.log("getRoomList?.roomList", getRoomList?.roomList);
                getRoomList?.roomList.map((room) => {
                    if (
                        roomObj[room._id] &&
                        (roomObj[room._id].status == "assign" || roomObj[room._id].status == "checkin" || roomObj[room._id].status == "cleaning")
                    ) {
                        room.reservationObjectId = roomObj[room._id].reservationObjectId;
                        (room.status = roomObj[room._id].status), (room.roomInfoId = roomObj[room._id].roomInfoId);
                    } else {
                        room.status = room.status=="maintainance"?"maintainance":"available";
                        room.reservationObjectId = null;
                    }
                    room.room_type.tax_group.taxes = room?.room_type.tax_group.taxes.map((k) => {
                        if (room?.room_type.tax_group.taxes_inclusive_in_product_price) {
                            k["type"] = "inclusive";
                        } else {
                            k["type"] = "exclusive";
                        }
                        return k;
                    });
                    let roomType = filter.findIndex((val) => val._id == room.room_type._id);
                    if (roomType == -1) {
                        filter.push({
                            type: "room_type",
                            name: room?.room_type?.name,
                            _id: room.room_type._id,
                        });
                    }
                    let find = filter.findIndex((val) => val._id == room.location._id);
                    if (find == -1) {
                        filter.push({
                            type: "location",
                            name: room?.location?.name,
                            _id: room.location._id,
                        });
                    }
                });

                setFloorList(
                    filter.sort((a, b) => {
                        if (a.type === "room_type" && b.type === "location") {
                            return -1;
                        } else if (a.type === "location" && b.type === "room_type") {
                            return 1;
                        } else {
                            return 0;
                        }
                    })
                );
                console.log("getRoomListroomList", getRoomList?.roomList);
                setRoomListData(getRoomList?.roomList);
                setAllRoomList(getRoomList?.roomList);
            } else {
                message.error({
                    content: getRoomList.message,
                    style: {
                        float: "center",
                        marginTop: "2vh",
                    },
                });
            }
        }
    }

    useEffect(() => {
        if (currentRegisterData && currentRegisterData?._id) {
            fetchRoomList(currentRegisterData?._id);
        }
    }, [currentRegisterData]);

    useEffect(() => {
        setWindowWidth(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
        setItem("windowWidth", windowWidth);
    }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);

    const [showRoomReservation, setShowRoomReservation] = useState(false);

    const setCategory = (type) => {
        setStatus(type);
    };

    const showEditRoomReservation = (val) => {
        setShowRoomReservation(val);
    };

    useEffect(() => {
        setRoomListData(allRoomList.filter((h) => `${h.room_type.name} ${h.room_name}`.toLowerCase().includes(searchText.toLowerCase())));
    }, [searchText]);

    return (
        <>
            <Row>
                <Input
                    autoFocus={windowWidth && windowWidth <= 992 ? false : true}
                    placeholder="Search Rooms"
                    style={{
                        height: "40px",
                        marginTop: "-10px",
                        marginBottom: "6px",
                    }}
                    onChange={(e) => setSearhRooms(e.target.value)}
                    value={searchText}
                    className="search-none"
                />

                {windowWidth && windowWidth >= 992 ? (
                    <Col xxl={3} lg={3} xl={3} sm={24} xs={24} className="category-col">
                        <Card headless className="category-card sagarNew">
                            <SellModuleNav>
                                {loader ? (
                                    <ul lassName="currentbuild-ul">
                                        <li style={{ fontSize: 13 }}>
                                            <NavLink to="#" onClick={setCategory.bind(this, "ALL")} className={"active"}>
                                                <span className="nav-text">
                                                    <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                                                </span>
                                            </NavLink>
                                        </li>
                                        {[...Array(3)].map((val) => {
                                            return (
                                                <li style={{ fontSize: 13 }}>
                                                    <NavLink to="#" onClick={setCategory.bind(this, "ALL")} className={"not-active"}>
                                                        <span className="nav-text">
                                                            <Skeleton.Button active={true} size={"default"} shape={"default"} block={false} />
                                                        </span>
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                ) : (
                                    <ul className="currentbuild-ul">
                                        <li style={{ fontSize: 13 }}>
                                            <NavLink
                                                to="#"
                                                onClick={setCategory.bind(this, "ALL")}
                                                className={currentStatus === "ALL" ? "active" : "not-active"}
                                            >
                                                <span className="nav-text">All</span>
                                            </NavLink>
                                        </li>
                                        <li style={{ fontSize: 13 }}>
                                            <NavLink
                                                to="#"
                                                onClick={setCategory.bind(this, "CHECKIN")}
                                                className={currentStatus === "CHECKIN" ? "active" : "not-active"}
                                            >
                                                <span className="nav-text">In-house</span>
                                            </NavLink>
                                        </li>
                                        <li style={{ fontSize: 13 }}>
                                            <NavLink
                                                to="#"
                                                onClick={setCategory.bind(this, "DIRTY")}
                                                className={currentStatus === "DIRTY" ? "active" : "not-active"}
                                            >
                                                <span className="nav-text">Dirty</span>
                                            </NavLink>
                                        </li>
                                        <li style={{ fontSize: 13 }}>
                                            <NavLink
                                                to="#"
                                                onClick={setCategory.bind(this, "AVAILABLE")}
                                                className={currentStatus === "AVAILABLE" ? "active" : "not-active"}
                                            >
                                                <span className="nav-text">Available</span>
                                            </NavLink>
                                        </li>
                                        <li style={{ fontSize: 13 }}>
                                            <NavLink
                                                to="#"
                                                onClick={setCategory.bind(this, "BLOCK")}
                                                className={currentStatus === "BLOCK" ? "active" : "not-active"}
                                            >
                                                <span className="nav-text">Block</span>
                                            </NavLink>
                                        </li>
                                        {floorList.map((val) => {
                                            return (
                                                <li style={{ fontSize: 13 }}>
                                                    <NavLink
                                                        to="#"
                                                        onClick={setCategory.bind(this, val)}
                                                        className={currentStatus?.name === val.name ? "active" : "not-active"}
                                                    >
                                                        <span className="nav-text">
                                                            <span>{val.name}</span>
                                                        </span>
                                                    </NavLink>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                )}
                            </SellModuleNav>
                        </Card>
                    </Col>
                ) : (
                    <Col xxl={3} lg={3} xl={3} sm={24} xs={24}>
                        <div className="smallDeviceMenuList">
                            <ul className="current-reservation-ul">
                                <li style={{ fontSize: 13 }}>
                                    <NavLink to="#" onClick={setCategory.bind(this, "ALL")} className={currentStatus === "ALL" ? "active" : "not-active"}>
                                        <span className="nav-text">All</span>
                                    </NavLink>
                                </li>
                                <li style={{ fontSize: 13 }}>
                                    <NavLink
                                        to="#"
                                        onClick={setCategory.bind(this, "CHECKIN")}
                                        className={currentStatus === "CHECKIN" ? "active" : "not-active"}
                                    >
                                        <span className="nav-text">In-house</span>
                                    </NavLink>
                                </li>
                                <li style={{ fontSize: 13 }}>
                                    <NavLink to="#" onClick={setCategory.bind(this, "DIRTY")} className={currentStatus === "DIRTY" ? "active" : "not-active"}>
                                        <span className="nav-text">Dirty</span>
                                    </NavLink>
                                </li>
                                <li style={{ fontSize: 13 }}>
                                    <NavLink
                                        to="#"
                                        onClick={setCategory.bind(this, "AVAILABLE")}
                                        className={currentStatus === "AVAILABLE" ? "active" : "not-active"}
                                    >
                                        <span className="nav-text">Available</span>
                                    </NavLink>
                                </li>
                                <li style={{ fontSize: 13 }}>
                                    <NavLink to="#" onClick={setCategory.bind(this, "BLOCK")} className={currentStatus === "BLOCK" ? "active" : "not-active"}>
                                        <span className="nav-text">Block</span>
                                    </NavLink>
                                </li>
                                {floorList.map((val) => {
                                    return (
                                        <li style={{ fontSize: 13 }}>
                                            <NavLink
                                                to="#"
                                                onClick={setCategory.bind(this, val)}
                                                className={currentStatus?.name === val.name ? "active" : "not-active"}
                                            >
                                                <span className="nav-text">
                                                    <span>{val.name}</span>
                                                </span>
                                            </NavLink>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </Col>
                )}

                {windowWidth && windowWidth >= 765 ? (
                    <Col xxl={21} lg={21} xl={21} sm={24} xs={24}>
                        <Card headless size="large" className="order-card" style={{ backgroundColor: "transparent" }}>
                            <AllRoom
                                currentStatus={currentStatus}
                                showEditRoomReservation={showEditRoomReservation}
                                loader={loader}
                                roomListData={roomListData}
                                tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                                fetchRoomList={fetchRoomList}
                                searchText={searchText}
                            />
                        </Card>
                    </Col>
                ) : (
                    <Col xxl={21} lg={21} xl={21} sm={24} xs={24}>
                        <Card headless size="large" className="order-card" style={{ backgroundColor: "transparent" }}>
                            <AllRoom
                                currentStatus={currentStatus}
                                showEditRoomReservation={showEditRoomReservation}
                                loader={loader}
                                roomListData={roomListData}
                                tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                                fetchRoomList={fetchRoomList}
                                searchText={searchText}
                            />
                        </Card>
                    </Col>
                )}
            </Row>
        </>
    );
};

export { Reservation };
