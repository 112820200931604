import React, { useState, useEffect } from "react";
import { Row, Col, Tabs, Select } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import SubscriptionCards from "./SubscriptionCards";
import Invoices from "./Invoices";
import { getItem } from "../../utility/localStorageControl";
import { useDispatch } from "react-redux";
import { transactionList } from "../../redux/sell/actionCreator";
const { TabPane } = Tabs;

function Billing({ currentRegisterData, marginTop }) {
    let [activeTab, changeTab] = useState("subscription");
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([]);
    let localData = getItem("setupCache");
    let registerList = localData?.register;
    const [registerData, setBillingRegisterData] = useState(currentRegisterData ? currentRegisterData : localData?.register.find((val) => val.active));

    const [loading, setLoading] = useState(false);

    async function fetchTransactionList() {
        setLoading(true);
        const getTransactionList = await dispatch(transactionList(registerData));
        if (getTransactionList) {
            setDataSource(getTransactionList);
            setLoading(false);
        } else {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchTransactionList();
    }, [registerData._id]);

    return (
        <div>
            <Main className="sellscroll" style={{ marginTop: marginTop ? "-0.5%" : "70px" }}>
                <PageHeader className="comman-other-custom-pageheader" ghost title="Subscription Plans" />
                <div
                    style={{
                        textAlign: "center",
                        alignContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Select
                        className="customBorderRadius"
                        style={{
                            width: "20%",
                            // marginBottom: 20,
                            textAlign: "center",
                            alignContent: "center",
                            alignItems: "center",
                        }}
                        value={registerData?._id}
                        onChange={(val) => {
                            setBillingRegisterData(registerList.find((j) => j._id == val));
                        }}
                    >
                        {registerList?.reverse()?.map((val) => {
                            return (
                                <Option style={{ textAlign: "center" }} value={val._id}>
                                    {val.register_name}
                                </Option>
                            );
                        })}
                    </Select>
                </div>
                <Row>
                    <Col md={12}>
                        <Tabs
                            className="subscription-tabs"
                            activeKey={activeTab}
                            // size="small"
                            onChange={(val) => {
                                changeTab(val);
                            }}
                        >
                            <TabPane tab="Subscription" key="subscription" style={{ outline: "none" }} />
                            <TabPane tab="Invoices" key="invoices" style={{ outline: "none" }} />
                        </Tabs>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                        {activeTab === "subscription" ? (
                            <SubscriptionCards loading={loading} dataSource={dataSource} currentRegisterData={registerData} />
                        ) : activeTab === "invoices" ? (
                            <Invoices loading={loading} dataSource={dataSource} />
                        ) : (
                            ""
                        )}
                    </Col>
                </Row>
            </Main>
        </div>
    );
}

export default Billing;
