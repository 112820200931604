import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Row, Col, Table, Input, Modal, Button, Form, message } from "antd";
import { SearchOutlined, ImportOutlined, CaretDownOutlined } from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { TableWrapper } from "../../styled";
import { Popover } from "../../../components/popup/popup";
import { Cards } from "../../../components/cards/frame/cards-frame";
import "../option.css";
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { UserTableStyleWrapper } from "../../pages/style";
import { deleteVendorItem, getVendorItemList } from "../../../redux/vendor/actionCreator";
import { getItem } from "../../../utility/localStorageControl";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
const VendorItemList = ({ setVendorItems }) => {
    const csvLinkRef = useRef();
    const [rsSymbol, setRsSymbol] = useState(
        getItem("setupCache")?.shopDetails?.rs_symbol
            ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)?.length > 0
                ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
                : getItem("setupCache").shopDetails.rs_symbol
            : "₹"
    );
    const { currentRegisterData } = useSelector((state) => {
        return {
            currentRegisterData: state.register.RegisterList?.length > 0 && state.register.RegisterList?.find((val) => val.active),
        };
    });
    const dispatch = useDispatch();
    let [search, setSearch] = useState("");
    const history = useHistory();
    const [selectionType] = useState("checkbox");
    const [loader, setLoader] = useState(true);
    const deviceWidth = localStorage.getItem("windowWidth");
    const [vendorList, setVendorList] = useState([]);
    const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    let [productCategoryList, setProductCategoryList] = useState([]);
    const [state, setState] = useState({});
    const [deleteSelect, setDeleteSelect] = useState(true);
    const getVendorListData = async () => {
        setLoader(true);
        let response = await dispatch(getVendorItemList());
        if (response && !response.error) {
            const uniqueCategories = {};
            response.data.forEach((item) => {
                const categoryId = item?.category?._id ? item?.category?._id : "";
                const categoryName = item?.category?.name ? item.category.name : "-";
                if (!uniqueCategories[categoryId]) {
                    uniqueCategories[categoryId] = {
                        text: categoryName,
                        value: categoryName,
                    };
                }
            });

            const uniqueCategoriesArray = Object.values(uniqueCategories);
            setProductCategoryList(uniqueCategoriesArray);
            setVendorList(response.data);
            setVendorItems(response.data);
            setLoader(false);
        } else {
            setLoader(false);
            message.error(response.message);
        }
    };

    useEffect(() => {
        getVendorListData();
    }, [currentRegisterData?._id]);

    const handleCancel = (e) => {
        setModelDeleteVisible(false);
    };

    const deleteSelectedVendor = async () => {
        setLoading(true);
        const { allSelectedRowsForDelete } = state;

        let allvariantdsForDelete = [];
        if (allSelectedRowsForDelete && allSelectedRowsForDelete.length > 0) {
            allSelectedRowsForDelete.map((item) => {
                allvariantdsForDelete.push(item._id);
            });
            const getDeletedVariant = await dispatch(deleteVendorItem({ ids: allvariantdsForDelete, untrack: !deleteSelect }));
            if (getDeletedVariant && !getDeletedVariant.error) {
                getVendorListData();
                setModelDeleteVisible(false);
                setState({
                    ...state,
                    selectedRows: [],
                });
            }
        }
    };

    const contentforaction = (
        <>
            <NavLink
                to="#"
                onClick={() => {
                    setLoading(false);
                    setDeleteSelect(true);
                    setModelDeleteVisible(true);
                }}
            >
                <FeatherIcon size={16} icon="trash-2" />
                <span>Delete Selected item</span>
            </NavLink>
            <NavLink
                to="#"
                onClick={() => {
                    setDeleteSelect(false);
                    setLoading(false);
                    setModelDeleteVisible(true);
                }}
            >
                <FeatherIcon size={16} icon="book-open" />
                <span>Untrack Selected item</span>
            </NavLink>
        </>
    );

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setState({
                ...state,
                allSelectedRowsForDelete: selectedRows,
            });
        },
    };

    console.log("productCategoryList", productCategoryList);

    const columns = [
        {
            title: (
                <>
                    <Popover placement="bottomLeft" content={contentforaction} trigger="click">
                        <CaretDownOutlined style={{ marginLeft: "12px" }} />
                    </Popover>
                </>
            ),
            key: "action",
            dataIndex: "action",
            align: "center",
            width: "4%",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            fixed: "left",
            className: "products_list_fix",
            render(text, record) {
                return {
                    children: <div style={{ color: "#008cba" }}>{text}</div>,
                };
            },
        },

        {
            title: "Category",
            dataIndex: "category",
            key: "category",
            align: "left",
            filters: productCategoryList,
            onFilter: (value, record) => record.category === value,
            render(text, record) {
                console.log("record", record);
                return {
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: "Cost price",
            dataIndex: "cost_price",
            key: "cost_price",
            align: "left",
            render(text, record) {
                if (text) {
                    return {
                        children: (
                            <div>
                                {rsSymbol}
                                {text}
                            </div>
                        ),
                    };
                } else {
                    return {
                        children: <div>-</div>,
                    };
                }
            },
        },
        {
            title: "Unit Of Measure",
            dataIndex: "measure",
            key: "measure",
            align: "left",
        },
        {
            title: "Track inventory",
            dataIndex: "track_inventory",
            key: "track_inventory",
            align: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
        },
    ];

    let locale = {
        emptyText: (
            <Spin
                style={{
                    marginTop: "20px",
                }}
            />
        ),
    };
    const handleDownloadCSV = () => {
        csvLinkRef.current.link.click();
    };

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(dataSource);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Vendoritems.xlsx");
    };

    const handleDownloadPdf = () => {
        let col = columns.filter((val) => val.dataIndex != "action");
        const doc = new jsPDF();
        doc.text("Vendors", 10, 10);
        doc.autoTable({
            startY: 20,
            head: [col.map((column) => column.title)],
            body: dataSource.map((row) => col.map((column) => row[column.dataIndex])),
        });
        doc.save("Vendoritems.pdf");
    };
    const content = (
        <>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadPdf();
                }}
            >
                <FeatherIcon size={16} icon="book-open" className="popupIcons" />
                <span>PDF</span>
            </NavLink>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadExcel();
                }}
            >
                <FeatherIcon size={16} icon="x" className="popupIcons" />
                <span>Excel (XLSX)</span>
            </NavLink>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadCSV();
                }}
            >
                <FeatherIcon size={16} icon="file" className="popupIcons" />
                <span>CSV</span>
            </NavLink>
        </>
    );

    const dataSource = [];
    let searchArrTaxes = vendorList.filter((value) => value?.name?.toLowerCase().includes(search.toLowerCase()));

    if (searchArrTaxes.length)
        searchArrTaxes.map((value) => {
            return dataSource.push({
                ...value,
                category: value?.category?.name,
                track_inventory: value?.track_inventory ? "Yes" : "No",
            });
        });
    return (
        <>
            <Row gutter={25}>
                <Col md={24} xs={24}>
                    <Cards headless>
                        <Row gutter={15} className="list-row">
                            <Col span={24} className="pricebookTableBox">
                                <div className="priceBook_titles_content">
                                    <div className="productCount">
                                        <h2>Items</h2>
                                        <span className="title-counter" style={{ marginTop: "-17px" }}>
                                            {vendorList.length} Items{" "}
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            boxShadow: "none",
                                            marginBottom: "16px",
                                            marginLeft: "20px",
                                        }}
                                        className="table_search"
                                    >
                                        <Input
                                            suffix={<SearchOutlined />}
                                            placeholder="Search by name"
                                            autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                                            style={{
                                                borderRadius: "30px",
                                                width: "250px",
                                            }}
                                            onChange={(e) => setSearch(e.target.value)}
                                            value={search}
                                        />
                                    </div>
                                </div>
                                <div className="pricebookTables-toolbox-menu">
                                    <div key="1" className="page-header-actions" style={{ marginRight: "10px" }}>
                                        <Popover placement="bottomLeft" content={content} trigger="hover">
                                            <Button size="small" type="white">
                                                <FeatherIcon icon="download" size={14} />
                                                Export
                                            </Button>
                                        </Popover>
                                    </div>
                                    <div key="1" className="page-header-actions" style={{ marginRight: "12px" }}>
                                        <NavLink to="/vendor/item/categories" className="ant-btn ant-btn-md">
                                            Categories
                                        </NavLink>
                                    </div>
                                    <div key="1" className="page-header-actions">
                                        <NavLink to="/vendor/item/add" className="ant-btn ant-btn-primary ant-btn-md">
                                            <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                                            Add Item
                                        </NavLink>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <CSVLink
                            style={{ display: "none" }}
                            data={dataSource}
                            headers={columns
                                .filter((val) => val.dataIndex != "action")
                                .map((column) => ({
                                    label: column.title,
                                    key: column.dataIndex,
                                }))}
                            filename={"Vendoritems.csv"}
                            ref={csvLinkRef}
                        >
                            Hidden Download CSV Link
                        </CSVLink>
                        <UserTableStyleWrapper>
                            <div className="contact-table">
                                <TableWrapper className="table-responsive">
                                    {loader ? (
                                        <Table
                                            rowKey="id"
                                            locale={locale}
                                            className="products_lsttable"
                                            size="small"
                                            dataSource={[]}
                                            columns={columns}
                                            fixed={true}
                                            scroll={{ x: 800 }}
                                            pagination={false}
                                        />
                                    ) : (
                                        <Table
                                            rowKey="_id"
                                            className="products_lsttable"
                                            rowSelection={{
                                                type: selectionType,
                                                ...rowSelection,
                                            }}
                                            onRow={(row) => ({
                                                onClick: () => {
                                                    let findDetails = vendorList.find((o) => o._id == row._id);
                                                    history.push(`/vendor/item/edit`, {
                                                        vendorDetails: findDetails,
                                                    });
                                                },
                                            })}
                                            size="small"
                                            dataSource={dataSource}
                                            columns={columns}
                                            fixed={true}
                                            scroll={{ x: 800 }}
                                            pagination={{
                                                showSizeChanger: true,
                                                total: vendorList.length,
                                                pageSizeOptions: ["10", "50", "100", "500", "1000"],
                                            }}
                                        />
                                    )}
                                </TableWrapper>
                            </div>
                        </UserTableStyleWrapper>
                    </Cards>
                    <p style={{ display: "none" }}>{loading}</p>
                    <Modal
                        title={deleteSelect ? "Confirm Delete" : "Confirm Untrack"}
                        okText={
                            loading ? (
                                <Spin
                                    indicator={
                                        <LoadingOutlined
                                            style={{
                                                fontSize: 16,
                                                color: "white",
                                                margin: "0px 14px",
                                            }}
                                            spin
                                        />
                                    }
                                />
                            ) : deleteSelect ? (
                                "Delete"
                            ) : (
                                "Untrack"
                            )
                        }
                        visible={modalDeleteVisible}
                        onOk={deleteSelectedVendor}
                        onCancel={handleCancel}
                        width={600}
                    >
                        <p>Are you sure you want to {deleteSelect ? "delete" : "untrack"} selected item ?</p>
                    </Modal>
                </Col>
            </Row>
        </>
    );
};

export { VendorItemList };
