import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Input, Modal, Space, Button, Form, Spin, message, Tooltip, Select } from "antd";
import { SearchOutlined, ImportOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { UserTableStyleWrapper } from "../../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../../Customer/Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Popover } from "../../../components/popup/popup";
import { ExportCustomer } from "../../../redux/customer/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../../Customer/customer.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import moment from "moment";
import { ChartjsBarChart, ChartjsBarChartTransparent, ChartjsDonutChart } from "../../../components/charts/chartjs";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import { getPaymnetMode, getProductWise } from "../../../redux/dashboard/actionCreator";
import { CardBarChart } from "../../dashboard/style";
import { handlePaymnetModeDate } from "../../../redux/receipts/actionCreator";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
const ProductwiseReport = () => {
    const [rsSymbol, setRsSymbol] = useState(
        getItem("setupCache")?.shopDetails?.rs_symbol
            ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)?.length > 0
                ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
                : getItem("setupCache").shopDetails.rs_symbol
            : "₹"
    );
    const userDetails = getItem("userDetails");
    const { startDate, endDate, dateChange, registerList } = useSelector((state) => {
        return {
            startDate: state.receipts.paymnetModeStartDate
                ? state.receipts.paymnetModeStartDate
                : moment()
                      .startOf("month")
                      .format("YYYY-MM-DD"),
            endDate: state.receipts.paymnetModeEndDate
                ? state.receipts.paymnetModeEndDate
                : moment()
                      .endOf("month")
                      .format("YYYY-MM-DD"),
            dateChange: state.receipts.paymnetModeDateChange,
            registerList: state.register.RegisterList.filter((value) => {
                if (userDetails?.business_type == undefined || userDetails?.business_type == "food_and_drink") {
                    return value.type == "restaurant";
                } else {
                    return true;
                }
            }),
        };
    });
    const dispatch = useDispatch();
    let colorsArray = [
        "#FF5733",
        "#FFC300",
        "#36DBCA",
        "#49FF33",
        "#336BFF", // Example custom colors
        "#FF33B7",
        "#FF3333",
        "#33FF5D",
        "#333BFF",
        "#5D33FF",
        "#FFE333",
        "#B633FF",
        "#33EFFF",
        "#FF3333",
        "#33FFC9",
        "#C933FF",
        "#33FFAA",
        "#FF3333",
        "#33FF9C",
        "#FF3333",
        "#33FF67",
        "#FF3333",
        "#5B33FF",
        "#33FF47",
        "#FF3333",
        "#3389FF",
        "#FF3333",
        "#33FF33",
        "#FF3333",
        "#33D4FF",
        "#FF3333",
        "#3350FF",
        "#FF3333",
        "#33FF33",
        "#FF3333",
        "#33FF4D",
        "#FF3333",
        "#3397FF",
        "#FF3333",
        "#33FFC9",
        "#FF3333",
        "#33FFD8",
        "#FF3333",
        "#33FF5D",
        "#FF3333",
        "#33FF8E",
        "#FF3333",
        "#33FFA7",
        "#FF3333",
        "#33FFDE",
    ];
    const [dateWisePatmnetList, setDateWisePatmnetList] = useState([]);
    const [totalPaymnetObj, setTotalPaymnetObj] = useState({});
    const [totalRS, setTotalRS] = useState(0);
    const [loader, setLoader] = useState(true);
    const [customObjtype, setCustomObjType] = useState([]);
    const csvLinkRef = useRef();
    const [windowWidth, setWindowWidth] = useState(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
    const [currentRegisterData, setCurrentRegisterData] = useState("allRegister");
    const [topCategory, setTopCategory] = useState([]);
    const [topProduct, setTopProduct] = useState([]);
    useEffect(() => {
        setWindowWidth(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
        setItem("windowWidth", windowWidth);
    }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
    let [productCategoryList, setProductCategoryList] = useState([]);
    async function fetchPaymnetData(startDate, endDate) {
        setLoader(true);
        const response = await dispatch(getProductWise(startDate, endDate, currentRegisterData));
        console.log("responseresponseresponseresponseresponse", response);
        setLoader(false);
        if (response && !response.error) {
            let paymentTable = [];
            let mainSamePaymentObj = {};

            if (response.saleSummaruydashboardDateWiseDetails) {
                let { saleSummaruydashboardDateWiseDetails } = response;

                let totalrs = 0;
                let topCategory = {};
                let dataService = [];
                let categoryList = [];
                let categorySet = new Set();
                saleSummaruydashboardDateWiseDetails
                    .sort((a, b) => b.totalSales - a.totalSales)
                    .map((val) => {
                        dataService.push({
                            ...val,
                            totalSales: `${rsSymbol}${Number(val.totalSales).toFixed(2)}`,
                            totalCostPrice: `${rsSymbol}${Number(val.totalCostPrice).toFixed(2)}`,
                            totalProfit: `${rsSymbol}${Number(Number(val.totalSales) - Number(val.totalCostPrice)).toFixed(2)}`,
                            totalAmount: Number(val.totalSales).toFixed(2),
                            foodCost: `${Number((Number(val.totalCostPrice) / Number(val.totalSales)) * 100).toFixed(2)} %`,
                        });
                        if (!categorySet.has(val.productCategory)) {
                            categoryList.push({
                                text: val.productCategory,
                                value: val.productCategory,
                            });
                            categorySet.add(val.productCategory); // Add to the set if it's unique
                        }
                        totalrs = totalrs + val.totalSales;
                        if (topCategory[val.productCategory]) {
                            topCategory[val.productCategory] = {
                                ...topCategory[val.productCategory],
                                totalSales: topCategory[val.productCategory].totalSales + val.totalSales,
                            };
                        } else {
                            topCategory[val.productCategory] = {
                                categoryName: val.productCategory,
                                totalSales: 0 + val.totalSales,
                            };
                        }
                    });
                setProductCategoryList(categoryList);
                setDateWisePatmnetList(dataService);
                setTopCategory(
                    Object.values(topCategory)
                        .sort((a, b) => b.totalSales - a.totalSales)
                        .slice(0, 10)
                );
                setTopProduct(saleSummaruydashboardDateWiseDetails.sort((a, b) => b.totalSales - a.totalSales).slice(0, 10));
                setTotalRS(Number(totalrs).toFixed(2));
            }
        } else if (response) {
            message.error(response.message);
        }
    }
    async function setStartDateAndDate(startDate, endDate) {
        dispatch(handlePaymnetModeDate(startDate, endDate, false));
    }
    useEffect(() => {
        let startDate = moment().format("YYYY-MM-DD");
        let endDate = moment().format("YYYY-MM-DD");
        fetchPaymnetData(startDate, endDate);
        setStartDateAndDate(startDate, endDate);
    }, []);

    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            if (dateChange) {
                fetchPaymnetData(startDate, endDate);
            }
        } else {
            didMount.current = true;
        }
    }, [dateChange, startDate, endDate]);
    const didMountRegister = useRef(false);
    useEffect(() => {
        if (didMountRegister.current) {
            if (currentRegisterData) {
                fetchPaymnetData(startDate, endDate);
            }
        } else {
            didMountRegister.current = true;
        }
    }, [currentRegisterData]);

    let locale = {
        emptyText: (
            <Spin
                style={{
                    marginTop: "20px",
                }}
            />
        ),
    };

    const columns = [
        {
            title: "Product Name",
            dataIndex: "_id",
            key: "_id",
            fixed: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: "Category",
            dataIndex: "productCategory",
            key: "productCategory",
            align: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
            filters: productCategoryList,
            onFilter: (value, record) => record.productCategory.indexOf(value) === 0,
        },
        {
            title: "Sell Quantity",
            dataIndex: "count",
            key: "count",
            align: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: "Total Selling Price",
            dataIndex: "totalSales",
            key: "totalSales",
            align: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: "Total Cost Price",
            dataIndex: "totalCostPrice",
            key: "totalCostPrice",
            align: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: "Margin",
            dataIndex: "totalProfit",
            key: "totalProfit",
            align: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
        },
        {
            title: "Food Cost",
            dataIndex: "foodCost",
            key: "foodCost",
            align: "left",
            render(text, record) {
                return {
                    children: <div>{text}</div>,
                };
            },
        },
    ];

    let ChartjsDonutChartDefaultProps = {
        height: 200,
        labels: topCategory.map((val) => val.categoryName),
        datasets: [
            {
                data: topCategory.map((val) => val.totalSales),
                backgroundColor: colorsArray.slice(0, topCategory.length),
            },
        ],

        options: {
            cutoutPercentage: 70,
            maintainAspectRatio: true,
            responsive: true,
            legend: {
                display: false,
                position: "bottom",
            },
            animation: {
                animateScale: true,
                animateRotate: true,
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        const dataset = data.datasets[tooltipItem.datasetIndex];
                        const total = dataset.data.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                        const currentValue = dataset.data[tooltipItem.index];
                        const percentage = ((currentValue / total) * 100).toFixed(2); // Round to two decimal places
                        return `${data.labels[tooltipItem.index]}: ${percentage}%`;
                    },
                },
            },
            plugins: {
                datalabels: {
                    color: "#fff", // Set the color of the labels, make sure it's visible against the chart background
                    font: {
                        weight: "bold", // Makes the labels bold
                    },
                    formatter: (value, ctx) => {
                        return value; // This will display the raw value; you can format it as needed
                    },
                    anchor: "center", // Anchors the labels in the center of each piece of the doughnut
                    align: "center", // Aligns text centrally relative to 'anchor' point
                    display: "auto", // You can set specific conditions for displaying labels
                },
            },
        },
    };

    let newLabels = [];
    let newtotalls = [];

    let scale;

    function generateRandomColors(num) {
        var colors = [];
        for (var i = 0; i < num; i++) {
            var color = "#" + ("00000" + ((Math.random() * (1 << 24)) | 0).toString(16)).slice(-6);
            colors.push(color);
        }
        return colors;
    }
    function truncateString(str, num) {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + "...";
    }
    if (true) {
        newLabels = [];
        newtotalls = [];

        if (topProduct.length > 0) {
            topProduct.map((i, idx) => {
                newLabels.push(truncateString(i._id, 10));
                newtotalls.push(i.totalSales);
            });
        }
        let max = 0;
        let stepSize;
        newtotalls.map((i) => {
            if (i > max) {
                max = i;
            }
        });

        if (max > 0 && max <= 100) {
            stepSize = 50;
        } else if (max > 100 && max <= 1000) {
            stepSize = 100;
        } else if (max > 1000 && max <= 5000) {
            stepSize = 1000;
        } else if (max > 5000 && max <= 10000) {
            stepSize = 2000;
        } else if (max > 10000 && max <= 100000) {
            stepSize = 20000;
        } else if (max > 100000 && max <= 500000) {
            stepSize = 50000;
        } else if (max > 500000 && max <= 1000000) {
            stepSize = 200000;
        }

        scale = {
            yAxes: [
                {
                    display: true,
                    ticks: {
                        stepSize: stepSize,
                        suggestedMin: 0,
                    },
                },
            ],
            xAxes: [
                {
                    stacked: true,
                    ticks: {
                        fontSize: 10,
                        callback: function(val, index) {
                            return index % 2 === 0 ? val : "";
                        },
                    },
                },
            ],
        };
    }

    const chartData = {
        labels: newLabels[0] == undefined ? [0] : newLabels,
        datasets: [
            {
                data: newtotalls,
                backgroundColor: generateRandomColors(newtotalls.length),
                maxBarThickness: window?.innerWidth < 600 ? 20 : 55,
                barThickness: window?.innerWidth < 600 ? 20 : 55,
            },
        ],
    };

    scale.yAxes[0].ticks["callback"] = function(value) {
        var ranges = [
            { divider: 1e6, suffix: "M" },
            { divider: 1e3, suffix: "k" },
        ];
        function formatNumber(n) {
            for (var i = 0; i < ranges.length; i++) {
                if (n >= ranges[i].divider) {
                    return (n / ranges[i].divider).toString() + ranges[i].suffix;
                }
            }
            return n;
        }
        return formatNumber(value);
    };
    const handleDownloadCSV = () => {
        csvLinkRef.current.link.click();
    };
    const handleDownloadPdf = () => {
        let col = [...columns, ...customObjtype]
            .filter((val) => val.dataIndex != "action")
            .filter((val) => val.dataIndex != "foodCost")
            .filter((val) => val.dataIndex != "totalProfit");

        const doc = new jsPDF();
        doc.text("Product Wise", 10, 10);
        doc.autoTable({
            startY: 20,
            head: [col.map((column) => column.title)],
            body: dateWisePatmnetList.map((row) => col.map((column) => (column.title == "Total Sales" ? row["totalAmount"] : row[column.dataIndex]))),
        });
        doc.save("Product Wise.pdf");
    };
    console.log("dateWisePatmnetList", dateWisePatmnetList);
    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            dateWisePatmnetList.map((k) => {
                return {
                    "Product Name": typeof k._id != "string" ? k._id.join(" ") : k._id,
                    "Product Category": k.productCategory,
                    Quantity: k.count,
                    "Total Sales": k.totalSales,
                    "Total Cost Price": k.totalCostPrice,
                    Margin: k.totalProfit,
                    "Food Cost": k.foodCost,
                };
            })
        );
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Product Wise.xlsx");
    };

    const content = (
        <>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadPdf();
                }}
            >
                <FeatherIcon size={16} icon="book-open" className="popupIcons" />
                <span>PDF</span>
            </NavLink>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadExcel();
                }}
            >
                <FeatherIcon size={16} icon="x" className="popupIcons" />
                <span>Excel (XLSX)</span>
            </NavLink>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadCSV();
                }}
            >
                <FeatherIcon size={16} icon="file" className="popupIcons" />
                <span>CSV</span>
            </NavLink>
        </>
    );
    return (
        <>
            <Main>
                <CardToolbox>
                    <PageHeader
                        ghost
                        className="comman-other-custom-pageheader receipts-top0"
                        subTitle={
                            <>
                                <div className="table_titles" style={{ marginBottom: 10, marginTop: 10 }}>
                                    <h2>Product</h2>
                                </div>

                                <Select
                                    className="customBorderRadius"
                                    style={{
                                        width: "100%",
                                        marginBottom: 10,
                                        marginTop: 10,
                                        textAlign: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        borderRadius: "3  px",
                                    }}
                                    value={currentRegisterData}
                                    onChange={(val) => setCurrentRegisterData(val)}
                                >
                                    <Option value={"allRegister"} style={{ textAlign: "center" }}>
                                        All Register
                                    </Option>
                                    {registerList?.reverse()?.map((val) => {
                                        return (
                                            <Option value={val._id} style={{ textAlign: "center" }}>
                                                {val.register_name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </>
                        }
                        buttons={[
                            <div key="2" className="page-header-actions custom_action" style={{ marginBottom: 10, marginTop: 10 }}>
                                <div className="actionBtn">
                                    <Tooltip
                                        title={
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "10px",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Showing master from{" "}
                                                {startDate
                                                    ? moment(startDate).format("MMMM Do YYYY")
                                                    : moment()
                                                          .startOf("month")
                                                          .format("MMMM Do YYYY")}
                                                {" to"}{" "}
                                                {endDate
                                                    ? moment(endDate).format("MMMM Do YYYY")
                                                    : moment()
                                                          .endOf("month")
                                                          .format("MMMM Do YYYY")}
                                            </p>
                                        }
                                    >
                                        <ExclamationCircleOutlined
                                            style={{
                                                cursor: "pointer",
                                                marginRight: "10px",
                                                fontSize: "18px",
                                            }}
                                        />
                                    </Tooltip>
                                    <CalendarButtonPageHeader key="7" type="payment-mode" />
                                </div>
                            </div>,
                            <div key="1" className="page-header-actions" style={{ marginBottom: 10, marginTop: 10 }}>
                                <Popover placement="bottomLeft" content={content} trigger="hover">
                                    <Button size="small" type="white">
                                        <FeatherIcon icon="download" size={14} />
                                        Export
                                    </Button>
                                </Popover>
                            </div>,
                        ]}
                    />
                </CardToolbox>
                <Row gutter={15}>
                    <Col md={24}>
                        <Cards headless>
                            <UserTableStyleWrapper>
                                <Row gutter={15}>
                                    <Col md={24} style={{ marginTop: "3%" }} xxl={7} lg={7} xl={7} sm={24} xs={24}>
                                        <CardBarChart>
                                            <ChartjsDonutChart
                                                labels={ChartjsDonutChartDefaultProps.labels}
                                                datasets={ChartjsDonutChartDefaultProps.datasets}
                                                options={ChartjsDonutChartDefaultProps.options}
                                                height={ChartjsDonutChartDefaultProps.height}
                                                totalRs={`${rsSymbol}${Number(totalRS).toFixed(2)}`}
                                                module={"Payment"}
                                            />
                                        </CardBarChart>
                                        <div style={{ top: "50%" }}>
                                            <div
                                                style={{
                                                    borderBottom: "1px solid #f0f0f0",
                                                    marginBottom: 10,
                                                    marginTop: 10,
                                                }}
                                            ></div>
                                            <Row gutter={{ xs: 6, sm: 12, md: 18, lg: 24 }} className="bod_botm">
                                                {topCategory.map((val, index) => {
                                                    let value = val.totalSales;
                                                    return (
                                                        <Col xs={8} xl={8}>
                                                            <div
                                                                className="growth-upward borderdas_left mobile-frbr"
                                                                style={{ borderLeftColor: colorsArray[index] }}
                                                            >
                                                                <div style={{ marginLeft: "10px" }}>
                                                                    <p>{capitalizeFirstLetter(val.categoryName)}</p>
                                                                    <h3>
                                                                        {rsSymbol}
                                                                        {Number(value).toFixed(2)}
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    );
                                                })}
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col md={24} xxl={17} lg={17} xl={17} sm={24} xs={24} style={{ marginTop: "4%" }}>
                                        <CardBarChart>
                                            <ChartjsBarChartTransparent
                                                chartData={chartData}
                                                displayLegend={false}
                                                scale={scale}
                                                height={"40%"}
                                                module={"Product"}
                                            />
                                        </CardBarChart>
                                    </Col>
                                </Row>
                            </UserTableStyleWrapper>
                        </Cards>
                    </Col>
                    <CSVLink
                        style={{ display: "none" }}
                        data={dateWisePatmnetList}
                        headers={[...columns]
                            .filter((val) => val.dataIndex != "action")
                            .map((column) => ({
                                label: column.title,
                                key: column.dataIndex,
                            }))}
                        filename={"Product Wise.csv"}
                        ref={csvLinkRef}
                    >
                        Hidden Download CSV Link
                    </CSVLink>
                    <Col md={24}>
                        <Row>
                            <Cards headless>
                                <UserTableStyleWrapper>
                                    <div className="contact-table">
                                        <TableWrapper className="table-responsive">
                                            {loader ? (
                                                <Table
                                                    locale={locale}
                                                    rowKey="_id"
                                                    size="small"
                                                    dataSource={[]}
                                                    columns={columns}
                                                    fixed={true}
                                                    pagination={false}
                                                    scroll={windowWidth < 1050 ? { x: 700 } : {}}
                                                />
                                            ) : (
                                                <Table
                                                    rowKey="_id"
                                                    size="small"
                                                    scroll={windowWidth < 1050 ? { x: 700 } : {}}
                                                    dataSource={dateWisePatmnetList}
                                                    columns={[...columns]}
                                                    fixed={true}
                                                    pagination={{
                                                        total: dateWisePatmnetList.length,
                                                        showSizeChanger: dateWisePatmnetList.length > 10 ? true : false,
                                                    }}
                                                />
                                            )}
                                        </TableWrapper>
                                    </div>
                                </UserTableStyleWrapper>
                            </Cards>
                        </Row>
                    </Col>
                </Row>
            </Main>
        </>
    );
};

export default ProductwiseReport;
