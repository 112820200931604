import React, { useState, useEffect } from "react";
import { Row, Col, Button, Modal, Spin, Table } from "antd";
import { PageHeader } from "../../components/page-headers/page-headers";
import { getItem } from "../../utility/localStorageControl";
import { useDispatch } from "react-redux";
import { transactionList } from "../../redux/sell/actionCreator";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main, TableWrapper } from "../styled";
function Invoices(props) {
    const { loading, dataSource } = props;

    const columns = [
        {
            title: "Paid At",
            dataIndex: "paid_at",
            key: "paid_at",
            fixed: "left",
        },
        {
            title: "Payment type",
            dataIndex: "paymentType",
            key: "paymentType",
            render(text, record) {
                return {
                    children: <div>{text == "manual" ? "Manual" : "Online"}</div>,
                };
            },
        },
        {
            title: "Expires At",
            dataIndex: "expires_at",
            key: "expires_at",
        },
        // {
        //   title: "Amount Paid",
        //   dataIndex: "price",
        //   key: "price",
        //   render(text, record) {
        //     return {
        //       children: <div>{text}</div>,
        //     };
        //   },
        // },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render(text, record) {
                return {
                    children: <div>{text == "paid" ? "Successful" : "Failed"}</div>,
                };
            },
        },
    ];

    let locale = {
        emptyText: (
            <Spin
                style={{
                    marginTop: "20px",
                }}
            />
        ),
    };
    console.log("dasdasjdhaskdadaccxcvx", dataSource, loading);
    return (
        <div>
            <Row>
                <Col span={24}>
                    <div className="planCardHeader">
                        <h1>Invoice</h1>
                    </div>
                    <Cards headless>
                        <TableWrapper className="table-responsive">
                            {loading ? (
                                <Table
                                    locale={locale}
                                    rowKey="_id"
                                    size="small"
                                    dataSource={[]}
                                    columns={columns}
                                    fixed={true}
                                    scroll={{ x: 800 }}
                                    pagination={false}
                                />
                            ) : (
                                <Table rowKey="_id" size="middle" dataSource={dataSource} columns={columns} fixed={true} scroll={{ x: 800 }} />
                            )}
                        </TableWrapper>
                    </Cards>
                </Col>
            </Row>
        </div>
    );
}

export default Invoices;
