import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
    Row,
    Col,
    Form,
    Input,
    Select,
    Radio,
    Tooltip,
    Tabs,
    TreeSelect,
    Button,
    Upload,
    Space,
    Modal,
    Descriptions,
    Divider,
    Image,
    message,
    Tag,
} from "antd";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import { Main, BasicFormWrapper } from "../../../../styled";
import { AddProductForm } from "../../../../Product/Style";
import { getAllRegisterList } from "../../../../../redux/register/actionCreator";
import { InfoCircleFilled, DeleteOutlined, EditOutlined, UploadOutlined, InstagramOutlined, FacebookOutlined } from "@ant-design/icons";
import { getTaxGroupList } from "../../../../../redux/taxGroup/actionCreator";
import { addOrUpdateBookingSetup, addOrUpdateroomType, getOtaChannel, uploadImage } from "../../../../../redux/roomType/actionCreator";
import "../../../../Product/product.css";
const { TabPane } = Tabs;
import { LoadingOutlined, PlusOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./style.css";
import Heading from "../../../../../components/heading/heading";
const AddBookingSetup = () => {
    const { currentRegisterData } = useSelector((state) => {
        return {
            currentRegisterData: state.register.RegisterList?.length > 0 && state.register.RegisterList?.find((val) => val.activeHotel),
        };
    });
    const [form] = Form.useForm();
    const [addForm] = Form.useForm();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    let isMounted = useRef(true);
    let [extraRateList, setExtraRateList] = useState([]);
    let [taxGroupList, setTaxGroupList] = useState([]);
    let [activeTab, changeTab] = useState("1");
    const [adultNo, setAdultNo] = useState(0);
    const [loading, setLoading] = useState(false);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [checkEdit, setEdit] = useState(false);
    const [editIndex, setEditIndex] = useState();
    const [roomTypeDetils, setRoomTypeDetails] = useState();
    const [RegisterNameList, setRegisterNameList] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [moreFileList, setMoreFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [loader, setLoader] = useState(false);
    const [channelRoomType, setChannelRoomType] = useState([]);
    const [channelRatePlans, setChannelRatePlans] = useState([]);
    const [type, setType] = useState();
    const [value, setValue] = useState();

    const onChange = (newValue) => {
        console.log(newValue);
        setValue(newValue);
    };
    const getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };

    useEffect(() => {
        if (isMounted.current) {
            fetchRegisterName();
        }
        return () => {
            isMounted.current = false;
        };
    }, []);
    const [imageLodaer1, setImageLoader1] = useState(false);
    const handleChange1 = async ({ fileList }) => {
        if (fileList[0]?.status == "uploading" && fileList[0]?.originFileObj) {
            setImageLoader1(true);
            let formData = new FormData();
            formData.append("oneImage", fileList[0]?.originFileObj);
            let response = await dispatch(uploadImage(formData));
            setImageLoader1(false);
            if (response && response.data) {
                setMoreFileList([{ url: response.data }]);
            }
        }
    };

    const [singleRatePlan, setSingleRateplan] = useState();
    async function fetchRegisterName() {
        const getRgisterNameList = await dispatch(getAllRegisterList("sell"));
        if (isMounted.current) {
            setRegisterNameList(getRgisterNameList.RegisterList.filter((val) => val.type == "hotel"));
        }
    }

    const fetchEditData = (data) => {
        setRoomTypeDetails(data);
        setType(data.payment_type);
        let mainRatePlans = data.hotel_section;
        setSingleRateplan(mainRatePlans[0]);
        form.setFieldsValue({
            ...data,
            registerId: data?.registerId?._id,
            section_description: mainRatePlans[0].section_description,
            section_title: mainRatePlans[0].section_title,
        });
        if (mainRatePlans[0].section_image) {
            setFileList([{ url: mainRatePlans[0].section_image }]);
        }
        setExtraRateList(mainRatePlans.filter((val, index) => index > 0));
    };

    useEffect(() => {
        if (location && location.state && location.state.data) {
            fetchEditData(location.state.data);
        }
    }, []);

    function insertObject(arr, obj) {
        arr = [...arr, obj];
        return arr;
    }

    const handleSubmitBasicDetails = async (formData) => {
        setLoading(true);
        let addRoomType;
        console.log("fasfasfafkashkffhjkashfafafasf", singleRatePlan);
        let array = [
            {
                section_description: formData.section_description ? formData?.section_description : singleRatePlan?.section_description,
                section_image: fileList[0]?.url,
                section_title: formData.section_title ? formData?.section_title : singleRatePlan?.section_title,
            },
            ...extraRateList,
        ];
        formData["hotel_section"] = array;
        delete formData.section_description;
        delete formData.section_image;
        delete formData.section_title;
        if (roomTypeDetils && roomTypeDetils._id) {
            addRoomType = await dispatch(addOrUpdateBookingSetup(formData, roomTypeDetils._id));
        } else {
            addRoomType = await dispatch(addOrUpdateBookingSetup(formData));
        }
        if (addRoomType && !addRoomType.error) {
            form.resetFields();
            history.push("/settings/booking-engine-setup");
            setLoading(false);
        } else if (addRoomType) {
            message.error({
                content: addRoomType.message,
                style: {
                    float: "center",
                    marginTop: "2vh",
                },
            });
            setLoading(false);
        } else {
            message.error({
                content: "Somthing is wrong",
                style: {
                    float: "center",
                    marginTop: "2vh",
                },
            });
        }
    };

    const showAddRateModal = (val) => {
        setOpenAddModal(true);
        if (val && val.section_title) {
            addForm.setFieldsValue({
                section_title: val.section_title,
                section_description: val.section_description,
            });
            setMoreFileList([{ url: val.section_image }]);
        } else {
            setMoreFileList([]);
            addForm.resetFields();
        }
    };

    const submitAddRate = (val) => {
        setOpenAddModal(false);
        const newObj = {};
        newObj.section_title = val.section_title;
        newObj.section_description = val.section_description;
        newObj.section_image = moreFileList[0]?.url;

        if (checkEdit) {
            extraRateList[editIndex] = newObj;
            setExtraRateList([...extraRateList]);
            setEdit(false);
            setEditIndex();
            addForm.resetFields();
        } else {
            setExtraRateList(insertObject(extraRateList, newObj));
            addForm.resetFields();
        }
    };

    const confirmDelete = (index, rateName) => {
        Modal.confirm({
            title: "Are you sure you wants to Delete this Rate Plan?",
            okText: "Yes",
            okType: "success",
            onOk: () => {
                extraRateList.splice(index, 1);
                setExtraRateList([...extraRateList]);
            },
        });
    };
    const validateFields = (tab) => {
        form.validateFields()
            .then(() => {
                changeTab(tab);
            })
            .catch((errorInfo) => {});
    };
    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };
    console.log("currentRegisterDatadfasdasdasdada", currentRegisterData);
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: "none",
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );
    const treeData = [
        {
            title: "General Services",
            value: "General Services",
            children: [
                { value: "24-Hour Front Desk", title: "24-Hour Front Desk" },
                { value: "Concierge Service", title: "Concierge Service" },
                { value: "Luggage Storage", title: "Luggage Storage" },
                { value: "Wake-Up Service", title: "Wake-Up Service" },
                { value: "Housekeeping", title: "Housekeeping" },
                {
                    value: "Laundry and Dry Cleaning Service",
                    title: "Laundry and Dry Cleaning Service",
                },
                { value: "Valet Parking", title: "Valet Parking" },
                {
                    value: "Shuttle Service (airport, local attractions)",
                    title: "Shuttle Service (airport, local attractions)",
                },
                { value: "Multilingual Staff", title: "Multilingual Staff" },
                {
                    value: "Room Service (for in-room dining)",
                    title: "Room Service (for in-room dining)",
                },
            ],
        },
        {
            title: "Dining Options",
            value: "Dining Options",
            children: [
                { value: "On-Site Restaurant(s)", title: "On-Site Restaurant(s)" },
                { value: "Bar/Lounge", title: "Bar/Lounge" },
                { value: "Coffee Shop", title: "Coffee Shop" },
                { value: "Breakfast Buffet", title: "Breakfast Buffet" },
                { value: "Outdoor Dining/Terrace", title: "Outdoor Dining/Terrace" },
            ],
        },
        {
            title: "Health and Wellness",
            value: "Health and Wellness",
            children: [
                {
                    value: "Swimming Pool (indoor/outdoor)",
                    title: "Swimming Pool (indoor/outdoor)",
                },
                { value: "Fitness Center/Gym", title: "Fitness Center/Gym" },
                { value: "Spa and Wellness Center", title: "Spa and Wellness Center" },
                { value: "Sauna/Steam Room", title: "Sauna/Steam Room" },
                { value: "Hot Tub/Jacuzzi", title: "Hot Tub/Jacuzzi" },
                { value: "Yoga/Meditation Room", title: "Yoga/Meditation Room" },
            ],
        },
        {
            title: "Business Facilities",
            value: "Business Facilities",
            children: [
                { value: "Business Center", title: "Business Center" },
                {
                    value: "Meeting Rooms/Conference Facilities",
                    title: "Meeting Rooms/Conference Facilities",
                },
                { value: "Banquet Halls", title: "Banquet Halls" },
                {
                    value: "Free Wi-Fi in Public Areas",
                    title: "Free Wi-Fi in Public Areas",
                },
                {
                    value: "Printing/Photocopying Services",
                    title: "Printing/Photocopying Services",
                },
            ],
        },
        {
            title: "Recreation and Leisure",
            value: "Recreation and Leisure",
            children: [
                { value: "Garden/Lawn Area", title: "Garden/Lawn Area" },
                {
                    value: "Game Room (billiards, table tennis, etc.)",
                    title: "Game Room (billiards, table tennis, etc.)",
                },
                {
                    value: "Kids’ Play Area/Kids’ Club",
                    title: "Kids’ Play Area/Kids’ Club",
                },
                { value: "Library/Reading Room", title: "Library/Reading Room" },
                {
                    value: "Entertainment (live music, cultural shows)",
                    title: "Entertainment (live music, cultural shows)",
                },
            ],
        },
        {
            title: "Accessibility and Convenience",
            value: "Accessibility and Convenience",
            children: [
                { value: "Elevator/Lift", title: "Elevator/Lift" },
                {
                    value: "Wheelchair Accessibility",
                    title: "Wheelchair Accessibility",
                },
                {
                    value: "On-Site Parking (free/paid)",
                    title: "On-Site Parking (free/paid)",
                },
                { value: "EV Charging Stations", title: "EV Charging Stations" },
                {
                    value: "Gift Shop/Convenience Store",
                    title: "Gift Shop/Convenience Store",
                },
                {
                    value: "Tour Desk/Travel Assistance",
                    title: "Tour Desk/Travel Assistance",
                },
                { value: "Currency Exchange", title: "Currency Exchange" },
                { value: "ATM/Cash Machine", title: "ATM/Cash Machine" },
            ],
        },
        {
            title: "Outdoor and Sports Facilities",
            value: "Outdoor and Sports Facilities",
            children: [
                { value: "Tennis Court", title: "Tennis Court" },
                {
                    value: "Golf Course (on-site/nearby)",
                    title: "Golf Course (on-site/nearby)",
                },
                {
                    value: "Outdoor Sports (volleyball, basketball, etc.)",
                    title: "Outdoor Sports (volleyball, basketball, etc.)",
                },
                { value: "Bicycle Rental", title: "Bicycle Rental" },
                {
                    value: "Beach Access (if applicable)",
                    title: "Beach Access (if applicable)",
                },
            ],
        },
        {
            title: "Safety and Security",
            value: "Safety and Security",
            children: [
                { value: "24-Hour Security", title: "24-Hour Security" },
                { value: "CCTV in Public Areas", title: "CCTV in Public Areas" },
                { value: "Fire Extinguishers", title: "Fire Extinguishers" },
                { value: "Smoke Detectors", title: "Smoke Detectors" },
                { value: "First Aid Kit", title: "First Aid Kit" },
            ],
        },
        {
            title: "Other Amenities",
            value: "Other Amenities",
            children: [
                { value: "Pet-Friendly Facilities", title: "Pet-Friendly Facilities" },
                {
                    value: "Designated Smoking Areas",
                    title: "Designated Smoking Areas",
                },
                {
                    value: "Event Planning and Catering Services",
                    title: "Event Planning and Catering Services",
                },
                {
                    value: "Complimentary Newspapers",
                    title: "Complimentary Newspapers",
                },
                {
                    value: "Luggage Carts/Bellhop Service",
                    title: "Luggage Carts/Bellhop Service",
                },
            ],
        },
    ];
    const [imageLodaer, setImageLoader] = useState(false);
    const handleChange = async ({ fileList }) => {
        console.log("dasddhjadhadhadadadadasd", fileList);
        if (fileList[0]?.status == "uploading" && fileList[0]?.originFileObj) {
            setImageLoader(true);
            let formData = new FormData();
            formData.append("oneImage", fileList[0]?.originFileObj);
            let response = await dispatch(uploadImage(formData));
            setImageLoader(false);
            if (response && response.data) {
                setFileList([{ url: response.data }]);
            }
        }
    };
    const supportedCurrencies = [
        "INR",
        "USD",
        "EUR",
        "SGD",
        "AED",
        "AUD",
        "CAD",
        "GBP",
        "JPY",
        "CNY",
        "ZAR",
        "THB",
        "NZD",
        "HKD",
        "CHF",
        "SEK",
        "NOK",
        "DKK",
        "PHP",
        "PLN",
        "MXN",
        "MYR",
        "RUB",
        "SAR",
        "TRY",
        "BRL",
        "IDR",
        "KRW",
        "VND",
        "KWD",
        "BHD",
        "OMR",
        "QAR",
        "CZK",
        "HUF",
        "ILS",
    ];
    const handleRemove = (file) => {
        setFileList([]);
    };
    const handleRemove1 = (file) => {
        setMoreFileList([]);
    };
    return (
        <>
            <Cards
                marginTop={true}
                title={
                    <div className="setting-card-title">
                        <Heading as="h4">Setup Booking Engine</Heading>
                        <span>Please provide the required details below to complete the setup of your booking engine.</span>
                    </div>
                }
            >
                {/* <Main className="padding-top-form"> */}
                <Row gutter={15}>
                    <Col xs={24}>
                        <Cards headless>
                            <Row gutter={25} justify="center" style={{ marginTop: "-50px" }}>
                                <Col xxl={16} md={16} sm={18} xs={24}>
                                    <AddProductForm>
                                        <Form style={{ width: "100%" }} form={form} name="addRate" onFinish={handleSubmitBasicDetails} className="comman-input">
                                            <BasicFormWrapper>
                                                <Tabs
                                                    activeKey={activeTab}
                                                    onChange={(val) => {
                                                        console.log("valvalvalval", val);
                                                        if (val == 1) {
                                                            changeTab("1");
                                                        } else {
                                                            validateFields(val.toString());
                                                        }
                                                    }}
                                                >
                                                    <TabPane tab="Details" key="1">
                                                        {loader ? (
                                                            <Spin
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className="add-product-block">
                                                                <Row gutter={15}>
                                                                    <Col xs={24}>
                                                                        <div className="add-product-content">
                                                                            <Form.Item
                                                                                name="hotel_name"
                                                                                label={
                                                                                    <span>
                                                                                        Hotel Name &nbsp;&nbsp;
                                                                                        {/* <Tooltip
                                            title="Create your type here."
                                            color="#FFFF"
                                          >
                                            <InfoCircleFilled
                                              style={{ color: "#AD005A" }}
                                            />
                                          </Tooltip> */}
                                                                                    </span>
                                                                                }
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Hotel name is required",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Input style={{ marginBottom: 10 }} placeholder="Hotel name" />
                                                                            </Form.Item>

                                                                            <Form.Item
                                                                                name="registerId"
                                                                                label="Linked Register"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Linked Register is required",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                    placeholder="Select a Register"
                                                                                >
                                                                                    {RegisterNameList.map((val) => {
                                                                                        return <Option value={val._id}>{val.register_name}</Option>;
                                                                                    })}
                                                                                </Select>
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name="payment_type"
                                                                                label="Payment type"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Payment Type is required",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Radio.Group
                                                                                    onChange={(e) => setType(e.target.value)}
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                >
                                                                                    <Radio value="pay_at_user">Full Payment (Prepaid)</Radio>{" "}
                                                                                    <Radio value="custom">Partial Payment (50% Deposit)</Radio>
                                                                                    <Radio value="pay_at_hotel">Pay at Hotel</Radio>
                                                                                </Radio.Group>
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name="price_adjustment"
                                                                                style={{
                                                                                    display: "inline-block",
                                                                                    width: "calc(50% - 12px)",
                                                                                }}
                                                                                label="Price Adjustment"
                                                                                initialValue={"decrease"}
                                                                            >
                                                                                <Select
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                    placeholder="Price Adjustment"
                                                                                >
                                                                                    <Option value={"decrease"}>Decrease</Option>
                                                                                    <Option value={"increase"}>Increase</Option>
                                                                                </Select>
                                                                            </Form.Item>
                                                                            <span
                                                                                style={{
                                                                                    display: "inline-block",
                                                                                    width: "24px",
                                                                                    lineHeight: "32px",
                                                                                    textAlign: "center",
                                                                                }}
                                                                            ></span>
                                                                            <Form.Item
                                                                                name="percentage_adjustment"
                                                                                style={{
                                                                                    display: "inline-block",
                                                                                    width: "calc(50% - 12px)",
                                                                                }}
                                                                                label="Percentage of adjustment"
                                                                                initialValue={"0"}
                                                                            >
                                                                                <Input
                                                                                    type="number"
                                                                                    max={100}
                                                                                    style={{ marginBottom: 6 }}
                                                                                    placeholder="Percentage of adjustment"
                                                                                    onKeyPress={(event) => {
                                                                                        if (event.key.match("[0-9]+")) {
                                                                                            return true;
                                                                                        } else {
                                                                                            return event.preventDefault();
                                                                                        }
                                                                                    }}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name="hotel_features"
                                                                                label="Hotel Features"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Hotel Features is required",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <TreeSelect
                                                                                    showSearch
                                                                                    style={{
                                                                                        width: "100%",
                                                                                    }}
                                                                                    value={value}
                                                                                    dropdownStyle={{
                                                                                        maxHeight: 400,
                                                                                        overflow: "auto",
                                                                                    }}
                                                                                    placeholder="Please select"
                                                                                    allowClear
                                                                                    multiple
                                                                                    treeDefaultExpandAll
                                                                                    onChange={onChange}
                                                                                    treeData={treeData}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name="currency"
                                                                                label="Currency"
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "currency is required",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <Select
                                                                                    style={{
                                                                                        width: "100%",
                                                                                        marginBottom: 10,
                                                                                    }}
                                                                                    placeholder="Select a currency"
                                                                                >
                                                                                    {supportedCurrencies.map((val) => {
                                                                                        return <Option value={val}>{val}</Option>;
                                                                                    })}
                                                                                </Select>
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name="cancel_policy"
                                                                                label={
                                                                                    <span>
                                                                                        Cancellation Policy&nbsp;&nbsp;
                                                                                        <Tooltip
                                                                                            title="Use this fields to Add summary for your room type"
                                                                                            color="#FFFF"
                                                                                        >
                                                                                            <InfoCircleFilled style={{ color: "#AD005A" }} />
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                }
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: "Please Enter Cancellation Policy",
                                                                                    },
                                                                                ]}
                                                                            >
                                                                                <TextArea
                                                                                    style={{ marginBottom: 10 }}
                                                                                    placeholder="Enter Cancellation Policy"
                                                                                />
                                                                            </Form.Item>
                                                                            <div className="add-form-action" style={{ margin: "10px 0px 2px 0px" }}>
                                                                                <Form.Item>
                                                                                    <NavLink to="/settings/booking-engine-setup">
                                                                                        <Button
                                                                                            className="btn-cancel btn-custom"
                                                                                            style={{ background: "white" }}
                                                                                        >
                                                                                            Go Back
                                                                                        </Button>
                                                                                    </NavLink>
                                                                                    <Button
                                                                                        size="small"
                                                                                        className="btn-custom"
                                                                                        type="primary"
                                                                                        raised
                                                                                        onClick={() => validateFields("2")}
                                                                                    >
                                                                                        Next
                                                                                    </Button>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </TabPane>

                                                    <TabPane tab="Hotel Info" key="2">
                                                        {loader ? (
                                                            <Spin
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className="add-product-block">
                                                                <Row gutter={15}>
                                                                    <Col span={24}>
                                                                        <Form.Item
                                                                            name="hotel_title"
                                                                            label="Hotel Title"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please Enter Hotel Title",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input step="any" placeholder="Enter Hotel Title" style={{ marginBottom: 10 }} />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name="hotel_description"
                                                                            label={
                                                                                <span>
                                                                                    Hotel Description&nbsp;&nbsp;
                                                                                    <Tooltip
                                                                                        title="Use this fields to Add summary for your room type"
                                                                                        color="#FFFF"
                                                                                    >
                                                                                        <InfoCircleFilled style={{ color: "#AD005A" }} />
                                                                                    </Tooltip>
                                                                                </span>
                                                                            }
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please Enter  Hotel Description",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <TextArea style={{ marginBottom: 10 }} placeholder="Enter  Hotel Description" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name="hotel_mobile"
                                                                            style={{
                                                                                display: "inline-block",
                                                                                width: "calc(50% - 12px)",
                                                                            }}
                                                                            label="Hotel Phone"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please Enter  Hotel Phone",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Enter Hotel Phone Number" />
                                                                        </Form.Item>
                                                                        <span
                                                                            style={{
                                                                                display: "inline-block",
                                                                                width: "24px",
                                                                                lineHeight: "32px",
                                                                                textAlign: "center",
                                                                            }}
                                                                        ></span>
                                                                        <Form.Item
                                                                            name="hotel_email"
                                                                            style={{
                                                                                display: "inline-block",
                                                                                width: "calc(50% - 12px)",
                                                                            }}
                                                                            label="Hotel Email"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please Enter  Hotel Email",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input type="email" style={{ marginBottom: 6 }} placeholder="Enter Hotel Email" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name="hotel_address"
                                                                            label={<span>Hotel Address&nbsp;&nbsp;</span>}
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Please Enter  Hotel Address",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <TextArea style={{ marginBottom: 10 }} placeholder="Enter  Hotel Address" />
                                                                        </Form.Item>
                                                                        <Form.Item name="bg_color" label="Choose a theme color" initialValue="#2d2a21">
                                                                            <Radio.Group className="cust_rabt">
                                                                                <Radio value="#2d2a21">
                                                                                    <Tag color="#2d2a21" style={{ cursor: "pointer" }} type="medium">
                                                                                        #2d2a21
                                                                                    </Tag>
                                                                                </Radio>
                                                                                <Radio value="#3D52A0">
                                                                                    <Tag color="#3D52A0" style={{ cursor: "pointer" }}>
                                                                                        #3D52A0
                                                                                    </Tag>
                                                                                </Radio>
                                                                                <Radio value="#244855">
                                                                                    <Tag color="#244855" style={{ cursor: "pointer" }}>
                                                                                        #244855
                                                                                    </Tag>
                                                                                </Radio>
                                                                                <Radio value="#2E3944">
                                                                                    <Tag color="#2E3944" style={{ cursor: "pointer" }}>
                                                                                        #2E3944
                                                                                    </Tag>
                                                                                </Radio>
                                                                                <Radio value="#464866">
                                                                                    <Tag color="#464866" style={{ cursor: "pointer" }}>
                                                                                        #464866
                                                                                    </Tag>
                                                                                </Radio>
                                                                                <Radio value="#2B7A78">
                                                                                    <Tag color="#2B7A78" style={{ cursor: "pointer" }}>
                                                                                        #2B7A78
                                                                                    </Tag>
                                                                                </Radio>
                                                                                <Radio value="#61892F">
                                                                                    <Tag color="#61892F" style={{ cursor: "pointer" }}>
                                                                                        #61892F
                                                                                    </Tag>
                                                                                </Radio>{" "}
                                                                                <Radio value="#46344E">
                                                                                    <Tag color="#46344E" style={{ cursor: "pointer" }}>
                                                                                        #46344E
                                                                                    </Tag>
                                                                                </Radio>{" "}
                                                                                <Radio value="#687864">
                                                                                    <Tag color="#687864" style={{ cursor: "pointer" }}>
                                                                                        #687864
                                                                                    </Tag>
                                                                                </Radio>
                                                                                <Radio value="#2D283E">
                                                                                    <Tag color="#2D283E" style={{ cursor: "pointer" }}>
                                                                                        #2D283E
                                                                                    </Tag>
                                                                                </Radio>{" "}
                                                                                <Radio value="#542F34">
                                                                                    <Tag color="#542F34" style={{ cursor: "pointer" }}>
                                                                                        #542F34
                                                                                    </Tag>
                                                                                </Radio>
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                        <div className="add-form-action" style={{ margin: "10px 0px 2px 0px" }}>
                                                                            <Form.Item>
                                                                                <NavLink to="/settings/booking-engine-setup">
                                                                                    <Button className="btn-cancel btn-custom" style={{ background: "white" }}>
                                                                                        Go Back
                                                                                    </Button>
                                                                                </NavLink>

                                                                                <Button
                                                                                    size="small"
                                                                                    className="btn-custom"
                                                                                    type="primary"
                                                                                    raised
                                                                                    onClick={() => validateFields("3")}
                                                                                >
                                                                                    Next
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </TabPane>
                                                    <TabPane tab="Add Section" key="3">
                                                        {loader ? (
                                                            <Spin
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className="add-product-block">
                                                                <Row gutter={15}>
                                                                    <Col span={24}>
                                                                        <Form.Item
                                                                            label="Section Title"
                                                                            name="section_title"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Section title is required",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input style={{ marginBottom: 10 }} placeholder="Section Title" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            label="Section Description"
                                                                            name="section_description"
                                                                            rules={[
                                                                                {
                                                                                    required: true,
                                                                                    message: "Section Description is required",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <TextArea style={{ marginBottom: 10 }} placeholder="Section Description" />
                                                                        </Form.Item>

                                                                        <Form.Item
                                                                            name="section_image"
                                                                            label="Section image"
                                                                            valuePropName="fileList"
                                                                            getValueFromEvent={(e) => {
                                                                                if (Array.isArray(e)) {
                                                                                    return e;
                                                                                }
                                                                                return e && e.fileList;
                                                                            }}
                                                                            rules={[
                                                                                {
                                                                                    required: fileList?.length ? false : true,
                                                                                    message: "Please upload an image",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <p style={{ display: "none" }}>{fileList?.length}</p>
                                                                            <Upload
                                                                                name="avatar"
                                                                                listType="picture-card"
                                                                                maxCount={1} // Restrict to one image
                                                                                action="/upload" // Replace with your actual upload RL
                                                                                fileList={fileList}
                                                                                onPreview={handlePreview}
                                                                                onChange={handleChange}
                                                                                onRemove={handleRemove}
                                                                            >
                                                                                {fileList.length < 1 && <div>{uploadButton}</div>}
                                                                            </Upload>
                                                                        </Form.Item>
                                                                        {previewImage && (
                                                                            <Image
                                                                                wrapperStyle={{
                                                                                    display: "none",
                                                                                }}
                                                                                preview={{
                                                                                    visible: previewOpen,
                                                                                    onVisibleChange: (visible) => setPreviewOpen(visible),
                                                                                    afterOpenChange: (visible) => !visible && setPreviewImage(""),
                                                                                }}
                                                                                src={previewImage}
                                                                            />
                                                                        )}
                                                                        {extraRateList && extraRateList.length > 0 ? (
                                                                            <div className="extraRateContainer">
                                                                                <h3>Section List</h3>

                                                                                <div className="extraRateContent">
                                                                                    {extraRateList?.map((data, i) => {
                                                                                        return (
                                                                                            <div className="extraRateDetail">
                                                                                                <div className="detail-header">
                                                                                                    <h4
                                                                                                        style={{
                                                                                                            fontWeight: 600,
                                                                                                        }}
                                                                                                    >
                                                                                                        {data.section_title}
                                                                                                    </h4>
                                                                                                    <div className="actions">
                                                                                                        <EditOutlined
                                                                                                            onClick={() => {
                                                                                                                setEdit(true);
                                                                                                                setEditIndex(i);
                                                                                                                showAddRateModal(data);
                                                                                                            }}
                                                                                                        />
                                                                                                        <DeleteOutlined
                                                                                                            onClick={() => confirmDelete(i, data?.rate_name)}
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <Descriptions
                                                                                                    bordered
                                                                                                    size="small"
                                                                                                    labelStyle={{
                                                                                                        width: "300px",
                                                                                                    }}
                                                                                                >
                                                                                                    <Descriptions.Item label="Section Description" span={3}>
                                                                                                        {data?.section_description}
                                                                                                    </Descriptions.Item>

                                                                                                    <Descriptions.Item label="Section Image" span={3}>
                                                                                                        <Image src={data.section_image} />
                                                                                                    </Descriptions.Item>
                                                                                                </Descriptions>
                                                                                            </div>
                                                                                        );
                                                                                    })}
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            ""
                                                                        )}
                                                                        <div className="add-form-action" style={{ margin: "10px 0px 2px 0px" }}>
                                                                            <Form.Item>
                                                                                <NavLink to="/settings/booking-engine-setup">
                                                                                    <Button className="btn-cancel btn-custom" style={{ background: "white" }}>
                                                                                        Go Back
                                                                                    </Button>
                                                                                </NavLink>
                                                                                <Button
                                                                                    size="small"
                                                                                    className="btn-custom"
                                                                                    type="primary"
                                                                                    raised
                                                                                    style={{ marginRight: "20px" }}
                                                                                    onClick={showAddRateModal}
                                                                                >
                                                                                    <PlusOutlined /> Add More Section
                                                                                </Button>
                                                                                <Button
                                                                                    size="small"
                                                                                    className="btn-custom"
                                                                                    type="primary"
                                                                                    raised
                                                                                    onClick={() => validateFields("4")}
                                                                                >
                                                                                    Next
                                                                                </Button>
                                                                            </Form.Item>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </TabPane>
                                                    <TabPane tab="Social Links" key="4">
                                                        {loader ? (
                                                            <Spin
                                                                style={{
                                                                    display: "flex",
                                                                    justifyContent: "center",
                                                                }}
                                                            />
                                                        ) : (
                                                            <div className="add-product-block">
                                                                <Row gutter={15}>
                                                                    <Col xs={24}>
                                                                        <div className="add-product-content">
                                                                            <Form.Item
                                                                                name="facebook_link"
                                                                                label={
                                                                                    <span>
                                                                                        Facebook&nbsp;&nbsp;
                                                                                        <Tooltip
                                                                                            title="This link will be shown in receipt footer of digital receipts"
                                                                                            color="#FFFF"
                                                                                        >
                                                                                            <InfoCircleFilled style={{ color: "#AD005A" }} />
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <Input
                                                                                    style={{ marginBottom: 20 }}
                                                                                    className="facebook social_input"
                                                                                    prefix={<FacebookOutlined style={{ fontSize: "20px" }} />}
                                                                                    placeholder="https://www.facebook.com/<your profile> (Optional)"
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item
                                                                                name="instagram_link"
                                                                                label={
                                                                                    <span>
                                                                                        Instagram&nbsp;&nbsp;
                                                                                        <Tooltip
                                                                                            title="This link will be shown in receipt footer of digital receipts"
                                                                                            color="#FFFF"
                                                                                        >
                                                                                            <InfoCircleFilled style={{ color: "#AD005A" }} />
                                                                                        </Tooltip>
                                                                                    </span>
                                                                                }
                                                                            >
                                                                                <Input
                                                                                    style={{ marginBottom: 20 }}
                                                                                    className="instagram social_input"
                                                                                    prefix={<InstagramOutlined style={{ fontSize: "20px" }} />}
                                                                                    placeholder="https://www.instagram.com/<your profile> (Optional)"
                                                                                />
                                                                            </Form.Item>{" "}
                                                                            <div className="add-form-action" style={{ margin: "10px 0px 2px 0px" }}>
                                                                                <Form.Item>
                                                                                    <NavLink to="/settings/booking-engine-setup">
                                                                                        <Button
                                                                                            className="btn-cancel btn-custom"
                                                                                            style={{
                                                                                                background: "white",
                                                                                            }}
                                                                                        >
                                                                                            Go Back
                                                                                        </Button>
                                                                                    </NavLink>

                                                                                    <Button
                                                                                        size="small"
                                                                                        className="btn-custom"
                                                                                        htmlType="submit"
                                                                                        type="primary"
                                                                                        raised
                                                                                    >
                                                                                        {loading ? (
                                                                                            <Spin
                                                                                                indicator={
                                                                                                    <LoadingOutlined
                                                                                                        style={{
                                                                                                            fontSize: 16,
                                                                                                            color: "white",
                                                                                                            margin: "0px 8px",
                                                                                                        }}
                                                                                                        spin
                                                                                                    />
                                                                                                }
                                                                                            />
                                                                                        ) : (
                                                                                            "Save"
                                                                                        )}
                                                                                    </Button>
                                                                                </Form.Item>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        )}
                                                    </TabPane>
                                                </Tabs>
                                            </BasicFormWrapper>
                                        </Form>
                                    </AddProductForm>
                                </Col>
                            </Row>
                        </Cards>

                        <Modal
                            title="Add More Section"
                            open={openAddModal}
                            onOk={addForm.submit}
                            onCancel={() => {
                                setOpenAddModal(false);
                                setEditIndex();
                                setEdit(false);
                            }}
                        >
                            <div className="addRatePlanForm">
                                <Form style={{ width: "100%" }} form={addForm} name="addNewRate" onFinish={submitAddRate} className="comman-input">
                                    <BasicFormWrapper>
                                        <Row gutter={15}>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="Section Title"
                                                    name="section_title"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Section title is required",
                                                        },
                                                    ]}
                                                >
                                                    <Input style={{ marginBottom: 10 }} placeholder="Section Title" />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Section Description"
                                                    name="section_description"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: "Section Description is required",
                                                        },
                                                    ]}
                                                >
                                                    <TextArea style={{ marginBottom: 10 }} placeholder="Section Description" />
                                                </Form.Item>

                                                <p style={{ display: "none" }}>{moreFileList?.length}</p>
                                                <Form.Item
                                                    name="section_image"
                                                    label="Section image"
                                                    valuePropName="fileList"
                                                    getValueFromEvent={(e) => {
                                                        if (Array.isArray(e)) {
                                                            return e;
                                                        }
                                                        return e && e.moreFileList;
                                                    }}
                                                    rules={[
                                                        {
                                                            required: moreFileList?.length ? false : true,
                                                            message: "Please upload an image",
                                                        },
                                                    ]}
                                                >
                                                    <p style={{ display: "none" }}>{moreFileList?.length}</p>
                                                    <Upload
                                                        name="avatar"
                                                        listType="picture-card"
                                                        maxCount={1} // Restrict to one image
                                                        action="/upload" // Replace with your actual upload RL
                                                        fileList={moreFileList}
                                                        onPreview={handlePreview}
                                                        onChange={handleChange1}
                                                        onRemove={handleRemove1}
                                                    >
                                                        {moreFileList.length < 1 && <Button icon={<UploadOutlined />}>Click to Upload</Button>}
                                                    </Upload>
                                                </Form.Item>
                                                {previewImage && (
                                                    <Image
                                                        wrapperStyle={{
                                                            display: "none",
                                                        }}
                                                        preview={{
                                                            visible: previewOpen,
                                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                                            afterOpenChange: (visible) => !visible && setPreviewImage(""),
                                                        }}
                                                        src={previewImage}
                                                    />
                                                )}
                                            </Col>
                                        </Row>
                                    </BasicFormWrapper>
                                </Form>
                            </div>
                        </Modal>
                    </Col>
                </Row>
                {/* </Main> */}
            </Cards>
        </>
    );
};

export default AddBookingSetup;
