import React, { useState, useRef, useEffect } from "react";
import CurrentReservation from "./CurrentReservation";
import AssignRoom from "./AssignRoom";
const CurrentReservationBuilder = ({ newReservation, tabChangeToCurrentFunction, reservationDetails, currentRegisterData, showbilling }) => {
    return (
        <>
            {newReservation ? (
                <CurrentReservation
                    tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                    roomDetails={reservationDetails}
                    currentRegisterData={currentRegisterData}
                    showbilling={showbilling}
                />
            ) : (
                <AssignRoom
                    tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                    reservationDetails={reservationDetails}
                    currentRegisterData={currentRegisterData}
                    showbilling={showbilling}
                />
            )}
        </>
    );
};

export default CurrentReservationBuilder;
