import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Tabs, Input, Form, Card, Button, Modal, Tooltip } from "antd";
import { Main } from "../styled";
import { BookingList } from "./Booking/BookingList";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getCartInfoFromLocalKey, getItem, setItem } from "../../utility/localStorageControl";
import Lock from "../Sell/Lock";
import { generate_random_number, generate_random_string } from "../../utility/utility";
import "../Sell/sell.css";
import Billing from "../Billing/Billing";
import { Reservation } from "./order/Reservation";

import CurrentReservationBuilder from "./current/CurrentReservationBuilder";
import Calendars from "../../components/calendar/Calendar";

const ReservationBuilder = () => {
    const { TabPane } = Tabs;
    const dispatch = useDispatch();
    const location = useLocation();
    const didMount = useRef(false);

    const [stayTime, setStayTime] = useState({});
    const [reservationDetails, setReservationDetails] = useState();
    const [newReservation, setNewReservation] = useState(false);
    const [quickReservationDetails, setQuickReservationDetails] = useState();

    const { currentRegisterData } = useSelector((state) => {
        return {
            currentRegisterData: state.register.RegisterList?.length > 0 && state.register.RegisterList?.find((val) => val.activeHotel),
        };
    });
    let showbilling = true;

    if (
        currentRegisterData &&
        currentRegisterData.isHotelMain == false &&
        (currentRegisterData.subscription_status == undefined || currentRegisterData.subscription_status == "unsubscribed")
    ) {
        showbilling = true;
    } else if (
        currentRegisterData &&
        currentRegisterData.isHotelMain == true &&
        currentRegisterData.expires_at_date &&
        (currentRegisterData.subscription_status == undefined || currentRegisterData.subscription_status == "unsubscribed")
    ) {
        showbilling = true;
    } else {
        if (currentRegisterData && currentRegisterData.PMSlastNumber && currentRegisterData.PMSlastNumber >= 100) {
            showbilling = true;
        } else {
            showbilling = false;
        }
    }
    console.log("showbilling", showbilling);
    let [activeReservationTab, setActiveReservationTab] = useState("ORDER");
    const tabChangeToCurrentFunction = (tab, newReservationClick, details, checkQuickReservation) => {
        console.log("detailsdetails", details);
        setActiveReservationTab(tab);
        if (checkQuickReservation) {
            setQuickReservationDetails(details);
        } else {
            setQuickReservationDetails();
            if (details) {
                setReservationDetails({ ...details });
            } else {
                setReservationDetails();
            }
        }
        setNewReservation(newReservationClick ? true : false);
    };

    useEffect(() => {
        if (quickReservationDetails) {
            setReservationDetails({ ...quickReservationDetails });
        }
    }, [quickReservationDetails]);

    useEffect(() => {
        console.log("sadfadfafafafasfasffasf", showbilling);
        if (showbilling) {
            setActiveReservationTab("BOOKING");
        } else {
            if (location && location.state && location.state.reservationData) {
                tabChangeToCurrentFunction("CURRENT", false, location.state.reservationData);
            }
        }
    }, [currentRegisterData]);

    return (
        <>
            <Main className="sellscroll" style={getItem("userDetails")?.role == "cashier" ? { marginTop: "50px" } : {}}>
                {showbilling && false ? (
                    <Billing currentRegisterData={currentRegisterData} />
                ) : (
                    <>
                        <Row>
                            <Col span={24}>
                                <Tabs
                                    className="sell-tabs"
                                    type="card"
                                    activeKey={activeReservationTab}
                                    size="small"
                                    onChange={(val) => {
                                        setActiveReservationTab(val);
                                    }}
                                >
                                    <TabPane tab={<span className="drft_counno">Rooms</span>} key="ORDER" style={{ outline: "none" }} />
                                    <TabPane tab="Reservations" key="BOOKING" style={{ outline: "none" }} />
                                    <TabPane key="calender_booking" tab="Calendar" style={{ outline: "none" }} />{" "}
                                    <TabPane tab="Current" disabled key="CURRENT" style={{ outline: "none" }} />
                                </Tabs>
                            </Col>
                        </Row>

                        <Row gutter={25}>
                            {console.log("activeReservationTabactiveReservationTabactiveReservationTabactiveReservationTab", activeReservationTab)}
                            <Col span={24}>
                                {activeReservationTab === "ORDER" ? (
                                    <Reservation tabChangeToCurrentFunction={tabChangeToCurrentFunction} currentRegisterData={currentRegisterData} />
                                ) : activeReservationTab === "BOOKING" ? (
                                    <BookingList
                                        setNewReservation={setNewReservation}
                                        tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                                        currentRegisterData={currentRegisterData}
                                        setReservationDetails={setReservationDetails}
                                        setActiveReservationTab={setActiveReservationTab}
                                    />
                                ) : activeReservationTab === "CURRENT" ? (
                                    <CurrentReservationBuilder
                                        stayDuration={stayTime}
                                        newReservation={newReservation}
                                        tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                                        reservationDetails={reservationDetails}
                                        currentRegisterData={currentRegisterData}
                                        showbilling={showbilling}
                                    />
                                ) : activeReservationTab === "calender_booking" ? (
                                    <Calendars
                                        type="calendar"
                                        roomTypeList={[]}
                                        setNewReservation={setNewReservation}
                                        setReservationDetails={setReservationDetails}
                                        setActiveReservationTab={setActiveReservationTab}
                                        currentRegisterData={currentRegisterData}
                                        tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                                    />
                                ) : (
                                    ""
                                )}
                            </Col>
                        </Row>
                    </>
                )}
            </Main>
        </>
    );
};

export default ReservationBuilder;
