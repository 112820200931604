import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Table, Modal, Form, Input, Radio, Tag, Tooltip, Spin, Popover } from "antd";
import ReactDOMServer from "react-dom/server";
import { useHistory } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useSelector } from "react-redux";
import { InvoiceLetterBox, ProductTable, OrderSummary } from "./Style";
import OrderTicketPrint from "../Sell/Current/OrderTicketPrint";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import Heading from "../../components/heading/heading";
import { Button } from "../../components/buttons/buttons";
import { useDispatch } from "react-redux";
import ReceiptPrint from "../Sell/Print/ReceiptPrint";
import { getReceiptsById, cancelOrder, deleteReceipt } from "../../redux/receipts/actionCreator";
import commonFunction from "../../utility/commonFunctions";
import { getItem } from "../../utility/localStorageControl";
import { ExclamationCircleOutlined, WhatsAppOutlined } from "@ant-design/icons";
import { getAllPaymentTypeList } from "../../redux/customField/actionCreator";
import { UnPaidReceipts } from "./UnPaidReceipts";
import { UnpaidBookingReceipts } from "./UnpaidBookingReceipts";
import "./receipt.css";
import { EditOutlined, LoadingOutlined } from "@ant-design/icons";
import { EditBookingReceipts } from "./EditBookingReceipts";
import { AddAndUpdateBooking } from "../../redux/sell/actionCreator";
import { getShopDetail } from "../../redux/shop/actionCreator";
import { getAllSetUpPrinterList } from "../../redux/printer/actionCreator";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
// import { LoadingOutlined } from "@ant-design/icons";

const Invoice = ({ match }) => {
    const [loading, setLoading] = useState(false);
    let isMounted = useRef(true);
    const editRef = useRef();
    const [rsSymbol, setRsSymbol] = useState(
        getItem("setupCache")?.shopDetails?.rs_symbol
            ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)?.length > 0
                ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
                : getItem("setupCache").shopDetails.rs_symbol
            : "₹"
    );
    const [phoneNumber, setPhoneNumber] = useState({
        number: "",
        code: "",
        codeString: "IN",
    });
    let [RecepitsData, setRecepitsData] = useState();
    const [emailVisible, setEmailVisible] = useState(false);
    const [ItemsList, setItemsList] = useState([]);
    const [modalVisibleOrderCancel, setModalVisibleOrderCancel] = useState(false);
    const [modalVisibleConfirmCancel, setModalVisibleConfirmCancel] = useState(false);
    const [deleteReceiptsModalVisible, setDeleteReceiptsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [PaymentType, setPaymentType] = useState();
    const [PaymentTypeList, setPaymentTypeList] = useState([]);
    const [deletebuttonShow, setDeleteButton] = useState(false);
    const history = useHistory();
    const [refundAmount, setRefundAmount] = useState(0);
    let [setupList, setsetupPrinterList] = useState([]);
    const [shopDetails, setShopDetails] = useState();
    const [registerdata, setRegisterData] = useState();
    const registerDetails = useSelector((state) => state.register.RegisterList.find((val) => val.active));
    const userDetails = getItem("userDetails");

    let countrySymbol = [
        {
            name: "Afghanistan",
            code: "AF",
            capital: "Kabul",
            region: "AS",
            currency: {
                code: "AFN",
                name: "Afghan afghani",
                symbol: "؋",
            },
            language: {
                code: "ps",
                name: "Pashto",
            },
            flag: "https://restcountries.eu/data/afg.svg",
        },
        {
            name: "Åland Islands",
            code: "AX",
            capital: "Mariehamn",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "sv",
                name: "Swedish",
            },
            flag: "https://restcountries.eu/data/ala.svg",
        },
        {
            name: "Albania",
            code: "AL",
            capital: "Tirana",
            region: "EU",
            currency: {
                code: "ALL",
                name: "Albanian lek",
                symbol: "L",
            },
            language: {
                code: "sq",
                name: "Albanian",
            },
            flag: "https://restcountries.eu/data/alb.svg",
        },
        {
            name: "Algeria",
            code: "DZ",
            capital: "Algiers",
            region: "AF",
            currency: {
                code: "DZD",
                name: "Algerian dinar",
                symbol: "د.ج",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/dza.svg",
        },
        {
            name: "American Samoa",
            code: "AS",
            capital: "Pago Pago",
            region: "OC",
            currency: {
                code: "USD",
                name: "United State Dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/asm.svg",
        },
        {
            name: "Andorra",
            code: "AD",
            capital: "Andorra la Vella",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "ca",
                name: "Catalan",
            },
            flag: "https://restcountries.eu/data/and.svg",
        },
        {
            name: "Angola",
            code: "AO",
            capital: "Luanda",
            region: "AF",
            currency: {
                code: "AOA",
                name: "Angolan kwanza",
                symbol: "Kz",
            },
            language: {
                code: "pt",
                name: "Portuguese",
            },
            flag: "https://restcountries.eu/data/ago.svg",
        },
        {
            name: "Anguilla",
            code: "AI",
            capital: "The Valley",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/aia.svg",
        },
        {
            name: "Antigua and Barbuda",
            code: "AG",
            capital: "Saint John's",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/atg.svg",
        },
        {
            name: "Argentina",
            code: "AR",
            capital: "Buenos Aires",
            region: "SA",
            currency: {
                code: "ARS",
                name: "Argentine peso",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/arg.svg",
        },

        {
            name: "Aruba",
            code: "AW",
            capital: "Oranjestad",
            region: "SA",
            currency: {
                code: "AWG",
                name: "Aruban florin",
                symbol: "ƒ",
            },
            language: {
                code: "nl",
                name: "Dutch",
            },
            flag: "https://restcountries.eu/data/abw.svg",
        },
        {
            name: "Australia",
            code: "AU",
            capital: "Canberra",
            region: "OC",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/aus.svg",
        },
        {
            name: "Austria",
            code: "AT",
            capital: "Vienna",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "de",
                name: "German",
            },
            flag: "https://restcountries.eu/data/aut.svg",
        },

        {
            name: "Bahamas",
            code: "BS",
            capital: "Nassau",
            region: "NA",
            currency: {
                code: "BSD",
                name: "Bahamian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/bhs.svg",
        },
        {
            name: "Bahrain",
            code: "BH",
            capital: "Manama",
            region: "AS",
            currency: {
                code: "BHD",
                name: "Bahraini dinar",
                symbol: ".د.ب",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/bhr.svg",
        },
        {
            name: "Bangladesh",
            code: "BD",
            capital: "Dhaka",
            region: "AS",
            currency: {
                code: "BDT",
                name: "Bangladeshi taka",
                symbol: "৳",
            },
            language: {
                code: "bn",
                name: "Bengali",
            },
            flag: "https://restcountries.eu/data/bgd.svg",
        },
        {
            name: "Barbados",
            code: "BB",
            capital: "Bridgetown",
            region: "NA",
            currency: {
                code: "BBD",
                name: "Barbadian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/brb.svg",
        },
        {
            name: "Belarus",
            code: "BY",
            capital: "Minsk",
            region: "EU",
            currency: {
                code: "BYN",
                name: "New Belarusian ruble",
                symbol: "Br",
            },
            language: {
                code: "be",
                name: "Belarusian",
            },
            flag: "https://restcountries.eu/data/blr.svg",
        },
        {
            name: "Belgium",
            code: "BE",
            capital: "Brussels",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "nl",
                name: "Dutch",
            },
            flag: "https://restcountries.eu/data/bel.svg",
        },
        {
            name: "Belize",
            code: "BZ",
            capital: "Belmopan",
            region: "NA",
            currency: {
                code: "BZD",
                name: "Belize dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/blz.svg",
        },
        {
            name: "Benin",
            code: "BJ",
            capital: "Porto-Novo",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/ben.svg",
        },
        {
            name: "Bermuda",
            code: "BM",
            capital: "Hamilton",
            region: "NA",
            currency: {
                code: "BMD",
                name: "Bermudian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/bmu.svg",
        },
        {
            name: "Bhutan",
            code: "BT",
            capital: "Thimphu",
            region: "AS",
            currency: {
                code: "BTN",
                name: "Bhutanese ngultrum",
                symbol: "Nu.",
            },
            language: {
                code: "dz",
                name: "Dzongkha",
            },
            flag: "https://restcountries.eu/data/btn.svg",
        },
        {
            name: "Bolivia (Plurinational State of)",
            code: "BO",
            capital: "Sucre",
            region: "SA",
            currency: {
                code: "BOB",
                name: "Bolivian boliviano",
                symbol: "Bs.",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/bol.svg",
        },
        {
            name: "Bonaire, Sint Eustatius and Saba",
            code: "BQ",
            capital: "Kralendijk",
            region: "SA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "nl",
                name: "Dutch",
            },
            flag: "https://restcountries.eu/data/bes.svg",
        },

        {
            name: "Botswana",
            code: "BW",
            capital: "Gaborone",
            region: "AF",
            currency: {
                code: "BWP",
                name: "Botswana pula",
                symbol: "P",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/bwa.svg",
        },
        {
            name: "Bouvet Island",
            code: "BV",
            capital: "",
            region: "AN",
            currency: {
                code: "NOK",
                name: "Norwegian krone",
                symbol: "kr",
            },
            language: {
                code: "no",
                name: "Norwegian",
            },
            flag: "https://restcountries.eu/data/bvt.svg",
        },
        {
            name: "Brazil",
            code: "BR",
            capital: "Brasília",
            region: "SA",
            currency: {
                code: "BRL",
                name: "Brazilian real",
                symbol: "R$",
            },
            language: {
                code: "pt",
                name: "Portuguese",
            },
            flag: "https://restcountries.eu/data/bra.svg",
        },
        {
            name: "British Indian Ocean Territory",
            code: "IO",
            capital: "Diego Garcia",
            region: "AF",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/iot.svg",
        },
        {
            name: "United States Minor Outlying Islands",
            code: "UM",
            capital: "",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States Dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/umi.svg",
        },
        {
            name: "Virgin Islands (British)",
            code: "VG",
            capital: "Road Town",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/vgb.svg",
        },
        {
            name: "Virgin Islands (U.S.)",
            code: "VI",
            capital: "Charlotte Amalie",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/vir.svg",
        },
        {
            name: "Brunei Darussalam",
            code: "BN",
            capital: "Bandar Seri Begawan",
            region: "AS",
            currency: {
                code: "BND",
                name: "Brunei dollar",
                symbol: "$",
            },
            language: {
                code: "ms",
                name: "Malay",
            },
            flag: "https://restcountries.eu/data/brn.svg",
        },
        {
            name: "Bulgaria",
            code: "BG",
            capital: "Sofia",
            region: "EU",
            currency: {
                code: "BGN",
                name: "Bulgarian lev",
                symbol: "лв",
            },
            language: {
                code: "bg",
                name: "Bulgarian",
            },
            flag: "https://restcountries.eu/data/bgr.svg",
        },
        {
            name: "Burkina Faso",
            code: "BF",
            capital: "Ouagadougou",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/bfa.svg",
        },
        {
            name: "Burundi",
            code: "BI",
            capital: "Bujumbura",
            region: "AF",
            currency: {
                code: "BIF",
                name: "Burundian franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/bdi.svg",
        },
        {
            name: "Cambodia",
            code: "KH",
            capital: "Phnom Penh",
            region: "AS",
            currency: {
                code: "KHR",
                name: "Cambodian riel",
                symbol: "៛",
            },
            language: {
                code: "km",
                name: "Khmer",
            },
            flag: "https://restcountries.eu/data/khm.svg",
        },
        {
            name: "Cameroon",
            code: "CM",
            capital: "Yaoundé",
            region: "AF",
            currency: {
                code: "XAF",
                name: "Central African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/cmr.svg",
        },
        {
            name: "Canada",
            code: "CA",
            capital: "Ottawa",
            region: "NA",
            currency: {
                code: "CAD",
                name: "Canadian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/can.svg",
        },
        {
            name: "Cabo Verde",
            code: "CV",
            capital: "Praia",
            region: "AF",
            currency: {
                code: "CVE",
                name: "Cape Verdean escudo",
                symbol: "Esc",
            },
            language: {
                code: "pt",
                iso639_2: "por",
                name: "Portuguese",
                nativeName: "Português",
            },
            flag: "https://restcountries.eu/data/cpv.svg",
        },
        {
            name: "Cayman Islands",
            code: "KY",
            capital: "George Town",
            region: "NA",
            demonym: "Caymanian",
            currency: {
                code: "KYD",
                name: "Cayman Islands dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/cym.svg",
        },
        {
            name: "Central African Republic",
            code: "CF",
            capital: "Bangui",
            region: "AF",
            currency: {
                code: "XAF",
                name: "Central African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/caf.svg",
        },
        {
            name: "Chad",
            code: "TD",
            capital: "N'Djamena",
            region: "AF",
            currency: {
                code: "XAF",
                name: "Central African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/tcd.svg",
        },
        {
            name: "Chile",
            code: "CL",
            capital: "Santiago",
            region: "SA",
            currency: {
                code: "CLP",
                name: "Chilean peso",
                symbol: "$",
            },
            language: {
                code: "es",
                iso639_2: "spa",
                name: "Spanish",
                nativeName: "Español",
            },
            flag: "https://restcountries.eu/data/chl.svg",
        },
        {
            name: "China",
            code: "CN",
            capital: "Beijing",
            region: "AS",
            currency: {
                code: "CNY",
                name: "Chinese yuan",
                symbol: "¥",
            },
            language: {
                code: "zh",
                name: "Chinese",
            },
            flag: "https://restcountries.eu/data/chn.svg",
        },
        {
            name: "Christmas Island",
            code: "CX",
            capital: "Flying Fish Cove",
            region: "OC",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/cxr.svg",
        },
        {
            name: "Cocos (Keeling) Islands",
            code: "CC",
            capital: "West Island",
            region: "OC",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/cck.svg",
        },
        {
            name: "Colombia",
            code: "CO",
            capital: "Bogotá",
            region: "SA",
            currency: {
                code: "COP",
                name: "Colombian peso",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/col.svg",
        },
        {
            name: "Comoros",
            code: "KM",
            capital: "Moroni",
            region: "AF",
            currency: {
                code: "KMF",
                name: "Comorian franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/com.svg",
        },
        {
            name: "Congo",
            code: "CG",
            capital: "Brazzaville",
            region: "AF",
            currency: {
                code: "XAF",
                name: "Central African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/cog.svg",
        },
        {
            name: "Congo (Democratic Republic of the)",
            code: "CD",
            capital: "Kinshasa",
            region: "AF",
            currency: {
                code: "CDF",
                name: "Congolese franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/cod.svg",
        },
        {
            name: "Cook Islands",
            code: "CK",
            capital: "Avarua",
            region: "OC",
            currency: {
                code: "NZD",
                name: "New Zealand dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/cok.svg",
        },
        {
            name: "Costa Rica",
            code: "CR",
            capital: "San José",
            region: "NA",
            currency: {
                code: "CRC",
                name: "Costa Rican colón",
                symbol: "₡",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/cri.svg",
        },
        {
            name: "Croatia",
            code: "HR",
            capital: "Zagreb",
            region: "EU",
            currency: {
                code: "HRK",
                name: "Croatian kuna",
                symbol: "kn",
            },
            language: {
                code: "hr",
                name: "Croatian",
            },
            flag: "https://restcountries.eu/data/hrv.svg",
        },
        {
            name: "Cuba",
            code: "CU",
            capital: "Havana",
            region: "NA",
            currency: {
                code: "CUC",
                name: "Cuban convertible peso",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/cub.svg",
        },
        {
            name: "Curaçao",
            code: "CW",
            capital: "Willemstad",
            region: "SA",
            currency: {
                code: "ANG",
                name: "Netherlands Antillean guilder",
                symbol: "ƒ",
            },
            language: {
                code: "nl",
                name: "Dutch",
            },
            flag: "https://restcountries.eu/data/cuw.svg",
        },
        {
            name: "Cyprus",
            code: "CY",
            capital: "Nicosia",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "tr",
                name: "Turkish",
            },
            flag: "https://restcountries.eu/data/cyp.svg",
        },
        {
            name: "Czech Republic",
            code: "CZ",
            capital: "Prague",
            region: "EU",
            currency: {
                code: "CZK",
                name: "Czech koruna",
                symbol: "Kč",
            },
            language: {
                code: "cs",
                name: "Czech",
            },
            flag: "https://restcountries.eu/data/cze.svg",
        },
        {
            name: "Denmark",
            code: "DK",
            capital: "Copenhagen",
            region: "EU",
            currency: {
                code: "DKK",
                name: "Danish krone",
                symbol: "kr",
            },
            language: {
                code: "da",
                name: "Danish",
            },
            flag: "https://restcountries.eu/data/dnk.svg",
        },
        {
            name: "Djibouti",
            code: "DJ",
            capital: "Djibouti",
            region: "AF",
            currency: {
                code: "DJF",
                name: "Djiboutian franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/dji.svg",
        },
        {
            name: "Dominica",
            code: "DM",
            capital: "Roseau",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/dma.svg",
        },
        {
            name: "Dominican Republic",
            code: "DO",
            capital: "Santo Domingo",
            region: "NA",
            currency: {
                code: "DOP",
                name: "Dominican peso",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/dom.svg",
        },
        {
            name: "Ecuador",
            code: "EC",
            capital: "Quito",
            region: "SA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/ecu.svg",
        },
        {
            name: "Egypt",
            code: "EG",
            capital: "Cairo",
            region: "AF",
            currency: {
                code: "EGP",
                name: "Egyptian pound",
                symbol: "£",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/egy.svg",
        },
        {
            name: "El Salvador",
            code: "SV",
            capital: "San Salvador",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/slv.svg",
        },
        {
            name: "Equatorial Guinea",
            code: "GQ",
            capital: "Malabo",
            region: "AF",
            currency: {
                code: "XAF",
                name: "Central African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "es",
                iso639_2: "spa",
                name: "Spanish",
                nativeName: "Español",
            },
            flag: "https://restcountries.eu/data/gnq.svg",
        },
        {
            name: "Eritrea",
            code: "ER",
            capital: "Asmara",
            region: "AF",
            currency: {
                code: "ERN",
                name: "Eritrean nakfa",
                symbol: "Nfk",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/eri.svg",
        },
        {
            name: "Estonia",
            code: "EE",
            capital: "Tallinn",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "et",
                name: "Estonian",
            },
            flag: "https://restcountries.eu/data/est.svg",
        },
        {
            name: "Ethiopia",
            code: "ET",
            capital: "Addis Ababa",
            region: "AF",
            currency: {
                code: "ETB",
                name: "Ethiopian birr",
                symbol: "Br",
            },
            language: {
                code: "am",
                name: "Amharic",
            },
            flag: "https://restcountries.eu/data/eth.svg",
        },
        {
            name: "Falkland Islands (Malvinas)",
            code: "FK",
            capital: "Stanley",
            region: "SA",
            currency: {
                code: "FKP",
                name: "Falkland Islands pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/flk.svg",
        },
        {
            name: "Faroe Islands",
            code: "FO",
            capital: "Tórshavn",
            region: "EU",
            currency: {
                code: "DKK",
                name: "Danish krone",
                symbol: "kr",
            },
            language: {
                code: "fo",
                name: "Faroese",
            },
            flag: "https://restcountries.eu/data/fro.svg",
        },
        {
            name: "Fiji",
            code: "FJ",
            capital: "Suva",
            region: "OC",
            currency: {
                code: "FJD",
                name: "Fijian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/fji.svg",
        },
        {
            name: "Finland",
            code: "FI",
            capital: "Helsinki",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fi",
                iso639_2: "fin",
                name: "Finnish",
                nativeName: "suomi",
            },
            flag: "https://restcountries.eu/data/fin.svg",
        },
        {
            name: "France",
            code: "FR",
            capital: "Paris",
            region: "EU",
            demonym: "French",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/fra.svg",
        },
        {
            name: "French Guiana",
            code: "GF",
            capital: "Cayenne",
            region: "SA",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/guf.svg",
        },
        {
            name: "French Polynesia",
            code: "PF",
            capital: "Papeetē",
            region: "OC",
            currency: {
                code: "XPF",
                name: "CFP franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/pyf.svg",
        },
        {
            name: "French Southern Territories",
            code: "TF",
            capital: "Port-aux-Français",
            region: "AF",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/atf.svg",
        },
        {
            name: "Gabon",
            code: "GA",
            capital: "Libreville",
            region: "AF",
            currency: {
                code: "XAF",
                name: "Central African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/gab.svg",
        },
        {
            name: "Gambia",
            code: "GM",
            capital: "Banjul",
            region: "AF",
            currency: {
                code: "GMD",
                name: "Gambian dalasi",
                symbol: "D",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/gmb.svg",
        },
        {
            name: "Georgia",
            code: "GE",
            capital: "Tbilisi",
            region: "AS",
            currency: {
                code: "GEL",
                name: "Georgian Lari",
                symbol: "ლ",
            },
            language: {
                code: "ka",
                name: "Georgian",
            },
            flag: "https://restcountries.eu/data/geo.svg",
        },
        {
            name: "Germany",
            code: "DE",
            capital: "Berlin",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "de",
                name: "German",
            },
            flag: "https://restcountries.eu/data/deu.svg",
        },
        {
            name: "Ghana",
            code: "GH",
            capital: "Accra",
            region: "AF",
            currency: {
                code: "GHS",
                name: "Ghanaian cedi",
                symbol: "₵",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/gha.svg",
        },
        {
            name: "Gibraltar",
            code: "GI",
            capital: "Gibraltar",
            region: "EU",
            currency: {
                code: "GIP",
                name: "Gibraltar pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/gib.svg",
        },
        {
            name: "Greece",
            code: "GR",
            capital: "Athens",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "el",
                name: "Greek (modern)",
            },
            flag: "https://restcountries.eu/data/grc.svg",
        },
        {
            name: "Greenland",
            code: "GL",
            capital: "Nuuk",
            region: "NA",
            currency: {
                code: "DKK",
                name: "Danish krone",
                symbol: "kr",
            },
            language: {
                code: "kl",
                name: "Kalaallisut",
            },
            flag: "https://restcountries.eu/data/grl.svg",
        },
        {
            name: "Grenada",
            code: "GD",
            capital: "St. George's",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/grd.svg",
        },
        {
            name: "Guadeloupe",
            code: "GP",
            capital: "Basse-Terre",
            region: "NA",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/glp.svg",
        },
        {
            name: "Guam",
            code: "GU",
            capital: "Hagåtña",
            region: "OC",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/gum.svg",
        },
        {
            name: "Guatemala",
            code: "GT",
            capital: "Guatemala City",
            region: "NA",
            currency: {
                code: "GTQ",
                name: "Guatemalan quetzal",
                symbol: "Q",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/gtm.svg",
        },
        {
            name: "Guernsey",
            code: "GG",
            capital: "St. Peter Port",
            region: "EU",
            currency: {
                code: "GBP",
                name: "British pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/ggy.svg",
        },
        {
            name: "Guinea",
            code: "GN",
            capital: "Conakry",
            region: "AF",
            currency: {
                code: "GNF",
                name: "Guinean franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/gin.svg",
        },
        {
            name: "Guinea-Bissau",
            code: "GW",
            capital: "Bissau",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "pt",
                name: "Portuguese",
            },
            flag: "https://restcountries.eu/data/gnb.svg",
        },
        {
            name: "Guyana",
            code: "GY",
            capital: "Georgetown",
            region: "SA",
            currency: {
                code: "GYD",
                name: "Guyanese dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/guy.svg",
        },
        {
            name: "Haiti",
            code: "HT",
            capital: "Port-au-Prince",
            region: "Americas",
            currency: {
                code: "HTG",
                name: "Haitian gourde",
                symbol: "G",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/hti.svg",
        },
        {
            name: "Heard Island and McDonald Islands",
            code: "HM",
            capital: "",
            region: "",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/hmd.svg",
        },
        {
            name: "Holy See",
            code: "VA",
            capital: "Rome",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/vat.svg",
        },
        {
            name: "Honduras",
            code: "HN",
            capital: "Tegucigalpa",
            region: "NA",
            currency: {
                code: "HNL",
                name: "Honduran lempira",
                symbol: "L",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/hnd.svg",
        },
        {
            name: "Hong Kong",
            code: "HK",
            capital: "City of Victoria",
            region: "AS",
            currency: {
                code: "HKD",
                name: "Hong Kong dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/hkg.svg",
        },
        {
            name: "Hungary",
            code: "HU",
            capital: "Budapest",
            region: "EU",
            currency: {
                code: "HUF",
                name: "Hungarian forint",
                symbol: "Ft",
            },
            language: {
                code: "hu",
                name: "Hungarian",
            },
            flag: "https://restcountries.eu/data/hun.svg",
        },
        {
            name: "Iceland",
            code: "IS",
            capital: "Reykjavík",
            region: "EU",
            currency: {
                code: "ISK",
                name: "Icelandic króna",
                symbol: "kr",
            },
            language: {
                code: "is",
                name: "Icelandic",
            },
            flag: "https://restcountries.eu/data/isl.svg",
        },
        {
            name: "India",
            code: "IN",
            capital: "New Delhi",
            region: "AS",
            currency: {
                code: "INR",
                name: "Indian rupee",
                symbol: "₹",
            },
            language: {
                code: "hi",
                name: "Hindi",
            },
            flag: "https://restcountries.eu/data/ind.svg",
        },
        {
            name: "Indonesia",
            code: "ID",
            capital: "Jakarta",
            region: "AS",
            currency: {
                code: "IDR",
                name: "Indonesian rupiah",
                symbol: "Rp",
            },
            language: {
                code: "id",
                name: "Indonesian",
            },
            flag: "https://restcountries.eu/data/idn.svg",
        },
        {
            name: "Côte d'Ivoire",
            code: "CI",
            capital: "Yamoussoukro",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/civ.svg",
        },
        {
            name: "Iran (Islamic Republic of)",
            code: "IR",
            capital: "Tehran",
            region: "AS",
            currency: {
                code: "IRR",
                name: "Iranian rial",
                symbol: "﷼",
            },
            language: {
                code: "fa",
                name: "Persian (Farsi)",
            },
            flag: "https://restcountries.eu/data/irn.svg",
        },
        {
            name: "Iraq",
            code: "IQ",
            capital: "Baghdad",
            region: "AS",
            currency: {
                code: "IQD",
                name: "Iraqi dinar",
                symbol: "ع.د",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/irq.svg",
        },
        {
            name: "Ireland",
            code: "IE",
            capital: "Dublin",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "ga",
                name: "Irish",
            },
            flag: "https://restcountries.eu/data/irl.svg",
        },
        {
            name: "Isle of Man",
            code: "IM",
            capital: "Douglas",
            region: "EU",
            currency: {
                code: "GBP",
                name: "British pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/imn.svg",
        },
        {
            name: "Israel",
            code: "IL",
            capital: "Jerusalem",
            region: "AS",
            currency: {
                code: "ILS",
                name: "Israeli new shekel",
                symbol: "₪",
            },
            language: {
                code: "he",
                name: "Hebrew (modern)",
            },
            flag: "https://restcountries.eu/data/isr.svg",
        },
        {
            name: "Italy",
            code: "IT",
            capital: "Rome",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "it",
                name: "Italian",
            },
            flag: "https://restcountries.eu/data/ita.svg",
        },
        {
            name: "Jamaica",
            code: "JM",
            capital: "Kingston",
            region: "NA",
            currency: {
                code: "JMD",
                name: "Jamaican dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/jam.svg",
        },
        {
            name: "Japan",
            code: "JP",
            capital: "Tokyo",
            region: "AS",
            currency: {
                code: "JPY",
                name: "Japanese yen",
                symbol: "¥",
            },
            language: {
                code: "ja",
                name: "Japanese",
            },
            flag: "https://restcountries.eu/data/jpn.svg",
        },
        {
            name: "Jersey",
            code: "JE",
            capital: "Saint Helier",
            region: "EU",
            currency: {
                code: "GBP",
                name: "British pound",
                symbol: "£",
            },
            language: {
                code: "en",
                iso639_2: "eng",
                name: "English",
                nativeName: "English",
            },
            flag: "https://restcountries.eu/data/jey.svg",
        },
        {
            name: "Jordan",
            code: "JO",
            capital: "Amman",
            region: "AS",
            currency: {
                code: "JOD",
                name: "Jordanian dinar",
                symbol: "د.ا",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/jor.svg",
        },

        {
            name: "Kenya",
            code: "KE",
            capital: "Nairobi",
            region: "AF",
            currency: {
                code: "KES",
                name: "Kenyan shilling",
                symbol: "Sh",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/ken.svg",
        },
        {
            name: "Kiribati",
            code: "KI",
            capital: "South Tarawa",
            region: "OC",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/kir.svg",
        },
        {
            name: "Kuwait",
            code: "KW",
            capital: "Kuwait City",
            region: "AS",
            currency: {
                code: "KWD",
                name: "Kuwaiti dinar",
                symbol: "د.ك",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/kwt.svg",
        },
        {
            name: "Kyrgyzstan",
            code: "KG",
            capital: "Bishkek",
            region: "AS",
            currency: {
                code: "KGS",
                name: "Kyrgyzstani som",
                symbol: "с",
            },
            language: {
                code: "ky",
                name: "Kyrgyz",
            },
            flag: "https://restcountries.eu/data/kgz.svg",
        },
        {
            name: "Lao People's Democratic Republic",
            code: "LA",
            capital: "Vientiane",
            region: "AS",
            currency: {
                code: "LAK",
                name: "Lao kip",
                symbol: "₭",
            },
            language: {
                code: "lo",
                name: "Lao",
            },
            flag: "https://restcountries.eu/data/lao.svg",
        },
        {
            name: "Latvia",
            code: "LV",
            capital: "Riga",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "lv",
                name: "Latvian",
            },
            flag: "https://restcountries.eu/data/lva.svg",
        },
        {
            name: "Lebanon",
            code: "LB",
            capital: "Beirut",
            region: "AS",
            currency: {
                code: "LBP",
                name: "Lebanese pound",
                symbol: "ل.ل",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/lbn.svg",
        },
        {
            name: "Lesotho",
            code: "LS",
            capital: "Maseru",
            region: "AF",
            currency: {
                code: "LSL",
                name: "Lesotho loti",
                symbol: "L",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/lso.svg",
        },
        {
            name: "Liberia",
            code: "LR",
            capital: "Monrovia",
            region: "AF",
            currency: {
                code: "LRD",
                name: "Liberian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/lbr.svg",
        },
        {
            name: "Libya",
            code: "LY",
            capital: "Tripoli",
            region: "AF",
            currency: {
                code: "LYD",
                name: "Libyan dinar",
                symbol: "ل.د",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/lby.svg",
        },
        {
            name: "Liechtenstein",
            code: "LI",
            capital: "Vaduz",
            region: "EU",
            currency: {
                code: "CHF",
                name: "Swiss franc",
                symbol: "Fr",
            },
            language: {
                code: "de",
                name: "German",
            },
            flag: "https://restcountries.eu/data/lie.svg",
        },
        {
            name: "Lithuania",
            code: "LT",
            capital: "Vilnius",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "lt",
                name: "Lithuanian",
            },
            flag: "https://restcountries.eu/data/ltu.svg",
        },
        {
            name: "Luxembourg",
            code: "LU",
            capital: "Luxembourg",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/lux.svg",
        },
        {
            name: "Macao",
            code: "MO",
            capital: "",
            region: "AS",
            currency: {
                code: "MOP",
                name: "Macanese pataca",
                symbol: "P",
            },
            language: {
                code: "zh",
                name: "Chinese",
            },
            flag: "https://restcountries.eu/data/mac.svg",
        },
        {
            name: "Macedonia (the former Yugoslav Republic of)",
            code: "MK",
            capital: "Skopje",
            region: "EU",
            currency: {
                code: "MKD",
                name: "Macedonian denar",
                symbol: "ден",
            },
            language: {
                code: "mk",
                name: "Macedonian",
            },
            flag: "https://restcountries.eu/data/mkd.svg",
        },
        {
            name: "Madagascar",
            code: "MG",
            capital: "Antananarivo",
            region: "AF",
            currency: {
                code: "MGA",
                name: "Malagasy ariary",
                symbol: "Ar",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/mdg.svg",
        },
        {
            name: "Malawi",
            code: "MW",
            capital: "Lilongwe",
            region: "AF",
            currency: {
                code: "MWK",
                name: "Malawian kwacha",
                symbol: "MK",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/mwi.svg",
        },
        {
            name: "Malaysia",
            code: "MY",
            capital: "Kuala Lumpur",
            region: "AS",
            currency: {
                code: "MYR",
                name: "Malaysian ringgit",
                symbol: "RM",
            },
            language: {
                code: null,
                name: "Malaysian",
            },
            flag: "https://restcountries.eu/data/mys.svg",
        },
        {
            name: "Maldives",
            code: "MV",
            capital: "Malé",
            region: "AS",
            currency: {
                code: "MVR",
                name: "Maldivian rufiyaa",
                symbol: ".ރ",
            },
            language: {
                code: "dv",
                name: "Divehi",
            },
            flag: "https://restcountries.eu/data/mdv.svg",
        },
        {
            name: "Mali",
            code: "ML",
            capital: "Bamako",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/mli.svg",
        },
        {
            name: "Malta",
            code: "MT",
            capital: "Valletta",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "mt",
                name: "Maltese",
            },
            flag: "https://restcountries.eu/data/mlt.svg",
        },
        {
            name: "Marshall Islands",
            code: "MH",
            capital: "Majuro",
            region: "OC",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/mhl.svg",
        },
        {
            name: "Martinique",
            code: "MQ",
            capital: "Fort-de-France",
            region: "Americas",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/mtq.svg",
        },
        {
            name: "Mauritania",
            code: "MR",
            capital: "Nouakchott",
            region: "AF",
            currency: {
                code: "MRO",
                name: "Mauritanian ouguiya",
                symbol: "UM",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/mrt.svg",
        },
        {
            name: "Mauritius",
            code: "MU",
            capital: "Port Louis",
            region: "AF",
            currency: {
                code: "MUR",
                name: "Mauritian rupee",
                symbol: "₨",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/mus.svg",
        },
        {
            name: "Mayotte",
            code: "YT",
            capital: "Mamoudzou",
            region: "AF",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/myt.svg",
        },
        {
            name: "Mexico",
            code: "MX",
            capital: "Mexico City",
            region: "NA",
            currency: {
                code: "MXN",
                name: "Mexican peso",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/mex.svg",
        },
        {
            name: "Micronesia (Federated States of)",
            code: "FM",
            capital: "Palikir",
            region: "OC",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/fsm.svg",
        },
        {
            name: "Moldova (Republic of)",
            code: "MD",
            capital: "Chișinău",
            region: "EU",
            currency: {
                code: "MDL",
                name: "Moldovan leu",
                symbol: "L",
            },
            language: {
                code: "ro",
                name: "Romanian",
            },
            flag: "https://restcountries.eu/data/mda.svg",
        },
        {
            name: "Monaco",
            code: "MC",
            capital: "Monaco",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/mco.svg",
        },
        {
            name: "Mongolia",
            code: "MN",
            capital: "Ulan Bator",
            region: "AS",
            currency: {
                code: "MNT",
                name: "Mongolian tögrög",
                symbol: "₮",
            },
            language: {
                code: "mn",
                name: "Mongolian",
            },
            flag: "https://restcountries.eu/data/mng.svg",
        },
        {
            name: "Montenegro",
            code: "ME",
            capital: "Podgorica",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "sr",
                name: "Serbian",
            },
            flag: "https://restcountries.eu/data/mne.svg",
        },
        {
            name: "Montserrat",
            code: "MS",
            capital: "Plymouth",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/msr.svg",
        },
        {
            name: "Morocco",
            code: "MA",
            capital: "Rabat",
            region: "AF",
            currency: {
                code: "MAD",
                name: "Moroccan dirham",
                symbol: "د.م.",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/mar.svg",
        },
        {
            name: "Mozambique",
            code: "MZ",
            capital: "Maputo",
            region: "AF",
            currency: {
                code: "MZN",
                name: "Mozambican metical",
                symbol: "MT",
            },
            language: {
                code: "pt",
                name: "Portuguese",
            },
            flag: "https://restcountries.eu/data/moz.svg",
        },
        {
            name: "Myanmar",
            code: "MM",
            capital: "Naypyidaw",
            region: "AS",
            currency: {
                code: "MMK",
                name: "Burmese kyat",
                symbol: "Ks",
            },
            language: {
                code: "my",
                name: "Burmese",
            },
            flag: "https://restcountries.eu/data/mmr.svg",
        },
        {
            name: "Namibia",
            code: "NA",
            capital: "Windhoek",
            region: "AF",
            currency: {
                code: "NAD",
                name: "Namibian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/nam.svg",
        },
        {
            name: "Nauru",
            code: "NR",
            capital: "Yaren",
            region: "OC",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/nru.svg",
        },
        {
            name: "Nepal",
            code: "NP",
            capital: "Kathmandu",
            region: "AS",
            currency: {
                code: "NPR",
                name: "Nepalese rupee",
                symbol: "₨",
            },
            language: {
                code: "ne",
                name: "Nepali",
            },
            flag: "https://restcountries.eu/data/npl.svg",
        },
        {
            name: "Netherlands",
            code: "NL",
            capital: "Amsterdam",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "nl",
                name: "Dutch",
            },
            flag: "https://restcountries.eu/data/nld.svg",
        },
        {
            name: "New Caledonia",
            code: "NC",
            capital: "Nouméa",
            region: "OC",
            currency: {
                code: "XPF",
                name: "CFP franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/ncl.svg",
        },
        {
            name: "New Zealand",
            code: "NZ",
            capital: "Wellington",
            region: "OC",
            currency: {
                code: "NZD",
                name: "New Zealand dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/nzl.svg",
        },
        {
            name: "Nicaragua",
            code: "NI",
            capital: "Managua",
            region: "NA",
            currency: {
                code: "NIO",
                name: "Nicaraguan córdoba",
                symbol: "C$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/nic.svg",
        },
        {
            name: "Niger",
            code: "NE",
            capital: "Niamey",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/ner.svg",
        },
        {
            name: "Nigeria",
            code: "NG",
            capital: "Abuja",
            region: "AF",
            currency: {
                code: "NGN",
                name: "Nigerian naira",
                symbol: "₦",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/nga.svg",
        },
        {
            name: "Niue",
            code: "NU",
            capital: "Alofi",
            region: "OC",
            currency: {
                code: "NZD",
                name: "New Zealand dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/niu.svg",
        },
        {
            name: "Norfolk Island",
            code: "NF",
            capital: "Kingston",
            region: "OC",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/nfk.svg",
        },
        {
            name: "Korea (Democratic People's Republic of)",
            code: "KP",
            capital: "Pyongyang",
            region: "AS",
            currency: {
                code: "KPW",
                name: "North Korean won",
                symbol: "₩",
            },
            language: {
                code: "ko",
                name: "Korean",
            },
            flag: "https://restcountries.eu/data/prk.svg",
        },
        {
            name: "Northern Mariana Islands",
            code: "MP",
            capital: "Saipan",
            region: "OC",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/mnp.svg",
        },
        {
            name: "Norway",
            code: "NO",
            capital: "Oslo",
            region: "EU",
            currency: {
                code: "NOK",
                name: "Norwegian krone",
                symbol: "kr",
            },
            language: {
                code: "no",
                name: "Norwegian",
            },
            flag: "https://restcountries.eu/data/nor.svg",
        },
        {
            name: "Oman",
            code: "OM",
            capital: "Muscat",
            region: "AS",
            currency: {
                code: "OMR",
                name: "Omani rial",
                symbol: "ر.ع.",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/omn.svg",
        },
        {
            name: "Pakistan",
            code: "PK",
            capital: "Islamabad",
            region: "AS",
            currency: {
                code: "PKR",
                name: "Pakistani rupee",
                symbol: "₨",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/pak.svg",
        },
        {
            name: "Palau",
            code: "PW",
            capital: "Ngerulmud",
            region: "OC",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/plw.svg",
        },
        {
            name: "Palestine, State of",
            code: "PS",
            capital: "Ramallah",
            region: "AS",
            currency: {
                code: "ILS",
                name: "Israeli new sheqel",
                symbol: "₪",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/pse.svg",
        },
        {
            name: "Panama",
            code: "PA",
            capital: "Panama City",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/pan.svg",
        },
        {
            name: "Papua New Guinea",
            code: "PG",
            capital: "Port Moresby",
            region: "OC",
            currency: {
                code: "PGK",
                name: "Papua New Guinean kina",
                symbol: "K",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/png.svg",
        },
        {
            name: "Paraguay",
            code: "PY",
            capital: "Asunción",
            region: "SA",
            currency: {
                code: "PYG",
                name: "Paraguayan guaraní",
                symbol: "₲",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/pry.svg",
        },
        {
            name: "Peru",
            code: "PE",
            capital: "Lima",
            region: "SA",
            currency: {
                code: "PEN",
                name: "Peruvian sol",
                symbol: "S/.",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/per.svg",
        },
        {
            name: "Philippines",
            code: "PH",
            capital: "Manila",
            region: "AS",
            currency: {
                code: "PHP",
                name: "Philippine peso",
                symbol: "₱",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/phl.svg",
        },
        {
            name: "Pitcairn",
            code: "PN",
            capital: "Adamstown",
            region: "OC",
            currency: {
                code: "NZD",
                name: "New Zealand dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/pcn.svg",
        },
        {
            name: "Poland",
            code: "PL",
            capital: "Warsaw",
            region: "EU",
            currency: {
                code: "PLN",
                name: "Polish złoty",
                symbol: "zł",
            },
            language: {
                code: "pl",
                name: "Polish",
            },
            flag: "https://restcountries.eu/data/pol.svg",
        },
        {
            name: "Portugal",
            code: "PT",
            capital: "Lisbon",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "pt",
                name: "Portuguese",
            },
            flag: "https://restcountries.eu/data/prt.svg",
        },
        {
            name: "Puerto Rico",
            code: "PR",
            capital: "San Juan",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/pri.svg",
        },
        {
            name: "Qatar",
            code: "QA",
            capital: "Doha",
            region: "AS",
            currency: {
                code: "QAR",
                name: "Qatari riyal",
                symbol: "ر.ق",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/qat.svg",
        },
        {
            name: "Republic of Kosovo",
            code: "XK",
            capital: "Pristina",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "sq",
                name: "Albanian",
            },
            flag: "https://restcountries.eu/data/kos.svg",
        },
        {
            name: "Réunion",
            code: "RE",
            capital: "Saint-Denis",
            region: "AF",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/reu.svg",
        },
        {
            name: "Romania",
            code: "RO",
            capital: "Bucharest",
            region: "EU",
            currency: {
                code: "RON",
                name: "Romanian leu",
                symbol: "lei",
            },
            language: {
                code: "ro",
                name: "Romanian",
            },
            flag: "https://restcountries.eu/data/rou.svg",
        },
        {
            name: "Russian Federation",
            code: "RU",
            capital: "Moscow",
            region: "EU",
            currency: {
                code: "RUB",
                name: "Russian ruble",
                symbol: "₽",
            },
            language: {
                code: "ru",
                name: "Russian",
            },
            flag: "https://restcountries.eu/data/rus.svg",
        },
        {
            name: "Rwanda",
            code: "RW",
            capital: "Kigali",
            region: "AF",
            currency: {
                code: "RWF",
                name: "Rwandan franc",
                symbol: "Fr",
            },
            language: {
                code: "rw",
                name: "Kinyarwanda",
            },
            flag: "https://restcountries.eu/data/rwa.svg",
        },
        {
            name: "Saint Barthélemy",
            code: "BL",
            capital: "Gustavia",
            region: "NA",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/blm.svg",
        },
        {
            name: "Saint Helena, Ascension and Tristan da Cunha",
            code: "SH",
            capital: "Jamestown",
            region: "AF",
            currency: {
                code: "SHP",
                name: "Saint Helena pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/shn.svg",
        },
        {
            name: "Saint Kitts and Nevis",
            code: "KN",
            capital: "Basseterre",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/kna.svg",
        },
        {
            name: "Saint Lucia",
            code: "LC",
            capital: "Castries",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/lca.svg",
        },
        {
            name: "Saint Martin (French part)",
            code: "MF",
            capital: "Marigot",
            region: "NA",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/maf.svg",
        },
        {
            name: "Saint Pierre and Miquelon",
            code: "PM",
            capital: "Saint-Pierre",
            region: "NA",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/spm.svg",
        },
        {
            name: "Saint Vincent and the Grenadines",
            code: "VC",
            capital: "Kingstown",
            region: "NA",
            currency: {
                code: "XCD",
                name: "East Caribbean dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/vct.svg",
        },
        {
            name: "Samoa",
            code: "WS",
            capital: "Apia",
            region: "OC",
            currency: {
                code: "WST",
                name: "Samoan tālā",
                symbol: "T",
            },
            language: {
                code: "sm",
                name: "Samoan",
            },
            flag: "https://restcountries.eu/data/wsm.svg",
        },
        {
            name: "San Marino",
            code: "SM",
            capital: "City of San Marino",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "it",
                name: "Italian",
            },
            flag: "https://restcountries.eu/data/smr.svg",
        },
        {
            name: "Sao Tome and Principe",
            code: "ST",
            capital: "São Tomé",
            region: "AF",
            currency: {
                code: "STD",
                name: "São Tomé and Príncipe dobra",
                symbol: "Db",
            },
            language: {
                code: "pt",
                name: "Portuguese",
            },
            flag: "https://restcountries.eu/data/stp.svg",
        },
        {
            name: "Saudi Arabia",
            code: "SA",
            capital: "Riyadh",
            region: "AS",
            currency: {
                code: "SAR",
                name: "Saudi riyal",
                symbol: "ر.س",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/sau.svg",
        },
        {
            name: "Senegal",
            code: "SN",
            capital: "Dakar",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/sen.svg",
        },
        {
            name: "Serbia",
            code: "RS",
            capital: "Belgrade",
            region: "EU",
            currency: {
                code: "RSD",
                name: "Serbian dinar",
                symbol: "дин.",
            },
            language: {
                code: "sr",
                name: "Serbian",
            },
            flag: "https://restcountries.eu/data/srb.svg",
        },
        {
            name: "Seychelles",
            code: "SC",
            capital: "Victoria",
            region: "AF",
            currency: {
                code: "SCR",
                name: "Seychellois rupee",
                symbol: "₨",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/syc.svg",
        },
        {
            name: "Sierra Leone",
            code: "SL",
            capital: "Freetown",
            region: "AF",
            currency: {
                code: "SLL",
                name: "Sierra Leonean leone",
                symbol: "Le",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/sle.svg",
        },
        {
            name: "Singapore",
            code: "SG",
            capital: "Singapore",
            region: "AS",
            currency: {
                code: "SGD",
                name: "Singapore dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/sgp.svg",
        },
        {
            name: "Sint Maarten (Dutch part)",
            code: "SX",
            capital: "Philipsburg",
            region: "Americas",
            currency: {
                code: "ANG",
                name: "Netherlands Antillean guilder",
                symbol: "ƒ",
            },
            language: {
                code: "nl",
                name: "Dutch",
            },
            flag: "https://restcountries.eu/data/sxm.svg",
        },
        {
            name: "Slovakia",
            code: "SK",
            capital: "Bratislava",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "sk",
                name: "Slovak",
            },
            flag: "https://restcountries.eu/data/svk.svg",
        },
        {
            name: "Slovenia",
            code: "SI",
            capital: "Ljubljana",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "sl",
                name: "Slovene",
            },
            flag: "https://restcountries.eu/data/svn.svg",
        },
        {
            name: "Solomon Islands",
            code: "SB",
            capital: "Honiara",
            region: "OC",
            currency: {
                code: "SBD",
                name: "Solomon Islands dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/slb.svg",
        },
        {
            name: "Somalia",
            code: "SO",
            capital: "Mogadishu",
            region: "AF",
            currency: {
                code: "SOS",
                name: "Somali shilling",
                symbol: "Sh",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/som.svg",
        },
        {
            name: "South Africa",
            code: "ZA",
            capital: "Pretoria",
            region: "AF",
            currency: {
                code: "ZAR",
                name: "South African rand",
                symbol: "R",
            },
            language: {
                code: "en",
                iso639_2: "eng",
                name: "English",
                nativeName: "English",
            },
            flag: "https://restcountries.eu/data/zaf.svg",
        },
        {
            name: "South Georgia and the South Sandwich Islands",
            code: "GS",
            capital: "King Edward Point",
            region: "NA",
            currency: {
                code: "GBP",
                name: "British pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/sgs.svg",
        },
        {
            name: "Korea (Republic of)",
            code: "KR",
            capital: "Seoul",
            region: "AS",
            currency: {
                code: "KRW",
                name: "South Korean won",
                symbol: "₩",
            },
            language: {
                code: "ko",
                name: "Korean",
            },
            flag: "https://restcountries.eu/data/kor.svg",
        },
        {
            name: "South Sudan",
            code: "SS",
            capital: "Juba",
            region: "AF",
            currency: {
                code: "SSP",
                name: "South Sudanese pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/ssd.svg",
        },
        {
            name: "Spain",
            code: "ES",
            capital: "Madrid",
            region: "EU",
            currency: {
                code: "EUR",
                name: "Euro",
                symbol: "€",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/esp.svg",
        },
        {
            name: "Sri Lanka",
            code: "LK",
            capital: "Colombo",
            region: "AS",
            currency: {
                code: "LKR",
                name: "Sri Lankan rupee",
                symbol: "Rs",
            },
            language: {
                code: "si",
                iso639_2: "sin",
                name: "Sinhalese",
                nativeName: "සිංහල",
            },
            flag: "https://restcountries.eu/data/lka.svg",
        },
        {
            name: "Sudan",
            code: "SD",
            capital: "Khartoum",
            region: "AF",
            currency: {
                code: "SDG",
                name: "Sudanese pound",
                symbol: "ج.س.",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/sdn.svg",
        },
        {
            name: "Suriname",
            code: "SR",
            capital: "Paramaribo",
            region: "SA",
            currency: {
                code: "SRD",
                name: "Surinamese dollar",
                symbol: "$",
            },
            language: {
                code: "nl",
                name: "Dutch",
            },
            flag: "https://restcountries.eu/data/sur.svg",
        },
        {
            name: "Svalbard and Jan Mayen",
            code: "SJ",
            capital: "Longyearbyen",
            region: "EU",
            currency: {
                code: "NOK",
                name: "Norwegian krone",
                symbol: "kr",
            },
            language: {
                code: "no",
                name: "Norwegian",
            },
            flag: "https://restcountries.eu/data/sjm.svg",
        },
        {
            name: "Swaziland",
            code: "SZ",
            capital: "Lobamba",
            region: "AF",
            currency: {
                code: "SZL",
                name: "Swazi lilangeni",
                symbol: "L",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/swz.svg",
        },
        {
            name: "Sweden",
            code: "SE",
            capital: "Stockholm",
            region: "EU",
            currency: {
                code: "SEK",
                name: "Swedish krona",
                symbol: "kr",
            },
            language: {
                code: "sv",
                name: "Swedish",
            },
            flag: "https://restcountries.eu/data/swe.svg",
        },
        {
            name: "Switzerland",
            code: "CH",
            capital: "Bern",
            region: "EU",
            currency: {
                code: "CHF",
                name: "Swiss franc",
                symbol: "Fr",
            },
            language: {
                code: "de",
                name: "German",
            },
            flag: "https://restcountries.eu/data/che.svg",
        },
        {
            name: "Syrian Arab Republic",
            code: "SY",
            capital: "Damascus",
            region: "AS",
            currency: {
                code: "SYP",
                name: "Syrian pound",
                symbol: "£",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/syr.svg",
        },
        {
            name: "Taiwan",
            code: "TW",
            capital: "Taipei",
            region: "AS",
            currency: {
                code: "TWD",
                name: "New Taiwan dollar",
                symbol: "$",
            },
            language: {
                code: "zh",
                name: "Chinese",
            },
            flag: "https://restcountries.eu/data/twn.svg",
        },
        {
            name: "Tajikistan",
            code: "TJ",
            capital: "Dushanbe",
            region: "AS",
            currency: {
                code: "TJS",
                name: "Tajikistani somoni",
                symbol: "ЅМ",
            },
            language: {
                code: "tg",
                name: "Tajik",
            },
            flag: "https://restcountries.eu/data/tjk.svg",
        },
        {
            name: "Tanzania, United Republic of",
            code: "TZ",
            capital: "Dodoma",
            region: "AF",
            currency: {
                code: "TZS",
                name: "Tanzanian shilling",
                symbol: "Sh",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/tza.svg",
        },
        {
            name: "Thailand",
            code: "TH",
            capital: "Bangkok",
            region: "AS",
            currency: {
                code: "THB",
                name: "Thai baht",
                symbol: "฿",
            },
            language: {
                code: "th",
                name: "Thai",
            },
            flag: "https://restcountries.eu/data/tha.svg",
        },
        {
            name: "Timor-Leste",
            code: "TL",
            capital: "Dili",
            region: "AS",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "pt",
                name: "Portuguese",
            },
            flag: "https://restcountries.eu/data/tls.svg",
        },
        {
            name: "Togo",
            code: "TG",
            capital: "Lomé",
            region: "AF",
            currency: {
                code: "XOF",
                name: "West African CFA franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/tgo.svg",
        },
        {
            name: "Tokelau",
            code: "TK",
            capital: "Fakaofo",
            region: "OC",
            currency: {
                code: "NZD",
                name: "New Zealand dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/tkl.svg",
        },
        {
            name: "Tonga",
            code: "TO",
            capital: "Nuku'alofa",
            region: "OC",
            currency: {
                code: "TOP",
                name: "Tongan paʻanga",
                symbol: "T$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/ton.svg",
        },
        {
            name: "Trinidad and Tobago",
            code: "TT",
            capital: "Port of Spain",
            region: "SA",
            currency: {
                code: "TTD",
                name: "Trinidad and Tobago dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/tto.svg",
        },
        {
            name: "Tunisia",
            code: "TN",
            capital: "Tunis",
            region: "AF",
            currency: {
                code: "TND",
                name: "Tunisian dinar",
                symbol: "د.ت",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/tun.svg",
        },

        {
            name: "Turkmenistan",
            code: "TM",
            capital: "Ashgabat",
            region: "AS",
            currency: {
                code: "TMT",
                name: "Turkmenistan manat",
                symbol: "m",
            },
            language: {
                code: "tk",
                name: "Turkmen",
            },
            flag: "https://restcountries.eu/data/tkm.svg",
        },
        {
            name: "Turks and Caicos Islands",
            code: "TC",
            capital: "Cockburn Town",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/tca.svg",
        },
        {
            name: "Tuvalu",
            code: "TV",
            capital: "Funafuti",
            region: "OC",
            currency: {
                code: "AUD",
                name: "Australian dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/tuv.svg",
        },
        {
            name: "Uganda",
            code: "UG",
            capital: "Kampala",
            region: "AF",
            currency: {
                code: "UGX",
                name: "Ugandan shilling",
                symbol: "Sh",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/uga.svg",
        },
        {
            name: "Ukraine",
            code: "UA",
            capital: "Kiev",
            region: "EU",
            currency: {
                code: "UAH",
                name: "Ukrainian hryvnia",
                symbol: "₴",
            },
            language: {
                code: "uk",
                name: "Ukrainian",
            },
            flag: "https://restcountries.eu/data/ukr.svg",
        },
        {
            name: "United Arab Emirates",
            code: "AE",
            capital: "Abu Dhabi",
            region: "AS",
            currency: {
                code: "AED",
                name: "United Arab Emirates dirham",
                symbol: "د.إ",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/are.svg",
        },
        {
            name: "United Kingdom of Great Britain and Northern Ireland",
            code: "GB",
            capital: "London",
            region: "EU",
            currency: {
                code: "GBP",
                name: "British pound",
                symbol: "£",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/gbr.svg",
        },
        {
            name: "United States of America",
            code: "US",
            capital: "Washington, D.C.",
            region: "NA",
            currency: {
                code: "USD",
                name: "United States dollar",
                symbol: "$",
            },
            language: {
                code: "en",
                iso639_2: "eng",
                name: "English",
                nativeName: "English",
            },
            flag: "https://restcountries.eu/data/usa.svg",
        },
        {
            name: "Uruguay",
            code: "UY",
            capital: "Montevideo",
            region: "SA",
            currency: {
                code: "UYU",
                name: "Uruguayan peso",
                symbol: "$",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/ury.svg",
        },

        {
            name: "Vanuatu",
            code: "VU",
            capital: "Port Vila",
            region: "OC",
            currency: {
                code: "VUV",
                name: "Vanuatu vatu",
                symbol: "Vt",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/vut.svg",
        },
        {
            name: "Venezuela (Bolivarian Republic of)",
            code: "VE",
            capital: "Caracas",
            region: "SA",
            currency: {
                code: "VEF",
                name: "Venezuelan bolívar",
                symbol: "Bs F",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/ven.svg",
        },
        {
            name: "Viet Nam",
            code: "VN",
            capital: "Hanoi",
            region: "AS",
            currency: {
                code: "VND",
                name: "Vietnamese đồng",
                symbol: "₫",
            },
            language: {
                code: "vi",
                name: "Vietnamese",
            },
            flag: "https://restcountries.eu/data/vnm.svg",
        },
        {
            name: "Wallis and Futuna",
            code: "WF",
            capital: "Mata-Utu",
            region: "OC",
            currency: {
                code: "XPF",
                name: "CFP franc",
                symbol: "Fr",
            },
            language: {
                code: "fr",
                name: "French",
            },
            flag: "https://restcountries.eu/data/wlf.svg",
        },
        {
            name: "Western Sahara",
            code: "EH",
            capital: "El Aaiún",
            region: "AF",
            currency: {
                code: "MAD",
                name: "Moroccan dirham",
                symbol: "د.م.",
            },
            language: {
                code: "es",
                name: "Spanish",
            },
            flag: "https://restcountries.eu/data/esh.svg",
        },
        {
            name: "Yemen",
            code: "YE",
            capital: "Sana'a",
            region: "AS",
            currency: {
                code: "YER",
                name: "Yemeni rial",
                symbol: "﷼",
            },
            language: {
                code: "ar",
                name: "Arabic",
            },
            flag: "https://restcountries.eu/data/yem.svg",
        },
        {
            name: "Zambia",
            code: "ZM",
            capital: "Lusaka",
            region: "AF",
            currency: {
                code: "ZMW",
                name: "Zambian kwacha",
                symbol: "ZK",
            },
            language: {
                code: "en",
                name: "English",
            },
            flag: "https://restcountries.eu/data/zmb.svg",
        },
        {
            name: "Zimbabwe",
            code: "ZW",
            capital: "Harare",
            region: "AF",
            currency: {
                code: "BWP",
                name: "Botswana pula",
                symbol: "P",
            },
            language: {
                code: "en",
                iso639_2: "eng",
                name: "English",
                nativeName: "English",
            },
            flag: "https://restcountries.eu/data/zwe.svg",
        },
    ];
    let phoneCode = [
        {
            name: "South Georgia",
            code: "GS",
            phoneCode: "+500",
        },
        {
            name: "Grenada",
            code: "GD",
            phoneCode: "+1473",
        },
        {
            name: "Switzerland",
            code: "CH",
            phoneCode: "+41",
        },
        {
            name: "Sierra Leone",
            code: "SL",
            phoneCode: "+232",
        },
        {
            name: "Hungary",
            code: "HU",
            phoneCode: "+36",
        },
        {
            name: "Taiwan",
            code: "TW",
            phoneCode: "+886",
        },
        {
            name: "Wallis and Futuna",
            code: "WF",
            phoneCode: "+681",
        },
        {
            name: "Barbados",
            code: "BB",
            phoneCode: "+1246",
        },
        {
            name: "Pitcairn Islands",
            code: "PN",
            phoneCode: "+64",
        },
        {
            name: "Ivory Coast",
            code: "CI",
            phoneCode: "+225",
        },
        {
            name: "Tunisia",
            code: "TN",
            phoneCode: "+216",
        },
        {
            name: "Italy",
            code: "IT",
            phoneCode: "+39",
        },
        {
            name: "Benin",
            code: "BJ",
            phoneCode: "+229",
        },
        {
            name: "Indonesia",
            code: "ID",
            phoneCode: "+62",
        },
        {
            name: "Cape Verde",
            code: "CV",
            phoneCode: "+238",
        },
        {
            name: "Saint Kitts and Nevis",
            code: "KN",
            phoneCode: "+1869",
        },
        {
            name: "Laos",
            code: "LA",
            phoneCode: "+856",
        },
        {
            name: "Caribbean Netherlands",
            code: "BQ",
            phoneCode: "+599",
        },
        {
            name: "Uganda",
            code: "UG",
            phoneCode: "+256",
        },
        {
            name: "Andorra",
            code: "AD",
            phoneCode: "+376",
        },
        {
            name: "Burundi",
            code: "BI",
            phoneCode: "+257",
        },
        {
            name: "South Africa",
            code: "ZA",
            phoneCode: "+27",
        },
        {
            name: "France",
            code: "FR",
            phoneCode: "+33",
        },
        {
            name: "Libya",
            code: "LY",
            phoneCode: "+218",
        },
        {
            name: "Mexico",
            code: "MX",
            phoneCode: "+52",
        },
        {
            name: "Gabon",
            code: "GA",
            phoneCode: "+241",
        },
        {
            name: "Northern Mariana Islands",
            code: "MP",
            phoneCode: "+1670",
        },
        {
            name: "North Macedonia",
            code: "MK",
            phoneCode: "+389",
        },
        {
            name: "China",
            code: "CN",
            phoneCode: "+86",
        },
        {
            name: "Yemen",
            code: "YE",
            phoneCode: "+967",
        },
        {
            name: "Saint Barthélemy",
            code: "BL",
            phoneCode: "+590",
        },
        {
            name: "Guernsey",
            code: "GG",
            phoneCode: "+44",
        },
        {
            name: "Solomon Islands",
            code: "SB",
            phoneCode: "+677",
        },
        {
            name: "Svalbard and Jan Mayen",
            code: "SJ",
            phoneCode: "+4779",
        },
        {
            name: "Faroe Islands",
            code: "FO",
            phoneCode: "+298",
        },
        {
            name: "Uzbekistan",
            code: "UZ",
            phoneCode: "+998",
        },
        {
            name: "Egypt",
            code: "EG",
            phoneCode: "+20",
        },
        {
            name: "Senegal",
            code: "SN",
            phoneCode: "+221",
        },
        {
            name: "Sri Lanka",
            code: "LK",
            phoneCode: "+94",
        },
        {
            name: "Palestine",
            code: "PS",
            phoneCode: "+970",
        },
        {
            name: "Bangladesh",
            code: "BD",
            phoneCode: "+880",
        },
        {
            name: "Peru",
            code: "PE",
            phoneCode: "+51",
        },
        {
            name: "Singapore",
            code: "SG",
            phoneCode: "+65",
        },
        {
            name: "Turkey",
            code: "TR",
            phoneCode: "+90",
        },
        {
            name: "Afghanistan",
            code: "AF",
            phoneCode: "+93",
        },
        {
            name: "Aruba",
            code: "AW",
            phoneCode: "+297",
        },
        {
            name: "Cook Islands",
            code: "CK",
            phoneCode: "+682",
        },
        {
            name: "United Kingdom",
            code: "GB",
            phoneCode: "+44",
        },
        {
            name: "Zambia",
            code: "ZM",
            phoneCode: "+260",
        },
        {
            name: "Finland",
            code: "FI",
            phoneCode: "+358",
        },
        {
            name: "Niger",
            code: "NE",
            phoneCode: "+227",
        },
        {
            name: "Christmas Island",
            code: "CX",
            phoneCode: "+61",
        },
        {
            name: "Tokelau",
            code: "TK",
            phoneCode: "+690",
        },
        {
            name: "Guinea-Bissau",
            code: "GW",
            phoneCode: "+245",
        },
        {
            name: "Azerbaijan",
            code: "AZ",
            phoneCode: "+994",
        },
        {
            name: "Réunion",
            code: "RE",
            phoneCode: "+262",
        },
        {
            name: "Djibouti",
            code: "DJ",
            phoneCode: "+253",
        },
        {
            name: "North Korea",
            code: "KP",
            phoneCode: "+850",
        },
        {
            name: "Mauritius",
            code: "MU",
            phoneCode: "+230",
        },
        {
            name: "Montserrat",
            code: "MS",
            phoneCode: "+1664",
        },
        {
            name: "United States Virgin Islands",
            code: "VI",
            phoneCode: "+1340",
        },
        {
            name: "Colombia",
            code: "CO",
            phoneCode: "+57",
        },
        {
            name: "Greece",
            code: "GR",
            phoneCode: "+30",
        },
        {
            name: "Croatia",
            code: "HR",
            phoneCode: "+385",
        },
        {
            name: "Morocco",
            code: "MA",
            phoneCode: "+212",
        },
        {
            name: "Algeria",
            code: "DZ",
            phoneCode: "+213",
        },
        {
            name: "Antarctica",
            code: "AQ",
            phoneCode: "",
        },
        {
            name: "Netherlands",
            code: "NL",
            phoneCode: "+31",
        },
        {
            name: "Sudan",
            code: "SD",
            phoneCode: "+249",
        },
        {
            name: "Fiji",
            code: "FJ",
            phoneCode: "+679",
        },
        {
            name: "Liechtenstein",
            code: "LI",
            phoneCode: "+423",
        },
        {
            name: "Nepal",
            code: "NP",
            phoneCode: "+977",
        },
        {
            name: "Puerto Rico",
            code: "PR",
            phoneCode: "+1787",
        },
        {
            name: "Georgia",
            code: "GE",
            phoneCode: "+995",
        },
        {
            name: "Pakistan",
            code: "PK",
            phoneCode: "+92",
        },
        {
            name: "Monaco",
            code: "MC",
            phoneCode: "+377",
        },
        {
            name: "Botswana",
            code: "BW",
            phoneCode: "+267",
        },
        {
            name: "Lebanon",
            code: "LB",
            phoneCode: "+961",
        },
        {
            name: "Papua New Guinea",
            code: "PG",
            phoneCode: "+675",
        },
        {
            name: "Mayotte",
            code: "YT",
            phoneCode: "+262",
        },
        {
            name: "Dominican Republic",
            code: "DO",
            phoneCode: "+1809",
        },
        {
            name: "Norfolk Island",
            code: "NF",
            phoneCode: "+672",
        },
        {
            name: "Bouvet Island",
            code: "BV",
            phoneCode: "+47",
        },
        {
            name: "Qatar",
            code: "QA",
            phoneCode: "+974",
        },
        {
            name: "Madagascar",
            code: "MG",
            phoneCode: "+261",
        },
        {
            name: "India",
            code: "IN",
            phoneCode: "+91",
        },
        {
            name: "Syria",
            code: "SY",
            phoneCode: "+963",
        },
        {
            name: "Montenegro",
            code: "ME",
            phoneCode: "+382",
        },
        {
            name: "Eswatini",
            code: "SZ",
            phoneCode: "+268",
        },
        {
            name: "Paraguay",
            code: "PY",
            phoneCode: "+595",
        },
        {
            name: "El Salvador",
            code: "SV",
            phoneCode: "+503",
        },
        {
            name: "Ukraine",
            code: "UA",
            phoneCode: "+380",
        },
        {
            name: "Isle of Man",
            code: "IM",
            phoneCode: "+44",
        },
        {
            name: "Namibia",
            code: "NA",
            phoneCode: "+264",
        },
        {
            name: "United Arab Emirates",
            code: "AE",
            phoneCode: "+971",
        },
        {
            name: "Bulgaria",
            code: "BG",
            phoneCode: "+359",
        },
        {
            name: "Greenland",
            code: "GL",
            phoneCode: "+299",
        },
        {
            name: "Germany",
            code: "DE",
            phoneCode: "+49",
        },
        {
            name: "Cambodia",
            code: "KH",
            phoneCode: "+855",
        },
        {
            name: "Iraq",
            code: "IQ",
            phoneCode: "+964",
        },
        {
            name: "French Southern and Antarctic Lands",
            code: "TF",
            phoneCode: "+262",
        },
        {
            name: "Sweden",
            code: "SE",
            phoneCode: "+46",
        },
        {
            name: "Cuba",
            code: "CU",
            phoneCode: "+53",
        },
        {
            name: "Kyrgyzstan",
            code: "KG",
            phoneCode: "+996",
        },
        {
            name: "Russia",
            code: "RU",
            phoneCode: "+73",
        },
        {
            name: "Malaysia",
            code: "MY",
            phoneCode: "+60",
        },
        {
            name: "São Tomé and Príncipe",
            code: "ST",
            phoneCode: "+239",
        },
        {
            name: "Cyprus",
            code: "CY",
            phoneCode: "+357",
        },
        {
            name: "Canada",
            code: "CA",
            phoneCode: "+1",
        },
        {
            name: "Malawi",
            code: "MW",
            phoneCode: "+265",
        },
        {
            name: "Saudi Arabia",
            code: "SA",
            phoneCode: "+966",
        },
        {
            name: "Bosnia and Herzegovina",
            code: "BA",
            phoneCode: "+387",
        },
        {
            name: "Ethiopia",
            code: "ET",
            phoneCode: "+251",
        },
        {
            name: "Spain",
            code: "ES",
            phoneCode: "+34",
        },
        {
            name: "Slovenia",
            code: "SI",
            phoneCode: "+386",
        },
        {
            name: "Oman",
            code: "OM",
            phoneCode: "+968",
        },
        {
            name: "Saint Pierre and Miquelon",
            code: "PM",
            phoneCode: "+508",
        },
        {
            name: "Macau",
            code: "MO",
            phoneCode: "+853",
        },
        {
            name: "San Marino",
            code: "SM",
            phoneCode: "+378",
        },
        {
            name: "Lesotho",
            code: "LS",
            phoneCode: "+266",
        },
        {
            name: "Marshall Islands",
            code: "MH",
            phoneCode: "+692",
        },
        {
            name: "Sint Maarten",
            code: "SX",
            phoneCode: "+1721",
        },
        {
            name: "Iceland",
            code: "IS",
            phoneCode: "+354",
        },
        {
            name: "Luxembourg",
            code: "LU",
            phoneCode: "+352",
        },
        {
            name: "Argentina",
            code: "AR",
            phoneCode: "+54",
        },
        {
            name: "Turks and Caicos Islands",
            code: "TC",
            phoneCode: "+1649",
        },
        {
            name: "Nauru",
            code: "NR",
            phoneCode: "+674",
        },
        {
            name: "Cocos (Keeling) Islands",
            code: "CC",
            phoneCode: "+61",
        },
        {
            name: "Western Sahara",
            code: "EH",
            phoneCode: "+2125288",
        },
        {
            name: "Dominica",
            code: "DM",
            phoneCode: "+1767",
        },
        {
            name: "Costa Rica",
            code: "CR",
            phoneCode: "+506",
        },
        {
            name: "Australia",
            code: "AU",
            phoneCode: "+61",
        },
        {
            name: "Thailand",
            code: "TH",
            phoneCode: "+66",
        },
        {
            name: "Haiti",
            code: "HT",
            phoneCode: "+509",
        },
        {
            name: "Tuvalu",
            code: "TV",
            phoneCode: "+688",
        },
        {
            name: "Honduras",
            code: "HN",
            phoneCode: "+504",
        },
        {
            name: "Equatorial Guinea",
            code: "GQ",
            phoneCode: "+240",
        },
        {
            name: "Saint Lucia",
            code: "LC",
            phoneCode: "+1758",
        },
        {
            name: "French Polynesia",
            code: "PF",
            phoneCode: "+689",
        },
        {
            name: "Belarus",
            code: "BY",
            phoneCode: "+375",
        },
        {
            name: "Latvia",
            code: "LV",
            phoneCode: "+371",
        },
        {
            name: "Palau",
            code: "PW",
            phoneCode: "+680",
        },
        {
            name: "Guadeloupe",
            code: "GP",
            phoneCode: "+590",
        },
        {
            name: "Philippines",
            code: "PH",
            phoneCode: "+63",
        },
        {
            name: "Gibraltar",
            code: "GI",
            phoneCode: "+350",
        },
        {
            name: "Denmark",
            code: "DK",
            phoneCode: "+45",
        },
        {
            name: "Cameroon",
            code: "CM",
            phoneCode: "+237",
        },
        {
            name: "Guinea",
            code: "GN",
            phoneCode: "+224",
        },
        {
            name: "Bahrain",
            code: "BH",
            phoneCode: "+973",
        },
        {
            name: "Suriname",
            code: "SR",
            phoneCode: "+597",
        },
        {
            name: "DR Congo",
            code: "CD",
            phoneCode: "+243",
        },
        {
            name: "Somalia",
            code: "SO",
            phoneCode: "+252",
        },
        {
            name: "Czechia",
            code: "CZ",
            phoneCode: "+420",
        },
        {
            name: "New Caledonia",
            code: "NC",
            phoneCode: "+687",
        },
        {
            name: "Vanuatu",
            code: "VU",
            phoneCode: "+678",
        },
        {
            name: "Saint Helena, Ascension and Tristan da Cunha",
            code: "SH",
            phoneCode: "+290",
        },
        {
            name: "Togo",
            code: "TG",
            phoneCode: "+228",
        },
        {
            name: "British Virgin Islands",
            code: "VG",
            phoneCode: "+1284",
        },
        {
            name: "Kenya",
            code: "KE",
            phoneCode: "+254",
        },
        {
            name: "Niue",
            code: "NU",
            phoneCode: "+683",
        },
        {
            name: "Heard Island and McDonald Islands",
            code: "HM",
            phoneCode: "",
        },
        {
            name: "Rwanda",
            code: "RW",
            phoneCode: "+250",
        },
        {
            name: "Estonia",
            code: "EE",
            phoneCode: "+372",
        },
        {
            name: "Romania",
            code: "RO",
            phoneCode: "+40",
        },
        {
            name: "Trinidad and Tobago",
            code: "TT",
            phoneCode: "+1868",
        },
        {
            name: "Guyana",
            code: "GY",
            phoneCode: "+592",
        },
        {
            name: "Timor-Leste",
            code: "TL",
            phoneCode: "+670",
        },
        {
            name: "Vietnam",
            code: "VN",
            phoneCode: "+84",
        },
        {
            name: "Uruguay",
            code: "UY",
            phoneCode: "+598",
        },
        {
            name: "Vatican City",
            code: "VA",
            phoneCode: "+3906698",
        },
        {
            name: "Hong Kong",
            code: "HK",
            phoneCode: "+852",
        },
        {
            name: "Austria",
            code: "AT",
            phoneCode: "+43",
        },
        {
            name: "Antigua and Barbuda",
            code: "AG",
            phoneCode: "+1268",
        },
        {
            name: "Turkmenistan",
            code: "TM",
            phoneCode: "+993",
        },
        {
            name: "Mozambique",
            code: "MZ",
            phoneCode: "+258",
        },
        {
            name: "Panama",
            code: "PA",
            phoneCode: "+507",
        },
        {
            name: "Micronesia",
            code: "FM",
            phoneCode: "+691",
        },
        {
            name: "Ireland",
            code: "IE",
            phoneCode: "+353",
        },
        {
            name: "Curaçao",
            code: "CW",
            phoneCode: "+599",
        },
        {
            name: "French Guiana",
            code: "GF",
            phoneCode: "+594",
        },
        {
            name: "Norway",
            code: "NO",
            phoneCode: "+47",
        },
        {
            name: "Åland Islands",
            code: "AX",
            phoneCode: "+35818",
        },
        {
            name: "Central African Republic",
            code: "CF",
            phoneCode: "+236",
        },
        {
            name: "Burkina Faso",
            code: "BF",
            phoneCode: "+226",
        },
        {
            name: "Eritrea",
            code: "ER",
            phoneCode: "+291",
        },
        {
            name: "Tanzania",
            code: "TZ",
            phoneCode: "+255",
        },
        {
            name: "South Korea",
            code: "KR",
            phoneCode: "+82",
        },
        {
            name: "Jordan",
            code: "JO",
            phoneCode: "+962",
        },
        {
            name: "Mauritania",
            code: "MR",
            phoneCode: "+222",
        },
        {
            name: "Lithuania",
            code: "LT",
            phoneCode: "+370",
        },
        {
            name: "United States Minor Outlying Islands",
            code: "UM",
            phoneCode: "+268",
        },
        {
            name: "Slovakia",
            code: "SK",
            phoneCode: "+421",
        },
        {
            name: "Angola",
            code: "AO",
            phoneCode: "+244",
        },
        {
            name: "Kazakhstan",
            code: "KZ",
            phoneCode: "+76",
        },
        {
            name: "Moldova",
            code: "MD",
            phoneCode: "+373",
        },
        {
            name: "Mali",
            code: "ML",
            phoneCode: "+223",
        },
        {
            name: "Falkland Islands",
            code: "FK",
            phoneCode: "+500",
        },
        {
            name: "Armenia",
            code: "AM",
            phoneCode: "+374",
        },
        {
            name: "Samoa",
            code: "WS",
            phoneCode: "+685",
        },
        {
            name: "Jersey",
            code: "JE",
            phoneCode: "+44",
        },
        {
            name: "Japan",
            code: "JP",
            phoneCode: "+81",
        },
        {
            name: "Bolivia",
            code: "BO",
            phoneCode: "+591",
        },
        {
            name: "Chile",
            code: "CL",
            phoneCode: "+56",
        },
        {
            name: "United States",
            code: "US",
            phoneCode: "+1201",
        },
        {
            name: "Saint Vincent and the Grenadines",
            code: "VC",
            phoneCode: "+1784",
        },
        {
            name: "Bermuda",
            code: "BM",
            phoneCode: "+1441",
        },
        {
            name: "Seychelles",
            code: "SC",
            phoneCode: "+248",
        },
        {
            name: "British Indian Ocean Territory",
            code: "IO",
            phoneCode: "+246",
        },
        {
            name: "Guatemala",
            code: "GT",
            phoneCode: "+502",
        },
        {
            name: "Ecuador",
            code: "EC",
            phoneCode: "+593",
        },
        {
            name: "Martinique",
            code: "MQ",
            phoneCode: "+596",
        },
        {
            name: "Tajikistan",
            code: "TJ",
            phoneCode: "+992",
        },
        {
            name: "Malta",
            code: "MT",
            phoneCode: "+356",
        },
        {
            name: "Gambia",
            code: "GM",
            phoneCode: "+220",
        },
        {
            name: "Nigeria",
            code: "NG",
            phoneCode: "+234",
        },
        {
            name: "Bahamas",
            code: "BS",
            phoneCode: "+1242",
        },
        {
            name: "Kosovo",
            code: "XK",
            phoneCode: "+383",
        },
        {
            name: "Kuwait",
            code: "KW",
            phoneCode: "+965",
        },
        {
            name: "Maldives",
            code: "MV",
            phoneCode: "+960",
        },
        {
            name: "South Sudan",
            code: "SS",
            phoneCode: "+211",
        },
        {
            name: "Iran",
            code: "IR",
            phoneCode: "+98",
        },
        {
            name: "Albania",
            code: "AL",
            phoneCode: "+355",
        },
        {
            name: "Brazil",
            code: "BR",
            phoneCode: "+55",
        },
        {
            name: "Serbia",
            code: "RS",
            phoneCode: "+381",
        },
        {
            name: "Belize",
            code: "BZ",
            phoneCode: "+501",
        },
        {
            name: "Myanmar",
            code: "MM",
            phoneCode: "+95",
        },
        {
            name: "Bhutan",
            code: "BT",
            phoneCode: "+975",
        },
        {
            name: "Venezuela",
            code: "VE",
            phoneCode: "+58",
        },
        {
            name: "Liberia",
            code: "LR",
            phoneCode: "+231",
        },
        {
            name: "Jamaica",
            code: "JM",
            phoneCode: "+1876",
        },
        {
            name: "Poland",
            code: "PL",
            phoneCode: "+48",
        },
        {
            name: "Cayman Islands",
            code: "KY",
            phoneCode: "+1345",
        },
        {
            name: "Brunei",
            code: "BN",
            phoneCode: "+673",
        },
        {
            name: "Comoros",
            code: "KM",
            phoneCode: "+269",
        },
        {
            name: "Guam",
            code: "GU",
            phoneCode: "+1671",
        },
        {
            name: "Tonga",
            code: "TO",
            phoneCode: "+676",
        },
        {
            name: "Kiribati",
            code: "KI",
            phoneCode: "+686",
        },
        {
            name: "Ghana",
            code: "GH",
            phoneCode: "+233",
        },
        {
            name: "Chad",
            code: "TD",
            phoneCode: "+235",
        },
        {
            name: "Zimbabwe",
            code: "ZW",
            phoneCode: "+263",
        },
        {
            name: "Saint Martin",
            code: "MF",
            phoneCode: "+590",
        },
        {
            name: "Mongolia",
            code: "MN",
            phoneCode: "+976",
        },
        {
            name: "Portugal",
            code: "PT",
            phoneCode: "+351",
        },
        {
            name: "American Samoa",
            code: "AS",
            phoneCode: "+1684",
        },
        {
            name: "Republic of the Congo",
            code: "CG",
            phoneCode: "+242",
        },
        {
            name: "Belgium",
            code: "BE",
            phoneCode: "+32",
        },
        {
            name: "Israel",
            code: "IL",
            phoneCode: "+972",
        },
        {
            name: "New Zealand",
            code: "NZ",
            phoneCode: "+64",
        },
        {
            name: "Nicaragua",
            code: "NI",
            phoneCode: "+505",
        },
        {
            name: "Anguilla",
            code: "AI",
            phoneCode: "+1264",
        },
    ];

    useEffect(() => {
        async function fetchShopDetails() {
            const data = await dispatch(getShopDetail("sell"));
            if (isMounted.current && data && data.payload) {
                setShopDetails(data.payload);
            }
        }
        async function fetchRegisterData() {
            if (registerDetails) {
                setRegisterData(registerDetails);
            }
        }
        async function fetchSetupPrint() {
            let getSetupPrintList = await dispatch(getAllSetUpPrinterList("sell"));
            if (getSetupPrintList) {
                setsetupPrinterList(getSetupPrintList);
            }
        }
        if (isMounted.current) {
            fetchShopDetails();
            fetchRegisterData();
            fetchSetupPrint();
        }
        return () => {
            isMounted.current = false;
        };
    }, []);
    useEffect(() => {
        let contryCode = countrySymbol.find((val) => val.currency?.symbol == rsSymbol);
        if (contryCode && contryCode?.code) {
            let findPhoneCode = phoneCode.find((val) => val.code == contryCode?.code);
            if (findPhoneCode) {
                console.log("findPhoneCodefindPhoneCode", findPhoneCode);
                setPhoneNumber({
                    number: "",
                    code: findPhoneCode.phoneCode,
                    codeString: contryCode.code,
                });
            }
        }
    }, []);
    async function fetchPaymentType(receiptData) {
        console.log("dajnjnncdscdscsdcdsc", receiptData);
        const getPaymentTypeList = await dispatch(getAllPaymentTypeList("sell"));
        if (isMounted.current && getPaymentTypeList && getPaymentTypeList.PaymentTypeList) {
            let array = [];
            if (receiptData.order_id.details && receiptData.order_id.details.bingagePaymnetType && receiptData.order_id.details.bingagePaymnetType == "Room") {
                array.push({ name: "Room" });
            }
            setPaymentTypeList([...array, ...getPaymentTypeList.PaymentTypeList.reverse()]);
        }
    }

    async function fetchRecepitsData(id) {
        const getRecepitsData = await dispatch(getReceiptsById(id));
        if (isMounted.current && getRecepitsData) {
            fetchPaymentType(getRecepitsData.ReceiptsIdData);
            getRecepitsData.ReceiptsIdData.order_id.details.receipt_number = getRecepitsData.ReceiptsIdData.receipt_number;
            let Taxesdata = [];
            let subTotalPrice = 0;
            let totalcustomItemDisocunt = 0;
            if (getRecepitsData.ReceiptsIdData.order_id.details?.AddtionChargeValue?.length > 0) {
                getRecepitsData.ReceiptsIdData.order_id.details.AddtionChargeValue.map((j) => {
                    if (j.is_automatically_added) {
                        j.tax_group &&
                            j.tax_group.taxes.map((data) => {
                                let totalTaxPrice = data.totalTaxPrice;
                                Taxesdata.push({
                                    name: data.tax_name,
                                    value: totalTaxPrice,
                                });
                            });
                    }
                });
            }
            getRecepitsData.ReceiptsIdData.order_id.details.itemsSold.map((product) => {
                subTotalPrice += product.calculatedprice;
                product.customDiscountedValue && (totalcustomItemDisocunt += Number(product.customDiscountedValue));

                product.taxGroup &&
                    product.taxGroup.taxes.map((data) => {
                        let totalTaxPrice = isNaN(data.totalTaxPrice) ? 0 : data.totalTaxPrice;
                        Taxesdata.push({
                            name: data.tax_name,
                            value: totalTaxPrice,
                        });
                    });
            });
            var holder = {};
            Taxesdata.forEach(function(d) {
                if (holder.hasOwnProperty(d.name)) {
                    holder[d.name] = holder[d.name] + d.value;
                } else {
                    holder[d.name] = d.value;
                }
            });
            var FinalTaxesArray = [];
            for (var prop in holder) {
                if (holder[prop] > 0) {
                    FinalTaxesArray.push({ name: prop, value: holder[prop] });
                }
            }
            console.log("FinalTaxesArrayFinalTaxesArray", FinalTaxesArray);
            if (getRecepitsData?.ReceiptsIdData?.order_id?.details?.onlineOrder?.packingCharge) {
                FinalTaxesArray.push({
                    name: "Packing Charges",
                    value: getRecepitsData?.ReceiptsIdData?.order_id?.details?.onlineOrder?.packingCharge,
                });
            }
            if (getRecepitsData?.ReceiptsIdData?.order_id?.details?.onlineOrder?.tax > 0) {
                FinalTaxesArray.push({
                    name: "Tax",
                    value: getRecepitsData?.ReceiptsIdData?.order_id?.details?.onlineOrder?.tax,
                });
            }

            getRecepitsData.ReceiptsIdData.order_id.details.priceSummery = {
                ...getRecepitsData.ReceiptsIdData?.order_id.details.priceSummery,
                taxexArray: FinalTaxesArray,
                sub_total: Number(subTotalPrice).toFixed(2),
                totalItemDisocunts: Number(totalcustomItemDisocunt).toFixed(2),
            };

            setRecepitsData(getRecepitsData.ReceiptsIdData);
            setItemsList(getRecepitsData.ReceiptsIdData.order_id.details.itemsSold.filter((val) => val.quantity > 0));

            if (getRecepitsData.ReceiptsIdData.order_id.details.saleType == "immediate") {
                let sum = getRecepitsData.ReceiptsIdData.order_id.details.immediate_sale.multiple_payments_type
                    .filter((val) => val.name != "Credit Sales (Pending)" && val.name != "pending")
                    .reduce(function(acc, obj) {
                        return acc + Number(obj.value);
                    }, 0);
                setRefundAmount(sum);
                form.setFieldsValue({
                    refund_amount: sum,
                });
            } else {
                let sums = getRecepitsData.ReceiptsIdData.order_id.details.bookingDetails.booking_advance_payment_type.reduce(function(acc, obj) {
                    return acc + Number(obj.value);
                }, 0);
                setRefundAmount(sums);
                form.setFieldsValue({
                    refund_amount: sums,
                });
            }

            if (getRecepitsData.ReceiptsIdData.order_id.cancellation) {
                setDeleteButton(true);
            }
        }
    }

    useEffect(() => {
        if (isMounted.current) {
            fetchRecepitsData(match.params.id);
        }
        return () => {
            isMounted.current = false;
        };
    }, []);

    const onSubmit = () => {
        setLoading(true);
        form.validateFields()
            .then(async (formData) => {
                formData.cancel_Date = new Date();

                let obj = {
                    cancellation: { ...formData },
                };
                if (RecepitsData.order_id && RecepitsData.order_id?.details?.poseaseRoom) {
                    obj["roomDetails"] = {
                        ...RecepitsData.order_id?.details?.roomDetails,
                        receipt_id: RecepitsData._id,
                    };
                }
                const getCancelOrder = await dispatch(cancelOrder(obj, RecepitsData.order_id));

                if (getCancelOrder && getCancelOrder.cancelOrderData) {
                    fetchRecepitsData(match.params.id);
                    setModalVisibleOrderCancel(false);
                    setModalVisibleConfirmCancel(true);
                    setDeleteButton(true);
                    setLoading(false);
                }
            })
            .catch((errorInfo) => errorInfo);
    };

    const fullFillOrder = async () => {
        RecepitsData.order_id.details.fulfillmentStatus = "Fulfilled";
        let ordederUpdatedata = { details: RecepitsData.order_id.details };
        ordederUpdatedata.updatePaymentDate = new Date();
        const getUpdateReceiptsData = await dispatch(AddAndUpdateBooking(ordederUpdatedata, RecepitsData.order_id._id));
    };

    const deleteReceipts = async (e) => {
        setLoading(true);
        // RecepitsData.order_id._id;
        console.log("receiptsdatacheckdelete", RecepitsData.order_id._id);
        if (`${match.params.id}_${RecepitsData.order_id._id}`) {
            const deleteReceiptData = await dispatch(deleteReceipt(`${match.params.id}_${RecepitsData.order_id._id}`));
            if (!deleteReceiptData.receiptDeletedData.error) {
                setLoading(false);
                history.push(`/receipts`);
            }
        }
    };

    const { rtl } = useSelector((state) => {
        return {
            rtl: state.ChangeLayoutMode.rtlData,
        };
    });

    const dataSource = [];
    let checkDiscount = false;
    // let totalItemDiscount;
    ItemsList.map((value) => {
        if (value.customDiscountedValue) {
            checkDiscount = true;
            // totalItemDiscount += value.customDiscountedValue;
        }
        const { id, quantity, calculatedprice, display_name, productTaxes, customDiscountedValue, productInclusivePricecalculatedprice } = value;

        return dataSource.push({
            id: id,
            quantity: quantity,
            price: productInclusivePricecalculatedprice ? productInclusivePricecalculatedprice : calculatedprice,
            display_name: display_name,
            taxes: productTaxes > 0 ? productTaxes : "-",
            customDiscountedValue: customDiscountedValue,
        });
    });

    const invoiceTableColumns = [
        {
            title: "Items",
            dataIndex: "display_name",
            key: "display_name",
            width: "60%",
            render(text, record) {
                let text2 = text.toString();

                let newSpilitArray = text2.split(/[+]/);
                let newSpilitArray1 = text2.split(/[,]/);

                let finalArray = [];
                newSpilitArray.map((value) => {
                    finalArray.push(value.replace(/,/gi, ""));
                });

                return {
                    children: (
                        <>
                            {text2.includes("-") ? (
                                newSpilitArray1.map((val) => <div>{val}</div>)
                            ) : (
                                <div>
                                    {" "}
                                    {finalArray.length > 1 ? (
                                        <div>
                                            {finalArray.map((value, index) => {
                                                return (
                                                    <div>
                                                        {index > 0 ? "+" : null}
                                                        {value}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <div>{text}</div>
                                    )}
                                </div>
                            )}
                        </>
                    ),
                };
            },
        },

        {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            render(text, record) {
                return {
                    props: {
                        style: { textAlign: "left" },
                    },
                    children: <span>{text}</span>,
                };
            },
        },
        {
            title: "Tax %",
            dataIndex: "taxes",
            key: "taxes",
        },
        checkDiscount
            ? {
                  title: "Discount",
                  align: "left",
                  render(text, record, index) {
                      return {
                          children: (
                              <div>
                                  {text.discountedValue || (text.customDiscountedValue && text.quantity > 0)
                                      ? `${rsSymbol}${text.discountedValue || text.customDiscountedValue} `
                                      : ""}
                              </div>
                          ),
                      };
                  },
              }
            : {},
        {
            title: "Price",
            dataIndex: "price",
            key: "price",
            className: "pricetgt",
            render(text, record) {
                return {
                    children: (
                        <span className="product-unit">
                            {rsSymbol}
                            {Number(text).toFixed(2)}
                        </span>
                    ),
                };
            },
        },
    ];
    const [mailForm] = Form.useForm();
    const emailDataSet = () => {
        setEmailVisible(true);
        if (RecepitsData?.order_id?.customer?.mobile) {
            setPhoneNumber({ ...phoneNumber, number: RecepitsData?.order_id?.customer?.mobile });
        }
    };

    const onFinish = async () => {
        let shop_name = shopDetails?.shop_name;
        let totalAmount = `${rsSymbol}${RecepitsData?.order_id.details.priceSummery.total}`;
        let billUrl = `https://web.posease.com/app/invoice/${RecepitsData?._id}`;
        const message = ` 
        Thank you for visiting ${shop_name}!

        Your bill amount is ${totalAmount}. Here’s your digital receipt
        
        ${billUrl}
        
        For feedback or assistance, feel free to reply to this message.
    `;
        const whatsappUrl = `https://wa.me/${phoneNumber.code}${phoneNumber.number}?text=${encodeURIComponent(message)}`;
        window.open(whatsappUrl, "_blank");
        setEmailVisible(false);
    };
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const content = (
        <div>
            <Form name="email_form" layout="vertical" form={mailForm} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                <Form.Item
                    label=""
                    rules={[
                        {
                            required: true,
                            message: "Phone is required!",
                        },
                    ]}
                >
                    <PhoneInput
                        country={phoneNumber.codeString || "in"} // Set country code dynamically
                        value={`+${phoneNumber.code}${phoneNumber.number}`}
                        inputProps={{
                            name: "phone",
                            required: true,
                        }}
                        enableSearch={true}
                        onChange={(phone, country) => {
                            setPhoneNumber({
                                number: phone.substring(country.dialCode.length),
                                code: country.dialCode,
                                codeString: country.countryCode,
                            });
                        }}
                    />
                </Form.Item>
                <br />
                <Form.Item>
                    <Button size="middle" type="primary" htmlType="submit" style={{ marginLeft: "30%" }}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
    return (
        <div style={userDetails.role == "cashier" ? { marginTop: "-25px" } : {}}>
            {RecepitsData ? (
                <>
                    {RecepitsData && RecepitsData.order_id.details.saleType === "immediate" ? (
                        <div>
                            {RecepitsData &&
                            RecepitsData.order_id.details.immediate_sale.multiple_payments_type.length &&
                            RecepitsData.order_id.details.immediate_sale.multiple_payments_type[0].no ? (
                                RecepitsData.order_id.details.immediate_sale.multiple_payments_type.map((data, indx) => {
                                    if (data.customer_type == "equally") {
                                        return (
                                            <>
                                                <Main className="receipts_inv">
                                                    <PageHeader
                                                        ghost
                                                        className="custome-status-header"
                                                        title={
                                                            <>
                                                                <span>
                                                                    Status1 &nbsp;
                                                                    {RecepitsData && (
                                                                        <>
                                                                            {RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                                                <Tag color="#43ac6a">Paid</Tag>
                                                                            ) : (
                                                                                <Tag color="#e99002">Unpaid</Tag>
                                                                            )}
                                                                            {RecepitsData.order_id.details.fulfillmentStatus == "Fulfilled" &&
                                                                            deletebuttonShow == false ? (
                                                                                <Tag color="#008cba">Fulfilled</Tag>
                                                                            ) : RecepitsData.order_id.details.fulfillmentStatus == "Unfulfilled" &&
                                                                              deletebuttonShow == false ? (
                                                                                <Tag color="darkgray">Unfulfilled</Tag>
                                                                            ) : (
                                                                                <Tag color="#f04124">Cancelled</Tag>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </span>
                                                                {RecepitsData.order_id.cancellation?.cancellation_reason && (
                                                                    <p>Cancellation Notes - {RecepitsData.order_id.cancellation.cancellation_reason}</p>
                                                                )}
                                                            </>
                                                        }
                                                        buttons={[
                                                            <div key="1" className="page-header-actions">
                                                                <Button
                                                                    shape="round"
                                                                    type="default"
                                                                    onClick={() => {
                                                                        window.frames[
                                                                            "print_frame"
                                                                        ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                            <ReceiptPrint
                                                                                title="DUPLICATE"
                                                                                receiptsDetails={RecepitsData.order_id}
                                                                                shopDetails={shopDetails}
                                                                                registerData={registerdata}
                                                                                ReceiptNumber={
                                                                                    RecepitsData?.order_id?.details?.receipt_number
                                                                                        ? RecepitsData?.order_id?.details?.receipt_number
                                                                                        : undefined
                                                                                }
                                                                            />
                                                                        );
                                                                        window.frames["print_frame"].window.focus();
                                                                        window.frames["print_frame"].window.print();
                                                                    }}
                                                                >
                                                                    <FeatherIcon icon="printer" size={14} />
                                                                    Print
                                                                </Button>
                                                                <Button shape="round" type="primary" onClick={() => history.push(`/receipts`)}>
                                                                    Go Back
                                                                </Button>
                                                                {deletebuttonShow ? (
                                                                    <Button shape="round" type="primary" onClick={() => setDeleteReceiptsModalVisible(true)}>
                                                                        Delete
                                                                    </Button>
                                                                ) : (
                                                                    (userDetails.role == "restaurant" ||
                                                                        (userDetails.role == "cashier" &&
                                                                            userDetails.has_manager_permission &&
                                                                            !getItem("do_not_allow_manager_to_receipt_cancel"))) && (
                                                                        <Button shape="round" type="primary" onClick={() => setModalVisibleOrderCancel(true)}>
                                                                            Cancel
                                                                        </Button>
                                                                    )
                                                                )}
                                                            </div>,
                                                        ]}
                                                    />
                                                    <Row gutter={15}>
                                                        <Col md={24}>
                                                            <Cards headless>
                                                                <InvoiceLetterBox>
                                                                    <div className="invoice-letter-inner">
                                                                        <Row align="top">
                                                                            <Col lg={10} xs={24}>
                                                                                <article className="invoice-author">
                                                                                    <Heading className="invoice-author__title" as="h3">
                                                                                        Invoice
                                                                                    </Heading>
                                                                                    <p>No : #{RecepitsData.receipt_number}</p>
                                                                                    <p className="color-5">
                                                                                        Prepared by {RecepitsData.order_id.details.order_by_name.username}{" "}
                                                                                        {RecepitsData.order_id.details.tableName != undefined &&
                                                                                            ` | ${RecepitsData.order_id.details.tableName}`}
                                                                                    </p>
                                                                                    <p>
                                                                                        on{" "}
                                                                                        {RecepitsData?.order_id?.actual_time
                                                                                            ? commonFunction.convertToDate(
                                                                                                  RecepitsData.order_id.actual_time,
                                                                                                  "MMM DD, Y h:mm A"
                                                                                              )
                                                                                            : commonFunction.convertToDate(
                                                                                                  RecepitsData.created_at,
                                                                                                  "MMM DD, Y h:mm A"
                                                                                              )}
                                                                                    </p>
                                                                                    <p className="color-5">
                                                                                        {" "}
                                                                                        {`${RecepitsData.register_id.register_name} Register`}
                                                                                    </p>
                                                                                </article>
                                                                            </Col>
                                                                            {RecepitsData.order_id.customer.name ||
                                                                            RecepitsData.order_id.customer.email != "" ||
                                                                            RecepitsData.order_id.customer.mobile != null ||
                                                                            RecepitsData.order_id.customer.city ||
                                                                            RecepitsData.order_id.customer.shipping_address ||
                                                                            RecepitsData.order_id.customer.zipcode ||
                                                                            RecepitsData?.order_id.details.customer_custom_fields?.length > 0 ||
                                                                            RecepitsData.order_id.details.custom_fields.length > 0 ? (
                                                                                <Col lg={14} xs={24}>
                                                                                    <address className="invoice-customer">
                                                                                        <Heading className="invoice-customer__title" as="h5">
                                                                                            Invoice To:
                                                                                        </Heading>

                                                                                        {RecepitsData.order_id.customer?.name ||
                                                                                        RecepitsData.order_id.customer?.email ||
                                                                                        RecepitsData.order_id.customer?.mobile ? (
                                                                                            <p>
                                                                                                {" "}
                                                                                                {RecepitsData.order_id.customer?.name
                                                                                                    ? RecepitsData.order_id.customer?.mobile ||
                                                                                                      RecepitsData.order_id.customer?.email
                                                                                                        ? `${RecepitsData.order_id.customer?.name} | `
                                                                                                        : RecepitsData.order_id.customer?.name
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer?.mobile
                                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                                        ? `${RecepitsData.order_id.customer?.mobile} | `
                                                                                                        : RecepitsData.order_id.customer?.mobile
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer?.email
                                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                                    : null}{" "}
                                                                                                {RecepitsData.order_id.customer?.shipping_address ? (
                                                                                                    <br />
                                                                                                ) : null}
                                                                                                {RecepitsData.order_id.customer?.shipping_address
                                                                                                    ? RecepitsData.order_id.customer?.city ||
                                                                                                      RecepitsData.order_id.customer?.zipcode
                                                                                                        ? `${RecepitsData.order_id.customer?.shipping_address} `
                                                                                                        : RecepitsData.order_id.customer?.shipping_address
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer.city ? <br /> : null}
                                                                                                {RecepitsData.order_id.customer?.city
                                                                                                    ? RecepitsData.order_id.customer?.zipcode
                                                                                                        ? `${RecepitsData.order_id.customer?.city}`
                                                                                                        : RecepitsData.order_id.customer?.city
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer?.zipcode
                                                                                                    ? ` - ${RecepitsData.order_id.customer?.zipcode}`
                                                                                                    : null}{" "}
                                                                                            </p>
                                                                                        ) : null}

                                                                                        <p className="color-5">
                                                                                            {RecepitsData?.order_id.details.customer_custom_fields?.map(
                                                                                                (data, index) => {
                                                                                                    if (
                                                                                                        RecepitsData.order_id.details.customer_custom_fields
                                                                                                            .length == 1 &&
                                                                                                        data.value != ""
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {data.name}
                                                                                                                {" : "}
                                                                                                                <span>{data.value}</span>
                                                                                                            </>
                                                                                                        );
                                                                                                    } else if (
                                                                                                        index + 1 ==
                                                                                                            RecepitsData.order_id.details.customer_custom_fields
                                                                                                                .length &&
                                                                                                        data.value != ""
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {data.name}
                                                                                                                {" : "}
                                                                                                                <span>{data.value}</span>
                                                                                                            </>
                                                                                                        );
                                                                                                    } else if (data.value != "") {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {data.name}
                                                                                                                {" : "}
                                                                                                                <span>{data.value}</span> {" | "}
                                                                                                            </>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </p>
                                                                                        <p className="color-5">
                                                                                            {RecepitsData.order_id.details.custom_fields.map((val) => (
                                                                                                <Tag color={val.tag_color}>{val.name}</Tag>
                                                                                            ))}
                                                                                        </p>
                                                                                    </address>
                                                                                </Col>
                                                                            ) : null}
                                                                        </Row>
                                                                    </div>
                                                                </InvoiceLetterBox>
                                                                <Modal
                                                                    title="Confirm Delete"
                                                                    visible={deleteReceiptsModalVisible}
                                                                    onCancel={() => setDeleteReceiptsModalVisible(false)}
                                                                    cancelText="Go Back"
                                                                    onOk={deleteReceipts}
                                                                    okText={
                                                                        loading ? (
                                                                            <Spin
                                                                                indicator={
                                                                                    <LoadingOutlined
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                            color: "white",
                                                                                            margin: "0px 14px",
                                                                                        }}
                                                                                        spin
                                                                                    />
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            "Delete Receipt"
                                                                        )
                                                                    }
                                                                >
                                                                    <p>
                                                                        Deleting the receipt will permanently remove it and will no longer appear on reports.
                                                                        Also, deleting the receipt will keep the metrics as they were after cancellation. Are
                                                                        you sure you want to proceed?
                                                                    </p>
                                                                </Modal>
                                                                <Modal
                                                                    title="Confirm Cancelled."
                                                                    visible={modalVisibleConfirmCancel}
                                                                    footer={[
                                                                        <Button type="primary" onClick={() => setModalVisibleConfirmCancel(false)}>
                                                                            Ok
                                                                        </Button>,
                                                                    ]}
                                                                >
                                                                    <p>Receipt has been cancelled.</p>
                                                                </Modal>
                                                                <Modal
                                                                    title="Confirm Cancel"
                                                                    bodyStyle={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: "12px",
                                                                    }}
                                                                    visible={modalVisibleOrderCancel}
                                                                    onCancel={() => {
                                                                        setModalVisibleOrderCancel(false);
                                                                        form.setFieldsValue({
                                                                            refund_amount: refundAmount,
                                                                        });
                                                                    }}
                                                                    width={600}
                                                                    footer={[
                                                                        <Button
                                                                            type="default"
                                                                            className="btn-cancel btn-custom go_back"
                                                                            onClick={() => {
                                                                                setModalVisibleOrderCancel(false);
                                                                                form.setFieldsValue({
                                                                                    refund_amount: refundAmount,
                                                                                });
                                                                            }}
                                                                        >
                                                                            Go Back
                                                                        </Button>,
                                                                        <Button
                                                                            type="primary"
                                                                            disabled={PaymentType || refundAmount == 0 ? false : true}
                                                                            onClick={() => onSubmit()}
                                                                        >
                                                                            {loading ? (
                                                                                <Spin
                                                                                    indicator={
                                                                                        <LoadingOutlined
                                                                                            style={{
                                                                                                fontSize: 16,
                                                                                                color: "white",
                                                                                                margin: "0px 14px",
                                                                                            }}
                                                                                            spin
                                                                                        />
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                "Cancel Receipt"
                                                                            )}
                                                                        </Button>,
                                                                    ]}
                                                                >
                                                                    <Form style={{ width: "100%" }} name="Export" form={form} onFinish={onSubmit}>
                                                                        <Form.Item
                                                                            name="refund_amount"
                                                                            label="Enter Refund Amount"
                                                                            rules={[
                                                                                {
                                                                                    validator: (_, value) => {
                                                                                        if (Number(value) >= 0) {
                                                                                            return Promise.resolve();
                                                                                        } else {
                                                                                            return Promise.reject("Refund price should be a positive number.");
                                                                                        }
                                                                                    },
                                                                                },
                                                                                {
                                                                                    validator: (_, value) => {
                                                                                        if (Number(value) > refundAmount) {
                                                                                            return Promise.reject(
                                                                                                "Refund amount cannot be more than the paid amount."
                                                                                            );
                                                                                        } else {
                                                                                            return Promise.resolve();
                                                                                        }
                                                                                    },
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Refund Amount" type="number" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name="refund_pay_type"
                                                                            label="Payment Type"
                                                                            rules={[
                                                                                {
                                                                                    message: "Choose a payment type to proceed",
                                                                                    required: refundAmount > 0 ? true : false,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Radio.Group
                                                                                onChange={(e) => setPaymentType(e.target.value)}
                                                                                value={PaymentType}
                                                                                className="tick-radio"
                                                                            >
                                                                                <Radio.Button
                                                                                    value="cash"
                                                                                    style={{
                                                                                        marginRight: "10px",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    {PaymentType === "cash" ? (
                                                                                        <svg
                                                                                            width="13px"
                                                                                            style={{ marginRight: "2px" }}
                                                                                            viewBox="0 0 123 102"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                fill="#BE3D5D"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    Cash
                                                                                </Radio.Button>
                                                                                <Radio.Button
                                                                                    value="card"
                                                                                    style={{
                                                                                        marginRight: "10px",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    {PaymentType === "card" ? (
                                                                                        <svg
                                                                                            width="13px"
                                                                                            style={{ marginRight: "2px" }}
                                                                                            viewBox="0 0 123 102"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                fill="#BE3D5D"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}{" "}
                                                                                    Credit / Debit Card
                                                                                </Radio.Button>
                                                                                {PaymentTypeList.map((val, index) => {
                                                                                    return (
                                                                                        <Radio.Button
                                                                                            value={val.name}
                                                                                            style={{
                                                                                                marginRight: "10px",
                                                                                                marginBottom: "10px",
                                                                                            }}
                                                                                        >
                                                                                            {PaymentType === val.name ? (
                                                                                                <svg
                                                                                                    width="13px"
                                                                                                    style={{
                                                                                                        marginRight: "2px",
                                                                                                    }}
                                                                                                    viewBox="0 0 123 102"
                                                                                                    fill="none"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                        fill="#BE3D5D"
                                                                                                    />
                                                                                                </svg>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                            {val.name}
                                                                                        </Radio.Button>
                                                                                    );
                                                                                })}
                                                                                <Radio.Button
                                                                                    value="other"
                                                                                    style={{
                                                                                        marginRight: "10px",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    {PaymentType === "other" ? (
                                                                                        <svg
                                                                                            width="13px"
                                                                                            style={{ marginRight: "2px" }}
                                                                                            viewBox="0 0 123 102"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                fill="#BE3D5D"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    Other
                                                                                </Radio.Button>
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name="cancellation_reason"
                                                                            label="Cancellation Notes3"
                                                                            rules={[
                                                                                {
                                                                                    max: 50,
                                                                                    message: "Cancellation Notes cannot be more than 50 characters long.",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Cancellation notes" />
                                                                        </Form.Item>
                                                                    </Form>
                                                                </Modal>
                                                                <br />
                                                                <ProductTable>
                                                                    <div className="table-invoice table-responsive">
                                                                        <Table
                                                                            dataSource={dataSource}
                                                                            columns={invoiceTableColumns}
                                                                            pagination={false}
                                                                            rowClassName="invoice-table"
                                                                        />
                                                                    </div>
                                                                </ProductTable>
                                                                <Row justify="end" style={{ paddingRight: "17px" }}>
                                                                    <Col xxl={4} xl={5} sm={8} xs={14} offset={rtl ? 0 : 10}>
                                                                        <OrderSummary>
                                                                            <div className="invoice-summary-inner">
                                                                                <ul className="summary-list">
                                                                                    <li>
                                                                                        <span className="summary-list-title">Subtotal :</span>
                                                                                        <span className="summary-list-text">
                                                                                            {`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.sub_total}`}
                                                                                        </span>
                                                                                    </li>

                                                                                    {RecepitsData?.order_id.details.priceSummery.totalItemDisocunts > 0 && (
                                                                                        <li>
                                                                                            <span className="summary-list-title">Item Discounts :</span>
                                                                                            <span className="summary-list-text">
                                                                                                {`-${rsSymbol}${RecepitsData?.order_id.details.priceSummery.totalItemDisocunts}`}
                                                                                            </span>
                                                                                        </li>
                                                                                    )}
                                                                                    {RecepitsData?.order_id?.details?.bulckDiscountValue && (
                                                                                        <li>
                                                                                            <span className="summary-list-title">
                                                                                                Bulk Discount{" "}
                                                                                                {RecepitsData?.order_id?.details?.bingageDetails && (
                                                                                                    <Tooltip title={<div>Bingage wallet</div>}>
                                                                                                        <ExclamationCircleOutlined
                                                                                                            style={{
                                                                                                                cursor: "pointer",
                                                                                                            }}
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                                )}
                                                                                            </span>

                                                                                            <span className="summary-list-text">
                                                                                                {`-${rsSymbol}${RecepitsData?.order_id?.details?.bulckDiscountValue}`}
                                                                                            </span>
                                                                                        </li>
                                                                                    )}

                                                                                    {RecepitsData?.order_id?.details?.AddtionChargeValue?.length > 0 &&
                                                                                        RecepitsData?.order_id?.details?.AddtionChargeValue.map(
                                                                                            (charge) =>
                                                                                                charge.is_automatically_added && (
                                                                                                    <li>
                                                                                                        <span className="summary-list-title">
                                                                                                            {charge.charge_name}{" "}
                                                                                                            {charge.tax_group &&
                                                                                                                `(Tax ${charge.tax_group.Totaltax}%) :`}
                                                                                                        </span>
                                                                                                        <span className="summary-list-text">
                                                                                                            {rsSymbol}
                                                                                                            {Number(charge.AddtionalCalculatedValue).toFixed(2)}
                                                                                                        </span>
                                                                                                    </li>
                                                                                                )
                                                                                        )}
                                                                                    {RecepitsData?.order_id.details.priceSummery.taxexArray &&
                                                                                        RecepitsData?.order_id.details.priceSummery.taxexArray.map((val) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <span className="summary-list-title">{val.name} :</span>
                                                                                                    <span className="summary-list-text">{`${rsSymbol}${Number(
                                                                                                        val.value
                                                                                                    ).toFixed(2)}`}</span>
                                                                                                </li>
                                                                                            );
                                                                                        })}
                                                                                    {RecepitsData?.order_id.details.priceSummery.round_off_value && (
                                                                                        <li>
                                                                                            <span className="summary-list-title">Roundoff :</span>
                                                                                            <span className="summary-list-text">{`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.round_off_value}`}</span>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                                <Heading className="summary-total" as="h4">
                                                                                    <span className="summary-total-label">Total : </span>
                                                                                    <span className="summary-total-amount">{`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.total}`}</span>
                                                                                </Heading>
                                                                                <Heading className="summary-total" as="h4">
                                                                                    <span className="summary-total-label">
                                                                                        For {data.name != "" ? data.name : `Customer ${data.no}`}{" "}
                                                                                    </span>
                                                                                    <span className="summary-total-amount">{`${rsSymbol}${data.value}`}</span>
                                                                                </Heading>
                                                                            </div>
                                                                        </OrderSummary>
                                                                    </Col>
                                                                    <Col></Col>
                                                                </Row>

                                                                {RecepitsData && RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                                    <div className="border-top">
                                                                        {RecepitsData.order_id.details.orderTicketsData ? (
                                                                            <Row>
                                                                                Order Tickets :
                                                                                {RecepitsData.order_id.details.orderTicketsData.map((i, index) => {
                                                                                    let privewsOrderTiket = [];
                                                                                    RecepitsData.order_id.details.orderTicketsData
                                                                                        .slice(0, index)
                                                                                        .map((val) => privewsOrderTiket.push(val.tiketNumber));
                                                                                    return (
                                                                                        <div>
                                                                                            <span>
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(0, 140, 186)",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        window.frames[
                                                                                                            "print_frame"
                                                                                                        ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                                                            <OrderTicketPrint
                                                                                                                categoryDetails={i}
                                                                                                                PreviousTikets={privewsOrderTiket}
                                                                                                                ReceiptNumber={i.receiptNumberDetails?.number}
                                                                                                                TableName={i?.table_name ? i?.table_name : ""}
                                                                                                            />
                                                                                                        );
                                                                                                        window.frames["print_frame"].window.focus();
                                                                                                        window.frames["print_frame"].window.print();
                                                                                                    }}
                                                                                                >
                                                                                                    {index ==
                                                                                                    RecepitsData.order_id.details.orderTicketsData.length - 1
                                                                                                        ? `#${i.tiketNumber}`
                                                                                                        : `#${i.tiketNumber},`}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        ) : null}
                                                                        <Row>
                                                                            {data.payment_type_list.map((val) => {
                                                                                if (val.tick == true) {
                                                                                    return (
                                                                                        <>
                                                                                            <Col lg={6} md={18} sm={24} offset={0}>
                                                                                                <div className="receipt-payment-transactions">
                                                                                                    <p>{`${rsSymbol}${data.value} on ${val.name}`}</p>
                                                                                                    <p className="text-muted">
                                                                                                        {commonFunction.convertToDate(
                                                                                                            val.paymentDate
                                                                                                                ? val.paymentDate
                                                                                                                : RecepitsData.created_at,
                                                                                                            "MMM DD, Y h:mm A"
                                                                                                        )}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })}
                                                                            {RecepitsData && RecepitsData.order_id.cancellation && indx == 0 && (
                                                                                <Col lg={6} md={18} sm={24} offset={0}>
                                                                                    <div className="receipt-payment-transactions">
                                                                                        <p>{`${rsSymbol}${RecepitsData.order_id.cancellation.refund_amount} ${RecepitsData.order_id.cancellation.refund_pay_type} refund`}</p>
                                                                                        <p className="text-muted">
                                                                                            {commonFunction.convertToDate(
                                                                                                RecepitsData.order_id.cancellation.cancel_Date,
                                                                                                "MMM DD, Y h:mm A"
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div style={{ display: "none" }}>{PaymentTypeList.length}</div>

                                                                        <UnPaidReceipts
                                                                            deletebuttonShow={deletebuttonShow}
                                                                            RecepitsDataDetails={RecepitsData}
                                                                            PaymentTypeList={PaymentTypeList}
                                                                            updateFetch={fetchRecepitsData}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Cards>
                                                        </Col>
                                                    </Row>
                                                </Main>
                                            </>
                                        );
                                    } else {
                                        const tableData = [];
                                        let Taxesdata = [];
                                        let subTotalPrice = 0;
                                        let ItemDiscout = 0;
                                        let checkCustomDiscount = false;
                                        data.product_List.map((product) => {
                                            if (product.customDiscountedValue) {
                                                checkCustomDiscount = true;
                                                ItemDiscout += product.customDiscountedValue;
                                            }
                                            subTotalPrice += product.calculatedprice;
                                            product.taxGroup &&
                                                product.taxGroup.taxes.map((data) => {
                                                    let totalTaxPrice = data.totalTaxPrice;
                                                    Taxesdata.push({
                                                        name: data.tax_name,
                                                        value: totalTaxPrice,
                                                    });
                                                });

                                            const { id, quantity, oldCalculatedPrice, display_name, productTaxes, customDiscountedValue } = product;

                                            return tableData.push({
                                                id: id,
                                                quantity: quantity,
                                                price: oldCalculatedPrice,
                                                display_name: display_name,
                                                taxes: productTaxes > 0 ? productTaxes : "-",
                                                customDiscountedValue: customDiscountedValue,
                                            });
                                        });

                                        var holder = {};
                                        Taxesdata.forEach(function(d) {
                                            if (holder.hasOwnProperty(d.name)) {
                                                holder[d.name] = holder[d.name] + d.value;
                                            } else {
                                                holder[d.name] = d.value;
                                            }
                                        });
                                        var FinalTaxesArray = [];
                                        for (var prop in holder) {
                                            if (holder[prop] > 0) {
                                                FinalTaxesArray.push({
                                                    name: prop,
                                                    value: holder[prop],
                                                });
                                            }
                                        }

                                        RecepitsData.order_id.details.priceSummery = {
                                            ...RecepitsData.order_id.details.priceSummery,
                                            taxexArray: FinalTaxesArray,
                                            sub_total: Number(subTotalPrice).toFixed(2),
                                            totalItemDisocunts: Number(ItemDiscout).toFixed(2),
                                        };

                                        const columnsData = [
                                            {
                                                title: "Items",
                                                dataIndex: "display_name",
                                                key: "display_name",
                                                width: "60%",
                                                render(text, record) {
                                                    let text2 = text.toString();

                                                    let newSpilitArray = text2.split(/[+]/);
                                                    let newSpilitArray1 = text2.split(/[,]/);

                                                    let finalArray = [];
                                                    newSpilitArray.map((value) => {
                                                        finalArray.push(value.replace(/,/gi, ""));
                                                    });

                                                    return {
                                                        children: (
                                                            <>
                                                                {text2.includes("-") ? (
                                                                    newSpilitArray1.map((val) => <div>{val}</div>)
                                                                ) : (
                                                                    <div>
                                                                        {" "}
                                                                        {finalArray.length > 1 ? (
                                                                            <div>
                                                                                {finalArray.map((value, index) => {
                                                                                    return (
                                                                                        <div>
                                                                                            {index > 0 ? "+" : null}
                                                                                            {value}
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </div>
                                                                        ) : (
                                                                            <div>{text}</div>
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </>
                                                        ),
                                                    };
                                                },
                                            },

                                            {
                                                title: "Quantity",
                                                dataIndex: "quantity",
                                                key: "quantity",
                                                render(text, record) {
                                                    return {
                                                        props: {
                                                            style: { textAlign: "left" },
                                                        },
                                                        children: <span className="product-quantity">{text}</span>,
                                                    };
                                                },
                                            },
                                            {
                                                title: "Tax %",
                                                dataIndex: "taxes",
                                                key: "taxes",
                                            },
                                            {
                                                title: "Price",
                                                dataIndex: "price",
                                                key: "price",
                                                render(text, record) {
                                                    return {
                                                        children: (
                                                            <span className="product-unit">
                                                                {rsSymbol}
                                                                {Number(text).toFixed(2)}
                                                            </span>
                                                        ),
                                                    };
                                                },
                                            },
                                            checkCustomDiscount
                                                ? {
                                                      title: "Discount",
                                                      align: "left",
                                                      render(text, record, index) {
                                                          return {
                                                              children: (
                                                                  <div>
                                                                      {text.discountedValue || (text.customDiscountedValue && text.quantity > 0)
                                                                          ? `${rsSymbol}${text.discountedValue || text.customDiscountedValue} `
                                                                          : ""}
                                                                  </div>
                                                              ),
                                                          };
                                                      },
                                                  }
                                                : {},
                                        ];

                                        return (
                                            <>
                                                <Main>
                                                    <PageHeader
                                                        ghost
                                                        className="custome-status-header"
                                                        title={
                                                            <>
                                                                <span>
                                                                    Status &nbsp;
                                                                    {RecepitsData && (
                                                                        <>
                                                                            {RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                                                <Tag color="#43ac6a">Paid</Tag>
                                                                            ) : (
                                                                                <Tag color="#e99002">Unpaid</Tag>
                                                                            )}
                                                                            {RecepitsData.order_id.details.fulfillmentStatus == "Fulfilled" &&
                                                                            deletebuttonShow == false ? (
                                                                                <Tag color="#008cba">Fulfilled</Tag>
                                                                            ) : RecepitsData.order_id.details.fulfillmentStatus == "Unfulfilled" &&
                                                                              deletebuttonShow == false ? (
                                                                                <Tag color="darkgray">Unfulfilled</Tag>
                                                                            ) : (
                                                                                <Tag color="#f04124">Cancelled</Tag>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </span>
                                                                {RecepitsData.order_id.cancellation?.cancellation_reason && (
                                                                    <p>Cancellation Notes - {RecepitsData.order_id.cancellation.cancellation_reason}</p>
                                                                )}
                                                            </>
                                                        }
                                                        buttons={[
                                                            <div key="1" className="page-header-actions">
                                                                <Button
                                                                    shape="round"
                                                                    type="default"
                                                                    onClick={() => {
                                                                        window.frames[
                                                                            "print_frame"
                                                                        ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                            <ReceiptPrint
                                                                                title="DUPLICATE"
                                                                                receiptsDetails={RecepitsData.order_id}
                                                                                shopDetails={shopDetails}
                                                                                registerData={registerdata}
                                                                                ReceiptNumber={
                                                                                    RecepitsData?.order_id?.details?.receipt_number
                                                                                        ? RecepitsData?.order_id?.details?.receipt_number
                                                                                        : undefined
                                                                                }
                                                                            />
                                                                        );
                                                                        window.frames["print_frame"].window.focus();
                                                                        window.frames["print_frame"].window.print();
                                                                    }}
                                                                >
                                                                    <FeatherIcon icon="printer" size={14} />
                                                                    Print
                                                                </Button>
                                                                <Button shape="round" type="primary" onClick={() => history.push(`/receipts`)}>
                                                                    Go Back
                                                                </Button>

                                                                {deletebuttonShow ? (
                                                                    <Button shape="round" type="primary" onClick={() => setDeleteReceiptsModalVisible(true)}>
                                                                        Delete
                                                                    </Button>
                                                                ) : (
                                                                    (userDetails.role == "restaurant" ||
                                                                        (userDetails.role == "cashier" &&
                                                                            userDetails.has_manager_permission &&
                                                                            !getItem("do_not_allow_manager_to_receipt_cancel"))) && (
                                                                        <Button shape="round" type="primary" onClick={() => setModalVisibleOrderCancel(true)}>
                                                                            Cancel
                                                                        </Button>
                                                                    )
                                                                )}
                                                            </div>,
                                                        ]}
                                                    />

                                                    <Row gutter={15}>
                                                        <Col md={24}>
                                                            <Cards headless>
                                                                <InvoiceLetterBox>
                                                                    <div className="invoice-letter-inner">
                                                                        <Row align="top">
                                                                            <Col lg={10} xs={24}>
                                                                                <article className="invoice-author">
                                                                                    <Heading className="invoice-author__title" as="h3">
                                                                                        Invoice
                                                                                    </Heading>
                                                                                    <p>No : #{RecepitsData.receipt_number}</p>
                                                                                    <p className="color-5">
                                                                                        Prepared by {RecepitsData.order_id.details.order_by_name.username}{" "}
                                                                                        {RecepitsData.order_id.details.tableName != undefined &&
                                                                                            ` | ${RecepitsData.order_id.details.tableName}`}
                                                                                    </p>
                                                                                    <p>
                                                                                        on{" "}
                                                                                        {RecepitsData?.order_id?.actual_time
                                                                                            ? commonFunction.convertToDate(
                                                                                                  RecepitsData.order_id.actual_time,
                                                                                                  "MMM DD, Y h:mm A"
                                                                                              )
                                                                                            : commonFunction.convertToDate(
                                                                                                  RecepitsData.created_at,
                                                                                                  "MMM DD, Y h:mm A"
                                                                                              )}
                                                                                    </p>
                                                                                    <p className="color-5">
                                                                                        {`${RecepitsData.register_id.register_name} Register`}
                                                                                    </p>
                                                                                </article>
                                                                            </Col>

                                                                            {RecepitsData.order_id.customer.name ||
                                                                            RecepitsData.order_id.customer.email != "" ||
                                                                            RecepitsData.order_id.customer.mobile != null ||
                                                                            RecepitsData.order_id.customer.city ||
                                                                            RecepitsData.order_id.customer.shipping_address ||
                                                                            RecepitsData.order_id.customer.zipcode ||
                                                                            RecepitsData?.order_id.details.customer_custom_fields?.length > 0 ||
                                                                            RecepitsData.order_id.details.custom_fields.length > 0 ? (
                                                                                <Col lg={14} xs={24}>
                                                                                    <address className="invoice-customer">
                                                                                        <Heading className="invoice-customer__title" as="h5">
                                                                                            Invoice To:
                                                                                        </Heading>

                                                                                        {RecepitsData.order_id.customer?.name ||
                                                                                        RecepitsData.order_id.customer?.email ||
                                                                                        RecepitsData.order_id.customer?.mobile ? (
                                                                                            <p>
                                                                                                {" "}
                                                                                                {RecepitsData.order_id.customer?.name
                                                                                                    ? RecepitsData.order_id.customer?.mobile ||
                                                                                                      RecepitsData.order_id.customer?.email
                                                                                                        ? `${RecepitsData.order_id.customer?.name} | `
                                                                                                        : RecepitsData.order_id.customer?.name
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer?.mobile
                                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                                        ? `${RecepitsData.order_id.customer?.mobile} | `
                                                                                                        : RecepitsData.order_id.customer?.mobile
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer?.email
                                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                                    : null}{" "}
                                                                                                {RecepitsData.order_id.customer?.shipping_address ? (
                                                                                                    <br />
                                                                                                ) : null}
                                                                                                {RecepitsData.order_id.customer?.shipping_address
                                                                                                    ? RecepitsData.order_id.customer?.city ||
                                                                                                      RecepitsData.order_id.customer?.zipcode
                                                                                                        ? `${RecepitsData.order_id.customer?.shipping_address} `
                                                                                                        : RecepitsData.order_id.customer?.shipping_address
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer.city ? <br /> : null}
                                                                                                {RecepitsData.order_id.customer?.city
                                                                                                    ? RecepitsData.order_id.customer?.zipcode
                                                                                                        ? `${RecepitsData.order_id.customer?.city}`
                                                                                                        : RecepitsData.order_id.customer?.city
                                                                                                    : null}
                                                                                                {RecepitsData.order_id.customer?.zipcode
                                                                                                    ? ` - ${RecepitsData.order_id.customer?.zipcode}`
                                                                                                    : null}{" "}
                                                                                            </p>
                                                                                        ) : null}

                                                                                        <p className="color-5">
                                                                                            {RecepitsData?.order_id.details.customer_custom_fields?.map(
                                                                                                (data, index) => {
                                                                                                    if (
                                                                                                        RecepitsData.order_id.details.customer_custom_fields
                                                                                                            .length == 1 &&
                                                                                                        data.value != ""
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {data.name}
                                                                                                                {" : "}
                                                                                                                <span>{data.value}</span>
                                                                                                            </>
                                                                                                        );
                                                                                                    } else if (
                                                                                                        index + 1 ==
                                                                                                            RecepitsData.order_id.details.customer_custom_fields
                                                                                                                .length &&
                                                                                                        data.value != ""
                                                                                                    ) {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {data.name}
                                                                                                                {" : "}
                                                                                                                <span>{data.value}</span>
                                                                                                            </>
                                                                                                        );
                                                                                                    } else if (data.value != "") {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {data.name}
                                                                                                                {" : "}
                                                                                                                <span>{data.value}</span> {" | "}
                                                                                                            </>
                                                                                                        );
                                                                                                    }
                                                                                                }
                                                                                            )}
                                                                                        </p>
                                                                                        <p className="color-5">
                                                                                            {RecepitsData.order_id.details.custom_fields.map((val) => (
                                                                                                <Tag color={val.tag_color}>{val.name}</Tag>
                                                                                            ))}
                                                                                        </p>
                                                                                    </address>
                                                                                </Col>
                                                                            ) : null}
                                                                        </Row>
                                                                    </div>
                                                                </InvoiceLetterBox>
                                                                <Modal
                                                                    title="Confirm Delete"
                                                                    visible={deleteReceiptsModalVisible}
                                                                    onCancel={() => setDeleteReceiptsModalVisible(false)}
                                                                    cancelText="Go Back"
                                                                    onOk={deleteReceipts}
                                                                    okText={
                                                                        loading ? (
                                                                            <Spin
                                                                                indicator={
                                                                                    <LoadingOutlined
                                                                                        style={{
                                                                                            fontSize: 16,
                                                                                            color: "white",
                                                                                            margin: "0px 14px",
                                                                                        }}
                                                                                        spin
                                                                                    />
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            "Delete Receipt"
                                                                        )
                                                                    }
                                                                >
                                                                    <p>
                                                                        Deleting the receipt will permanently remove it and will no longer appear on reports.
                                                                        Also, deleting the receipt will keep the metrics as they were after cancellation. Are
                                                                        you sure you want to proceed?
                                                                    </p>
                                                                </Modal>
                                                                <Modal
                                                                    title="Confirm Cancelled."
                                                                    visible={modalVisibleConfirmCancel}
                                                                    footer={[
                                                                        <Button type="primary" onClick={() => setModalVisibleConfirmCancel(false)}>
                                                                            Ok
                                                                        </Button>,
                                                                    ]}
                                                                >
                                                                    <p>Receipt has been cancelled.</p>
                                                                </Modal>
                                                                <Modal
                                                                    title="Confirm Cancel"
                                                                    bodyStyle={{
                                                                        paddingTop: 0,
                                                                        paddingBottom: "12px",
                                                                    }}
                                                                    visible={modalVisibleOrderCancel}
                                                                    onCancel={() => {
                                                                        setModalVisibleOrderCancel(false);
                                                                        form.setFieldsValue({
                                                                            refund_amount: refundAmount,
                                                                        });
                                                                    }}
                                                                    width={600}
                                                                    footer={[
                                                                        <Button
                                                                            type="default"
                                                                            className="btn-cancel btn-custom go_back"
                                                                            onClick={() => {
                                                                                setModalVisibleOrderCancel(false);
                                                                                form.setFieldsValue({
                                                                                    refund_amount: refundAmount,
                                                                                });
                                                                            }}
                                                                        >
                                                                            Go Back
                                                                        </Button>,
                                                                        <Button
                                                                            type="primary"
                                                                            disabled={PaymentType || refundAmount == 0 ? false : true}
                                                                            onClick={() => onSubmit()}
                                                                        >
                                                                            {loading ? (
                                                                                <Spin
                                                                                    indicator={
                                                                                        <LoadingOutlined
                                                                                            style={{
                                                                                                fontSize: 16,
                                                                                                color: "white",
                                                                                                margin: "0px 14px",
                                                                                            }}
                                                                                            spin
                                                                                        />
                                                                                    }
                                                                                />
                                                                            ) : (
                                                                                "Cancel Receipt"
                                                                            )}
                                                                        </Button>,
                                                                    ]}
                                                                >
                                                                    <Form style={{ width: "100%" }} name="Export" form={form} onFinish={onSubmit}>
                                                                        <Form.Item
                                                                            name="refund_amount"
                                                                            label="Enter Refund Amount"
                                                                            rules={[
                                                                                {
                                                                                    validator: (_, value) => {
                                                                                        if (Number(value) >= 0) {
                                                                                            return Promise.resolve();
                                                                                        } else {
                                                                                            return Promise.reject("Refund price should be a positive number.");
                                                                                        }
                                                                                    },
                                                                                },
                                                                                {
                                                                                    validator: (_, value) => {
                                                                                        if (Number(value) > refundAmount) {
                                                                                            return Promise.reject(
                                                                                                "Refund amount cannot be more than the paid amount."
                                                                                            );
                                                                                        } else {
                                                                                            return Promise.resolve();
                                                                                        }
                                                                                    },
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Refund Amount" type="number" />
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name="refund_pay_type"
                                                                            label="Payment Type"
                                                                            rules={[
                                                                                {
                                                                                    message: "Choose a payment type to proceed",
                                                                                    required: refundAmount > 0 ? true : false,
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Radio.Group
                                                                                onChange={(e) => setPaymentType(e.target.value)}
                                                                                value={PaymentType}
                                                                                className="tick-radio"
                                                                            >
                                                                                <Radio.Button
                                                                                    value="cash"
                                                                                    style={{
                                                                                        marginRight: "10px",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    {PaymentType === "cash" ? (
                                                                                        <svg
                                                                                            width="13px"
                                                                                            style={{ marginRight: "2px" }}
                                                                                            viewBox="0 0 123 102"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                fill="#BE3D5D"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    Cash
                                                                                </Radio.Button>
                                                                                <Radio.Button
                                                                                    value="card"
                                                                                    style={{
                                                                                        marginRight: "10px",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    {PaymentType === "card" ? (
                                                                                        <svg
                                                                                            width="13px"
                                                                                            style={{ marginRight: "2px" }}
                                                                                            viewBox="0 0 123 102"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                fill="#BE3D5D"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}{" "}
                                                                                    Credit / Debit Card
                                                                                </Radio.Button>
                                                                                {PaymentTypeList.map((val, index) => {
                                                                                    return (
                                                                                        <Radio.Button
                                                                                            value={val.name}
                                                                                            style={{
                                                                                                marginRight: "10px",
                                                                                                marginBottom: "10px",
                                                                                            }}
                                                                                        >
                                                                                            {PaymentType === val.name ? (
                                                                                                <svg
                                                                                                    width="13px"
                                                                                                    style={{
                                                                                                        marginRight: "2px",
                                                                                                    }}
                                                                                                    viewBox="0 0 123 102"
                                                                                                    fill="none"
                                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                                >
                                                                                                    <path
                                                                                                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                        fill="#BE3D5D"
                                                                                                    />
                                                                                                </svg>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                            {val.name}
                                                                                        </Radio.Button>
                                                                                    );
                                                                                })}
                                                                                <Radio.Button
                                                                                    value="other"
                                                                                    style={{
                                                                                        marginRight: "10px",
                                                                                        marginBottom: "10px",
                                                                                    }}
                                                                                >
                                                                                    {PaymentType === "other" ? (
                                                                                        <svg
                                                                                            width="13px"
                                                                                            style={{ marginRight: "2px" }}
                                                                                            viewBox="0 0 123 102"
                                                                                            fill="none"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                        >
                                                                                            <path
                                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                                fill="#BE3D5D"
                                                                                            />
                                                                                        </svg>
                                                                                    ) : (
                                                                                        ""
                                                                                    )}
                                                                                    Other
                                                                                </Radio.Button>
                                                                            </Radio.Group>
                                                                        </Form.Item>
                                                                        <Form.Item
                                                                            name="cancellation_reason"
                                                                            label="Cancellation Notes4"
                                                                            rules={[
                                                                                {
                                                                                    max: 50,
                                                                                    message: "Cancellation Notes cannot be more than 50 characters long.",
                                                                                },
                                                                            ]}
                                                                        >
                                                                            <Input placeholder="Cancellation notes" />
                                                                        </Form.Item>
                                                                    </Form>
                                                                </Modal>
                                                                <br />
                                                                <ProductTable>
                                                                    <div className="table-invoice table-responsive">
                                                                        <Table
                                                                            dataSource={tableData}
                                                                            columns={columnsData}
                                                                            pagination={false}
                                                                            rowClassName="invoice-table"
                                                                        />
                                                                    </div>
                                                                </ProductTable>
                                                                <Row justify="end" style={{ paddingRight: "17px" }}>
                                                                    <Col xxl={4} xl={5} sm={8} xs={14} offset={rtl ? 0 : 10}>
                                                                        <OrderSummary>
                                                                            <div className="invoice-summary-inner">
                                                                                <ul className="summary-list">
                                                                                    <li>
                                                                                        <span className="summary-list-title">Subtotal :</span>
                                                                                        <span className="summary-list-text">
                                                                                            {`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.sub_total}`}
                                                                                        </span>
                                                                                    </li>
                                                                                    {RecepitsData?.order_id.details.priceSummery.taxexArray &&
                                                                                        RecepitsData?.order_id.details.priceSummery.taxexArray.map((val) => {
                                                                                            return (
                                                                                                <li>
                                                                                                    <span className="summary-list-title">{val.name} :</span>
                                                                                                    <span className="summary-list-text">{`${rsSymbol}${Number(
                                                                                                        val.value
                                                                                                    ).toFixed(2)}`}</span>
                                                                                                </li>
                                                                                            );
                                                                                        })}
                                                                                    {RecepitsData?.order_id.details.priceSummery.round_off_value && (
                                                                                        <li>
                                                                                            <span className="summary-list-title">Roundoff :</span>
                                                                                            <span className="summary-list-text">{`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.round_off_value}`}</span>
                                                                                        </li>
                                                                                    )}
                                                                                </ul>
                                                                                <Heading className="summary-total" as="h4">
                                                                                    <span className="summary-total-label">Total : </span>
                                                                                    <span className="summary-total-amount">{`${rsSymbol}${Number(
                                                                                        data.value
                                                                                    ).toFixed(2)}`}</span>
                                                                                </Heading>
                                                                                <Heading className="summary-total" as="h4">
                                                                                    <span className="summary-total-label">
                                                                                        For {data.name != "" ? data.name : `Customer ${data.no}`}{" "}
                                                                                    </span>
                                                                                    <span className="summary-total-amount">{`${rsSymbol}${Number(
                                                                                        data.value
                                                                                    ).toFixed(2)}`}</span>
                                                                                </Heading>
                                                                            </div>
                                                                        </OrderSummary>
                                                                    </Col>
                                                                    <Col></Col>
                                                                </Row>

                                                                {RecepitsData && RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                                    <div className="border-top">
                                                                        {RecepitsData.order_id.details.orderTicketsData ? (
                                                                            <Row>
                                                                                Order Tickets :
                                                                                {RecepitsData.order_id.details.orderTicketsData.map((i, index) => {
                                                                                    let privewsOrderTiket = [];
                                                                                    RecepitsData.order_id.details.orderTicketsData
                                                                                        .slice(0, index)
                                                                                        .map((val) => privewsOrderTiket.push(val.tiketNumber));
                                                                                    return (
                                                                                        <div>
                                                                                            <span>
                                                                                                <span
                                                                                                    style={{
                                                                                                        color: "rgb(0, 140, 186)",
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        window.frames[
                                                                                                            "print_frame"
                                                                                                        ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                                                            <OrderTicketPrint
                                                                                                                categoryDetails={i}
                                                                                                                PreviousTikets={privewsOrderTiket}
                                                                                                                ReceiptNumber={i.receiptNumberDetails?.number}
                                                                                                                TableName={i?.table_name ? i?.table_name : ""}
                                                                                                            />
                                                                                                        );
                                                                                                        window.frames["print_frame"].window.focus();
                                                                                                        window.frames["print_frame"].window.print();
                                                                                                    }}
                                                                                                >
                                                                                                    {index ==
                                                                                                    RecepitsData.order_id.details.orderTicketsData.length - 1
                                                                                                        ? `#${i.tiketNumber}`
                                                                                                        : `#${i.tiketNumber},`}
                                                                                                </span>
                                                                                            </span>
                                                                                        </div>
                                                                                    );
                                                                                })}
                                                                            </Row>
                                                                        ) : null}
                                                                        <Row>
                                                                            {data.payment_type_list.map((val) => {
                                                                                if (val.tick == true) {
                                                                                    return (
                                                                                        <>
                                                                                            <Col lg={6} md={18} sm={24} offset={0}>
                                                                                                <div className="receipt-payment-transactions">
                                                                                                    <p>{`${rsSymbol}${data.value} on ${val.name}`}</p>
                                                                                                    <p className="text-muted">
                                                                                                        {commonFunction.convertToDate(
                                                                                                            val.paymentDate
                                                                                                                ? val.paymentDate
                                                                                                                : RecepitsData.created_at,
                                                                                                            "MMM DD, Y h:mm A"
                                                                                                        )}
                                                                                                    </p>
                                                                                                </div>
                                                                                            </Col>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })}
                                                                            {RecepitsData && RecepitsData.order_id.cancellation && indx == 0 && (
                                                                                <Col lg={6} md={18} sm={24} offset={0}>
                                                                                    <div className="receipt-payment-transactions">
                                                                                        <p>{`${rsSymbol}${RecepitsData.order_id.cancellation.refund_amount} ${RecepitsData.order_id.cancellation.refund_pay_type} refund`}</p>
                                                                                        <p className="text-muted">
                                                                                            {commonFunction.convertToDate(
                                                                                                RecepitsData.order_id.cancellation.cancel_Date,
                                                                                                "MMM DD, Y h:mm A"
                                                                                            )}
                                                                                        </p>
                                                                                    </div>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div style={{ display: "none" }}>{PaymentTypeList.length}</div>
                                                                        <UnPaidReceipts
                                                                            deletebuttonShow={deletebuttonShow}
                                                                            RecepitsDataDetails={RecepitsData}
                                                                            PaymentTypeList={PaymentTypeList}
                                                                            updateFetch={fetchRecepitsData}
                                                                        />
                                                                    </>
                                                                )}
                                                            </Cards>
                                                        </Col>
                                                    </Row>
                                                </Main>
                                            </>
                                        );
                                    }
                                })
                            ) : (
                                <Main className="receipts_inv">
                                    <PageHeader
                                        ghost
                                        className="custome-status-header"
                                        title={
                                            <>
                                                <span>
                                                    Status &nbsp;
                                                    {RecepitsData && (
                                                        <>
                                                            {RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                                <Tag color="#43ac6a">Paid</Tag>
                                                            ) : (
                                                                <Tag color="#e99002">Unpaid</Tag>
                                                            )}
                                                            {RecepitsData.order_id.details.fulfillmentStatus == "Fulfilled" && deletebuttonShow == false ? (
                                                                <Tag color="#008cba">Fulfilled</Tag>
                                                            ) : RecepitsData.order_id.details.fulfillmentStatus == "Unfulfilled" &&
                                                              deletebuttonShow == false ? (
                                                                <Tag color="darkgray">Unfulfilled</Tag>
                                                            ) : (
                                                                <Tag color="#f04124">Cancelled</Tag>
                                                            )}
                                                        </>
                                                    )}
                                                </span>
                                                {RecepitsData.order_id.cancellation?.cancellation_reason && (
                                                    <p>Cancellation Notes - {RecepitsData.order_id.cancellation.cancellation_reason}</p>
                                                )}
                                            </>
                                        }
                                        buttons={[
                                            <div key="1" className="page-header-actions">
                                                <Button
                                                    shape="round"
                                                    type="default"
                                                    onClick={() => {
                                                        window.frames["print_frame"].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                            <ReceiptPrint
                                                                title="DUPLICATE"
                                                                onlineOrder={
                                                                    RecepitsData?.order_id?.details?.onlineOrder
                                                                        ? {
                                                                              source: RecepitsData.order_id.details?.onlineOrder.Source,
                                                                              orderId: RecepitsData.order_id.details?.onlineOrder.order_id,
                                                                          }
                                                                        : undefined
                                                                }
                                                                receiptsDetails={RecepitsData.order_id}
                                                                shopDetails={shopDetails}
                                                                registerData={registerdata}
                                                                ReceiptNumber={
                                                                    RecepitsData?.order_id?.details?.receipt_number
                                                                        ? RecepitsData?.order_id?.details?.receipt_number
                                                                        : undefined
                                                                }
                                                            />
                                                        );
                                                        window.frames["print_frame"].window.focus();
                                                        window.frames["print_frame"].window.print();
                                                    }}
                                                >
                                                    <FeatherIcon icon="printer" size={14} style={{ position: "relative", top: "3px" }} />
                                                    Print
                                                </Button>
                                                <Popover
                                                    content={content}
                                                    title=""
                                                    trigger="click"
                                                    visible={emailVisible}
                                                    onVisibleChange={(visible) => setEmailVisible(visible)}
                                                >
                                                    <Button shape="round" type="default" onClick={() => emailDataSet()}>
                                                        <WhatsAppOutlined />
                                                        Send whatsapp
                                                    </Button>
                                                </Popover>

                                                <Button shape="round" type="primary" onClick={() => history.push(`/receipts`)}>
                                                    Go Back
                                                </Button>

                                                {deletebuttonShow ? (
                                                    <Button shape="round" type="primary" onClick={() => setDeleteReceiptsModalVisible(true)}>
                                                        Delete
                                                    </Button>
                                                ) : (
                                                    (userDetails.role == "restaurant" ||
                                                        (userDetails.role == "cashier" &&
                                                            userDetails.has_manager_permission &&
                                                            !getItem("do_not_allow_manager_to_receipt_cancel"))) && (
                                                        <Button shape="round" type="primary" onClick={() => setModalVisibleOrderCancel(true)}>
                                                            Cancel
                                                        </Button>
                                                    )
                                                )}
                                            </div>,
                                        ]}
                                    />

                                    <Row gutter={15}>
                                        <Col md={24}>
                                            <Cards headless>
                                                <InvoiceLetterBox>
                                                    <div className="invoice-letter-inner">
                                                        <Row align="top">
                                                            <Col lg={10} xs={24}>
                                                                <article className="invoice-author">
                                                                    <Heading className="invoice-author__title" as="h3">
                                                                        Invoice
                                                                    </Heading>
                                                                    <p>No : #{RecepitsData.receipt_number}</p>
                                                                    {RecepitsData?.order_id?.details?.onlineOrder?.Source ? (
                                                                        <p>
                                                                            #{RecepitsData?.order_id?.details?.onlineOrder?.order_id} -{" "}
                                                                            {RecepitsData?.order_id?.details?.onlineOrder?.Source}
                                                                        </p>
                                                                    ) : null}

                                                                    <p className="color-5">
                                                                        Prepared by {RecepitsData.order_id.details.order_by_name.username}{" "}
                                                                        {RecepitsData.order_id.details.tableName != undefined &&
                                                                            ` | ${RecepitsData.order_id.details.tableName}`}
                                                                    </p>
                                                                    <p>
                                                                        on{" "}
                                                                        {RecepitsData?.order_id?.actual_time
                                                                            ? commonFunction.convertToDate(
                                                                                  RecepitsData.order_id.actual_time,
                                                                                  "MMM DD, Y h:mm A"
                                                                              )
                                                                            : commonFunction.convertToDate(RecepitsData.created_at, "MMM DD, Y h:mm A")}
                                                                    </p>
                                                                    <p className="color-5"> {`${RecepitsData.register_id.register_name} Register`}</p>
                                                                </article>
                                                            </Col>
                                                            {console.log("RecepitsData.order_id.customer", RecepitsData.order_id)}
                                                            {RecepitsData.order_id.customer.name ||
                                                            RecepitsData.order_id.customer.email != "" ||
                                                            RecepitsData.order_id.customer.mobile != null ||
                                                            RecepitsData.order_id.customer.city ||
                                                            RecepitsData.order_id.customer.shipping_address ||
                                                            RecepitsData.order_id.customer.zipcode ||
                                                            RecepitsData?.order_id.details.customer_custom_fields?.length > 0 ||
                                                            RecepitsData.order_id.details.custom_fields?.length > 0 ? (
                                                                <Col lg={14} xs={24}>
                                                                    <address className="invoice-customer">
                                                                        <Heading className="invoice-customer__title" as="h5">
                                                                            Invoice To:
                                                                        </Heading>

                                                                        {RecepitsData.order_id.customer?.name ||
                                                                        RecepitsData.order_id.customer?.email ||
                                                                        RecepitsData.order_id.customer?.mobile ? (
                                                                            <p>
                                                                                {" "}
                                                                                {RecepitsData.order_id.customer?.name
                                                                                    ? RecepitsData.order_id.customer?.mobile ||
                                                                                      RecepitsData.order_id.customer?.email
                                                                                        ? `${RecepitsData.order_id.customer?.name} | `
                                                                                        : RecepitsData.order_id.customer?.name
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer?.mobile
                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                        ? `${RecepitsData.order_id.customer?.mobile} | `
                                                                                        : RecepitsData.order_id.customer?.mobile
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer?.email
                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                    : null}{" "}
                                                                                {RecepitsData.order_id.customer?.shipping_address ? <br /> : null}
                                                                                {RecepitsData.order_id.customer?.shipping_address
                                                                                    ? RecepitsData.order_id.customer?.city ||
                                                                                      RecepitsData.order_id.customer?.zipcode
                                                                                        ? `${RecepitsData.order_id.customer?.shipping_address} `
                                                                                        : RecepitsData.order_id.customer?.shipping_address
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer.city ? <br /> : null}
                                                                                {RecepitsData.order_id.customer?.city
                                                                                    ? RecepitsData.order_id.customer?.zipcode
                                                                                        ? `${RecepitsData.order_id.customer?.city}`
                                                                                        : RecepitsData.order_id.customer?.city
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer?.zipcode
                                                                                    ? ` - ${RecepitsData.order_id.customer?.zipcode}`
                                                                                    : null}{" "}
                                                                            </p>
                                                                        ) : null}

                                                                        <p className="color-5">
                                                                            {RecepitsData?.order_id.details.customer_custom_fields?.map((data, index) => {
                                                                                if (
                                                                                    RecepitsData.order_id.details.customer_custom_fields.length == 1 &&
                                                                                    data.value != ""
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            {data.name}
                                                                                            {" : "}
                                                                                            <span>{data.value}</span>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    index + 1 == RecepitsData.order_id.details.customer_custom_fields.length &&
                                                                                    data.value != ""
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            {data.name}
                                                                                            {" : "}
                                                                                            <span>{data.value}</span>
                                                                                        </>
                                                                                    );
                                                                                } else if (data.value != "") {
                                                                                    return (
                                                                                        <>
                                                                                            {data.name}
                                                                                            {" : "}
                                                                                            <span>{data.value}</span> {" | "}
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </p>
                                                                        <p className="color-5">
                                                                            {RecepitsData.order_id.details.custom_fields?.map((val) => (
                                                                                <Tag color={val.tag_color}>{val.name}</Tag>
                                                                            ))}
                                                                        </p>
                                                                    </address>
                                                                </Col>
                                                            ) : null}
                                                        </Row>
                                                    </div>
                                                </InvoiceLetterBox>

                                                <Modal
                                                    title="Confirm Delete"
                                                    visible={deleteReceiptsModalVisible}
                                                    onCancel={() => setDeleteReceiptsModalVisible(false)}
                                                    cancelText="Go Back"
                                                    onOk={deleteReceipts}
                                                    okText={
                                                        loading ? (
                                                            <Spin
                                                                indicator={
                                                                    <LoadingOutlined
                                                                        style={{
                                                                            fontSize: 16,
                                                                            color: "white",
                                                                            margin: "0px 14px",
                                                                        }}
                                                                        spin
                                                                    />
                                                                }
                                                            />
                                                        ) : (
                                                            "Delete Receipt"
                                                        )
                                                    }
                                                >
                                                    <p>
                                                        Deleting the receipt will permanently remove it and will no longer appear on reports. Also, deleting the
                                                        receipt will keep the metrics as they were after cancellation. Are you sure you want to proceed?
                                                    </p>
                                                </Modal>
                                                <Modal
                                                    title="Confirm Cancelled."
                                                    visible={modalVisibleConfirmCancel}
                                                    footer={[
                                                        <Button type="primary" onClick={() => setModalVisibleConfirmCancel(false)}>
                                                            Ok
                                                        </Button>,
                                                    ]}
                                                >
                                                    <p>Receipt has been cancelled.</p>
                                                </Modal>
                                                <Modal
                                                    title="Confirm Cancel"
                                                    className="remove-border"
                                                    bodyStyle={{ paddingTop: 0, paddingBottom: "12px" }}
                                                    visible={modalVisibleOrderCancel}
                                                    onCancel={() => {
                                                        setModalVisibleOrderCancel(false);
                                                        form.setFieldsValue({
                                                            refund_amount: refundAmount,
                                                        });
                                                    }}
                                                    width={600}
                                                    footer={[
                                                        <Button
                                                            type="default"
                                                            className="btn-cancel btn-custom go_back"
                                                            onClick={() => {
                                                                setModalVisibleOrderCancel(false);
                                                                form.setFieldsValue({
                                                                    refund_amount: refundAmount,
                                                                });
                                                            }}
                                                        >
                                                            Go Back
                                                        </Button>,
                                                        <Button
                                                            type="primary"
                                                            disabled={PaymentType || refundAmount == 0 ? false : true}
                                                            onClick={() => onSubmit()}
                                                        >
                                                            {loading ? (
                                                                <Spin
                                                                    indicator={
                                                                        <LoadingOutlined
                                                                            style={{
                                                                                fontSize: 16,
                                                                                color: "white",
                                                                                margin: "0px 14px",
                                                                            }}
                                                                            spin
                                                                        />
                                                                    }
                                                                />
                                                            ) : (
                                                                "Cancel Receipt"
                                                            )}
                                                        </Button>,
                                                    ]}
                                                >
                                                    <Form style={{ width: "100%" }} name="Export" form={form} onFinish={onSubmit}>
                                                        <Form.Item
                                                            name="refund_amount"
                                                            label="Enter Refund Amount"
                                                            rules={[
                                                                {
                                                                    validator: (_, value) => {
                                                                        if (Number(value) >= 0) {
                                                                            return Promise.resolve();
                                                                        } else {
                                                                            return Promise.reject("Refund price should be a positive number.");
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    validator: (_, value) => {
                                                                        if (Number(value) > refundAmount) {
                                                                            return Promise.reject("Refund amount cannot be more than the paid amount.");
                                                                        } else {
                                                                            return Promise.resolve();
                                                                        }
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Refund Amount" type="number" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="refund_pay_type"
                                                            label="Payment Type"
                                                            rules={[
                                                                {
                                                                    message: "Choose a payment type to proceed",
                                                                    required: refundAmount > 0 ? true : false,
                                                                },
                                                            ]}
                                                        >
                                                            <Radio.Group
                                                                onChange={(e) => setPaymentType(e.target.value)}
                                                                value={PaymentType}
                                                                className="tick-radio"
                                                            >
                                                                <Radio.Button
                                                                    value="cash"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {PaymentType === "cash" ? (
                                                                        <svg
                                                                            width="13px"
                                                                            style={{ marginRight: "2px" }}
                                                                            viewBox="0 0 123 102"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                fill="#BE3D5D"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    Cash
                                                                </Radio.Button>
                                                                <Radio.Button
                                                                    value="card"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {PaymentType === "card" ? (
                                                                        <svg
                                                                            width="13px"
                                                                            style={{ marginRight: "2px" }}
                                                                            viewBox="0 0 123 102"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                fill="#BE3D5D"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        ""
                                                                    )}{" "}
                                                                    Credit / Debit Card
                                                                </Radio.Button>
                                                                {PaymentTypeList.map((val, index) => {
                                                                    return (
                                                                        <Radio.Button
                                                                            value={val.name}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                                marginBottom: "10px",
                                                                            }}
                                                                        >
                                                                            {PaymentType === val.name ? (
                                                                                <svg
                                                                                    width="13px"
                                                                                    style={{ marginRight: "2px" }}
                                                                                    viewBox="0 0 123 102"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                        fill="#BE3D5D"
                                                                                    />
                                                                                </svg>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {val.name}
                                                                        </Radio.Button>
                                                                    );
                                                                })}
                                                                <Radio.Button
                                                                    value="other"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {PaymentType === "other" ? (
                                                                        <svg
                                                                            width="13px"
                                                                            style={{ marginRight: "2px" }}
                                                                            viewBox="0 0 123 102"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                fill="#BE3D5D"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    Other
                                                                </Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="cancellation_reason"
                                                            label="Cancellation Notes"
                                                            rules={[
                                                                {
                                                                    max: 50,
                                                                    message: "Cancellation Notes cannot be more than 50 characters long.",
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Cancellation notes" />
                                                        </Form.Item>
                                                    </Form>
                                                </Modal>
                                                <br />
                                                {RecepitsData.order_id.details.priceSummery?.orderCacel ? (
                                                    <>
                                                        <Row style={{ textAlign: "center" }}>
                                                            <p>All items were removed.</p>
                                                        </Row>

                                                        <Row>
                                                            Order Tickets :
                                                            {RecepitsData.order_id.details.orderTicketsData?.map((i, index) => {
                                                                let privewsOrderTiket = [];
                                                                RecepitsData.order_id.details.orderTicketsData
                                                                    .slice(0, index)
                                                                    .map((val) => privewsOrderTiket.push(val.tiketNumber));

                                                                return (
                                                                    <div>
                                                                        <span>
                                                                            <span
                                                                                style={{
                                                                                    color: "rgb(0, 140, 186)",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    window.frames[
                                                                                        "print_frame"
                                                                                    ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                                        <OrderTicketPrint
                                                                                            categoryDetails={i}
                                                                                            PreviousTikets={privewsOrderTiket}
                                                                                            ReceiptNumber={i.receiptNumberDetails?.number}
                                                                                            TableName={i?.table_name ? i?.table_name : ""}
                                                                                        />
                                                                                    );
                                                                                    window.frames["print_frame"].window.focus();
                                                                                    window.frames["print_frame"].window.print();
                                                                                }}
                                                                            >
                                                                                {index == RecepitsData.order_id.details.orderTicketsData.length - 1
                                                                                    ? `#${i.tiketNumber}`
                                                                                    : `#${i.tiketNumber},`}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <div>
                                                        <ProductTable>
                                                            <div className="table-invoice table-responsive">
                                                                <Table
                                                                    dataSource={dataSource}
                                                                    columns={invoiceTableColumns}
                                                                    pagination={false}
                                                                    rowClassName="invoice-table"
                                                                />
                                                            </div>
                                                        </ProductTable>

                                                        <Row justify="end" style={{ paddingRight: "17px" }}>
                                                            <Col xxl={4} xl={5} sm={8} xs={14} offset={rtl ? 0 : 10}>
                                                                <OrderSummary>
                                                                    <div className="invoice-summary-inner">
                                                                        <ul className="summary-list">
                                                                            <li>
                                                                                <span className="summary-list-title">Subtotal :</span>

                                                                                <span className="summary-list-text">
                                                                                    {`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.sub_total}`}
                                                                                </span>
                                                                            </li>
                                                                            {RecepitsData?.order_id.details.priceSummery.totalItemDisocunts > 0 && (
                                                                                <li>
                                                                                    <span className="summary-list-title">Item Discounts :</span>
                                                                                    <span className="summary-list-text">
                                                                                        {`-${rsSymbol}${RecepitsData?.order_id.details.priceSummery.totalItemDisocunts}`}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                            {console.log("RecepitsData212122121", RecepitsData?.order_id?.details)}
                                                                            {RecepitsData?.order_id?.details?.bulckDiscountValue && (
                                                                                <li>
                                                                                    <span className="summary-list-title">
                                                                                        Bulk Discount{" "}
                                                                                        {RecepitsData?.order_id?.details?.bingageDetails && (
                                                                                            <Tooltip title={<div>Bingage wallet</div>}>
                                                                                                <ExclamationCircleOutlined
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </span>

                                                                                    <span className="summary-list-text">
                                                                                        {`-${rsSymbol}${RecepitsData?.order_id?.details?.bulckDiscountValue}`}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                            {RecepitsData?.order_id?.details?.AddtionChargeValue &&
                                                                                RecepitsData?.order_id?.details?.AddtionChargeValue.map(
                                                                                    (charge) =>
                                                                                        charge.is_automatically_added && (
                                                                                            <li>
                                                                                                <span className="summary-list-title">
                                                                                                    {charge.charge_name}{" "}
                                                                                                    {charge.tax_group &&
                                                                                                        `(Tax ${charge.tax_group.Totaltax}%) :`}
                                                                                                </span>

                                                                                                <span className="summary-list-text">
                                                                                                    {rsSymbol}
                                                                                                    {Number(charge.AddtionalCalculatedValue).toFixed(2)}
                                                                                                </span>
                                                                                            </li>
                                                                                        )
                                                                                )}

                                                                            {RecepitsData?.order_id.details.priceSummery.taxexArray &&
                                                                                RecepitsData?.order_id.details.priceSummery.taxexArray.map((val) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <span className="summary-list-title">{val.name} :</span>
                                                                                            <span className="summary-list-text">{`${rsSymbol}${Number(
                                                                                                val.value
                                                                                            ).toFixed(2)}`}</span>
                                                                                        </li>
                                                                                    );
                                                                                })}
                                                                            {RecepitsData?.order_id.details.priceSummery.round_off_value && (
                                                                                <li>
                                                                                    <span className="summary-list-title">Roundoff :</span>
                                                                                    <span className="summary-list-text">{`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.round_off_value}`}</span>
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                        <Heading className="summary-total" as="h4">
                                                                            <span className="summary-total-label">Total : </span>
                                                                            <span className="summary-total-amount">{`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.total}`}</span>
                                                                        </Heading>
                                                                    </div>
                                                                </OrderSummary>
                                                            </Col>
                                                            <Col></Col>
                                                        </Row>

                                                        {RecepitsData && RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                            <div className="border-top">
                                                                {RecepitsData.order_id.details.orderTicketsData &&
                                                                RecepitsData.order_id.details.orderTicketsData ? (
                                                                    <Row>
                                                                        Order Tickets :
                                                                        {console.log("kmkmmmmk22222", RecepitsData.order_id.details.orderTicketsData)}
                                                                        {RecepitsData.order_id.details.orderTicketsData.map((i, index) => {
                                                                            let privewsOrderTiket = [];
                                                                            RecepitsData.order_id.details.orderTicketsData
                                                                                .slice(0, index)
                                                                                .map((val) => privewsOrderTiket.push(val.tiketNumber));

                                                                            return (
                                                                                <div>
                                                                                    <span>
                                                                                        <span
                                                                                            style={{
                                                                                                color: "rgb(0, 140, 186)",
                                                                                                cursor: "pointer",
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                window.frames[
                                                                                                    "print_frame"
                                                                                                ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                                                    <OrderTicketPrint
                                                                                                        categoryDetails={i}
                                                                                                        PreviousTikets={privewsOrderTiket}
                                                                                                        ReceiptNumber={i.receiptNumberDetails?.number}
                                                                                                        TableName={i?.table_name ? i?.table_name : ""}
                                                                                                    />
                                                                                                );
                                                                                                window.frames["print_frame"].window.focus();
                                                                                                window.frames["print_frame"].window.print();
                                                                                            }}
                                                                                        >
                                                                                            {index == RecepitsData.order_id.details.orderTicketsData.length - 1
                                                                                                ? `#${i.tiketNumber}`
                                                                                                : `#${i.tiketNumber},`}
                                                                                        </span>
                                                                                    </span>
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </Row>
                                                                ) : null}
                                                                {RecepitsData.order_id.details.deliveryBoyInfo?.name ? (
                                                                    <Row>
                                                                        <span style={{ marginLeft: "10px" }}>
                                                                            Delivery Person : {RecepitsData.order_id.details.deliveryBoyInfo?.name} |{" "}
                                                                            {RecepitsData.order_id.details.deliveryBoyInfo?.phone}{" "}
                                                                        </span>
                                                                    </Row>
                                                                ) : null}

                                                                {RecepitsData && RecepitsData.order_id.details.immediate_sale.multiple_payments_type && (
                                                                    <Row>
                                                                        {RecepitsData.order_id.details.immediate_sale.multiple_payments_type.map((val) => {
                                                                            return (
                                                                                <>
                                                                                    <Col lg={6} md={18} sm={24} offset={0}>
                                                                                        <div className="receipt-payment-transactions">
                                                                                            <p>
                                                                                                {`${rsSymbol}${val.value} on ${
                                                                                                    val.name == "Credit / Debit Card" || val.name == "card"
                                                                                                        ? "Card"
                                                                                                        : val.name
                                                                                                }`}{" "}
                                                                                                {RecepitsData.order_id.details.immediate_sale.card_Details &&
                                                                                                RecepitsData.order_id.details.immediate_sale
                                                                                                    .multiple_payments_type[0].name == "card" ? (
                                                                                                    <Tooltip
                                                                                                        title={
                                                                                                            <div>
                                                                                                                Card Details :
                                                                                                                <br />
                                                                                                                {
                                                                                                                    RecepitsData.order_id.details.immediate_sale
                                                                                                                        .card_Details
                                                                                                                }
                                                                                                            </div>
                                                                                                        }
                                                                                                    >
                                                                                                        {" "}
                                                                                                        <ExclamationCircleOutlined
                                                                                                            style={{
                                                                                                                cursor: "pointer",
                                                                                                            }}
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                                ) : null}
                                                                                                {RecepitsData.order_id.details.immediate_sale.payment_notes &&
                                                                                                RecepitsData.order_id.details.immediate_sale
                                                                                                    .multiple_payments_type[0].name != "cash" &&
                                                                                                RecepitsData.order_id.details.immediate_sale
                                                                                                    .multiple_payments_type[0].name != "card" &&
                                                                                                RecepitsData.order_id.details.immediate_sale
                                                                                                    .multiple_payments_type[0].name != "pending" ? (
                                                                                                    <Tooltip
                                                                                                        title={
                                                                                                            <div>
                                                                                                                Notes :
                                                                                                                <br />
                                                                                                                {
                                                                                                                    RecepitsData.order_id.details.immediate_sale
                                                                                                                        .payment_notes
                                                                                                                }
                                                                                                            </div>
                                                                                                        }
                                                                                                    >
                                                                                                        <ExclamationCircleOutlined
                                                                                                            style={{
                                                                                                                cursor: "pointer",
                                                                                                            }}
                                                                                                        />
                                                                                                    </Tooltip>
                                                                                                ) : null}
                                                                                            </p>
                                                                                            <p className="text-muted">
                                                                                                {commonFunction.convertToDate(
                                                                                                    val.paymentDate ? val.paymentDate : RecepitsData.created_at,
                                                                                                    "MMM DD, Y h:mm A"
                                                                                                )}
                                                                                            </p>
                                                                                        </div>
                                                                                    </Col>
                                                                                </>
                                                                            );
                                                                        })}

                                                                        {RecepitsData && RecepitsData.order_id.cancellation && (
                                                                            <Col lg={6} md={18} sm={24} offset={0}>
                                                                                <div className="receipt-payment-transactions">
                                                                                    <p>{`${rsSymbol}${RecepitsData.order_id.cancellation.refund_amount} ${RecepitsData.order_id.cancellation.refund_pay_type} refund`}</p>
                                                                                    <p className="text-muted">
                                                                                        {commonFunction.convertToDate(
                                                                                            RecepitsData.order_id.cancellation.cancel_Date,
                                                                                            "MMM DD, Y h:mm A"
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                )}
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div style={{ display: "none" }}>{PaymentTypeList.length}</div>
                                                                <UnPaidReceipts
                                                                    deletebuttonShow={deletebuttonShow}
                                                                    RecepitsDataDetails={RecepitsData}
                                                                    PaymentTypeList={PaymentTypeList}
                                                                    updateFetch={fetchRecepitsData}
                                                                />
                                                            </>
                                                        )}
                                                    </div>
                                                )}
                                            </Cards>
                                        </Col>
                                    </Row>
                                </Main>
                            )}
                        </div>
                    ) : (
                        <div>
                            {RecepitsData && (
                                <Main>
                                    <PageHeader
                                        ghost
                                        className="custome-status-header"
                                        title={
                                            <>
                                                <span>
                                                    Status &nbsp;
                                                    {RecepitsData && (
                                                        <>
                                                            {RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                                <Tag color="#43ac6a">Paid</Tag>
                                                            ) : (
                                                                <Tag color="#e99002">Unpaid</Tag>
                                                            )}
                                                            {RecepitsData.order_id.details.fulfillmentStatus == "Fulfilled" && deletebuttonShow == false ? (
                                                                <Tag color="#008cba">Fulfilled</Tag>
                                                            ) : RecepitsData.order_id.details.fulfillmentStatus == "Unfulfilled" &&
                                                              deletebuttonShow == false ? (
                                                                <Tag color="darkgray">Unfulfilled</Tag>
                                                            ) : (
                                                                <Tag color="#f04124">Cancelled</Tag>
                                                            )}
                                                        </>
                                                    )}
                                                </span>
                                                {RecepitsData.order_id.cancellation?.cancellation_reason && (
                                                    <p>Cancellation Notes - {RecepitsData.order_id.cancellation.cancellation_reason}</p>
                                                )}
                                            </>
                                        }
                                        buttons={[
                                            <div key="1" className="page-header-actions">
                                                <Button
                                                    shape="round"
                                                    type="default"
                                                    onClick={() => {
                                                        window.frames["print_frame"].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                            <ReceiptPrint
                                                                title="DUPLICATE"
                                                                receiptsDetails={RecepitsData.order_id}
                                                                shopDetails={shopDetails}
                                                                registerData={registerdata}
                                                                ReceiptNumber={
                                                                    RecepitsData?.order_id?.details?.receipt_number
                                                                        ? RecepitsData?.order_id?.details?.receipt_number
                                                                        : undefined
                                                                }
                                                            />
                                                        );
                                                        window.frames["print_frame"].window.focus();
                                                        window.frames["print_frame"].window.print();
                                                    }}
                                                >
                                                    <FeatherIcon icon="printer" size={14} />
                                                    Print
                                                </Button>
                                                <Button shape="round" type="primary" onClick={() => history.push(`/receipts`)}>
                                                    Go Back
                                                </Button>
                                                {deletebuttonShow ? (
                                                    <Button shape="round" type="primary" onClick={() => setDeleteReceiptsModalVisible(true)}>
                                                        Delete
                                                    </Button>
                                                ) : (
                                                    (userDetails.role == "restaurant" ||
                                                        (userDetails.role == "cashier" &&
                                                            userDetails.has_manager_permission &&
                                                            !getItem("do_not_allow_manager_to_receipt_cancel"))) && (
                                                        <Button shape="round" type="primary" onClick={() => setModalVisibleOrderCancel(true)}>
                                                            Cancel
                                                        </Button>
                                                    )
                                                )}
                                            </div>,
                                        ]}
                                    />

                                    <Row gutter={15}>
                                        <Col md={24}>
                                            <Cards headless>
                                                <InvoiceLetterBox>
                                                    <div className="invoice-letter-inner">
                                                        <Row align="top">
                                                            <Col lg={10} xs={24}>
                                                                <article className="invoice-author">
                                                                    <Heading className="invoice-author__title" as="h3">
                                                                        Invoice
                                                                    </Heading>
                                                                    <p>No : #{RecepitsData.receipt_number}</p>
                                                                    <p className="color-5">
                                                                        Prepared by {RecepitsData.order_id.details.order_by_name.username}{" "}
                                                                        {RecepitsData.order_id.details.tableName != undefined &&
                                                                            ` | ${RecepitsData.order_id.details.tableName}`}
                                                                    </p>
                                                                    <p>
                                                                        on{" "}
                                                                        {RecepitsData?.order_id?.actual_time
                                                                            ? commonFunction.convertToDate(
                                                                                  RecepitsData.order_id.actual_time,
                                                                                  "MMM DD, Y h:mm A"
                                                                              )
                                                                            : commonFunction.convertToDate(RecepitsData.created_at, "MMM DD, Y h:mm A")}
                                                                    </p>
                                                                    <p className="color-5"> {`${RecepitsData.register_id.register_name} Register`}</p>
                                                                </article>
                                                            </Col>

                                                            {RecepitsData.order_id.customer.name ||
                                                            RecepitsData.order_id.customer.email != "" ||
                                                            RecepitsData.order_id.customer.mobile != null ||
                                                            RecepitsData.order_id.customer.city ||
                                                            RecepitsData.order_id.customer.shipping_address ||
                                                            RecepitsData.order_id.customer.zipcode ||
                                                            RecepitsData?.order_id.details.customer_custom_fields?.length > 0 ||
                                                            RecepitsData.order_id.details.custom_fields.length > 0 ? (
                                                                <Col lg={14} xs={24}>
                                                                    <address className="invoice-customer">
                                                                        <Heading className="invoice-customer__title" as="h5">
                                                                            {RecepitsData.order_id.details.fulfillmentStatus == "Unfulfilled" &&
                                                                                deletebuttonShow == false && (
                                                                                    <EditOutlined
                                                                                        onClick={() => editRef.current.showModal()}
                                                                                        style={{ marginRight: "10px" }}
                                                                                    />
                                                                                )}
                                                                            Invoice To:
                                                                        </Heading>

                                                                        {RecepitsData.order_id.customer?.name ||
                                                                        RecepitsData.order_id.customer?.email ||
                                                                        RecepitsData.order_id.customer?.mobile ? (
                                                                            <p>
                                                                                {" "}
                                                                                {RecepitsData.order_id.customer?.name
                                                                                    ? RecepitsData.order_id.customer?.mobile ||
                                                                                      RecepitsData.order_id.customer?.email
                                                                                        ? `${RecepitsData.order_id.customer?.name} | `
                                                                                        : RecepitsData.order_id.customer?.name
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer?.mobile
                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                        ? `${RecepitsData.order_id.customer?.mobile} | `
                                                                                        : RecepitsData.order_id.customer?.mobile
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer?.email
                                                                                    ? RecepitsData.order_id.customer?.email
                                                                                    : null}{" "}
                                                                                {RecepitsData.order_id.customer?.shipping_address ? <br /> : null}
                                                                                {RecepitsData.order_id.customer?.shipping_address
                                                                                    ? RecepitsData.order_id.customer?.city ||
                                                                                      RecepitsData.order_id.customer?.zipcode
                                                                                        ? `${RecepitsData.order_id.customer?.shipping_address} `
                                                                                        : RecepitsData.order_id.customer?.shipping_address
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer.city ? <br /> : null}
                                                                                {RecepitsData.order_id.customer?.city
                                                                                    ? RecepitsData.order_id.customer?.zipcode
                                                                                        ? `${RecepitsData.order_id.customer?.city}`
                                                                                        : RecepitsData.order_id.customer?.city
                                                                                    : null}
                                                                                {RecepitsData.order_id.customer?.zipcode
                                                                                    ? ` - ${RecepitsData.order_id.customer?.zipcode}`
                                                                                    : null}{" "}
                                                                            </p>
                                                                        ) : null}

                                                                        <p className="color-5">
                                                                            {RecepitsData?.order_id.details.customer_custom_fields?.map((data, index) => {
                                                                                if (
                                                                                    RecepitsData.order_id.details.customer_custom_fields.length == 1 &&
                                                                                    data.value != ""
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            {data.name}
                                                                                            {" : "}
                                                                                            <span>{data.value}</span>
                                                                                        </>
                                                                                    );
                                                                                } else if (
                                                                                    index + 1 == RecepitsData.order_id.details.customer_custom_fields.length &&
                                                                                    data.value != ""
                                                                                ) {
                                                                                    return (
                                                                                        <>
                                                                                            {data.name}
                                                                                            {" : "}
                                                                                            <span>{data.value}</span>
                                                                                        </>
                                                                                    );
                                                                                } else if (data.value != "") {
                                                                                    return (
                                                                                        <>
                                                                                            {data.name}
                                                                                            {" : "}
                                                                                            <span>{data.value}</span> {" | "}
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            })}
                                                                        </p>
                                                                        <p className="color-5">
                                                                            {RecepitsData.order_id.details.custom_fields.map((val) => (
                                                                                <Tag color={val.tag_color}>{val.name}</Tag>
                                                                            ))}
                                                                        </p>
                                                                    </address>
                                                                </Col>
                                                            ) : null}
                                                        </Row>
                                                    </div>
                                                </InvoiceLetterBox>
                                                <Modal
                                                    title="Confirm Delete"
                                                    visible={deleteReceiptsModalVisible}
                                                    onCancel={() => setDeleteReceiptsModalVisible(false)}
                                                    cancelText="Go Back"
                                                    onOk={deleteReceipts}
                                                    okText={
                                                        loading ? (
                                                            <Spin
                                                                indicator={
                                                                    <LoadingOutlined
                                                                        style={{
                                                                            fontSize: 16,
                                                                            color: "white",
                                                                            margin: "0px 14px",
                                                                        }}
                                                                        spin
                                                                    />
                                                                }
                                                            />
                                                        ) : (
                                                            "Delete Receipt"
                                                        )
                                                    }
                                                >
                                                    <p>
                                                        Deleting the receipt will permanently remove it and will no longer appear on reports. Also, deleting the
                                                        receipt will keep the metrics as they were after cancellation. Are you sure you want to proceed?
                                                    </p>
                                                </Modal>
                                                <Modal
                                                    title="Confirm Cancelled."
                                                    visible={modalVisibleConfirmCancel}
                                                    footer={[
                                                        <Button type="primary" onClick={() => setModalVisibleConfirmCancel(false)}>
                                                            Ok
                                                        </Button>,
                                                    ]}
                                                >
                                                    <p>Receipt has been cancelled.</p>
                                                </Modal>
                                                <Modal
                                                    title="Confirm Cancel"
                                                    bodyStyle={{ paddingTop: 0, paddingBottom: "12px" }}
                                                    visible={modalVisibleOrderCancel}
                                                    onCancel={() => {
                                                        setModalVisibleOrderCancel(false);
                                                        form.setFieldsValue({
                                                            refund_amount: refundAmount,
                                                        });
                                                    }}
                                                    width={600}
                                                    footer={[
                                                        <Button
                                                            type="default"
                                                            className="btn-cancel btn-custom go_back"
                                                            onClick={() => {
                                                                setModalVisibleOrderCancel(false);
                                                                form.setFieldsValue({
                                                                    refund_amount: refundAmount,
                                                                });
                                                            }}
                                                        >
                                                            Go Back
                                                        </Button>,
                                                        <Button
                                                            type="primary"
                                                            disabled={PaymentType || refundAmount == 0 ? false : true}
                                                            onClick={() => onSubmit()}
                                                        >
                                                            {loading ? (
                                                                <Spin
                                                                    indicator={
                                                                        <LoadingOutlined
                                                                            style={{
                                                                                fontSize: 16,
                                                                                color: "white",
                                                                                margin: "0px 14px",
                                                                            }}
                                                                            spin
                                                                        />
                                                                    }
                                                                />
                                                            ) : (
                                                                "Cancel Receipt"
                                                            )}
                                                        </Button>,
                                                    ]}
                                                >
                                                    <Form style={{ width: "100%" }} name="Export" form={form} onFinish={onSubmit}>
                                                        <Form.Item
                                                            name="refund_amount"
                                                            label="Enter Refund Amount"
                                                            rules={[
                                                                {
                                                                    validator: (_, value) => {
                                                                        if (Number(value) >= 0) {
                                                                            return Promise.resolve();
                                                                        } else {
                                                                            return Promise.reject("Refund price should be a positive number.");
                                                                        }
                                                                    },
                                                                },
                                                                {
                                                                    validator: (_, value) => {
                                                                        if (Number(value) > refundAmount) {
                                                                            return Promise.reject("Refund amount cannot be more than the paid amount.");
                                                                        } else {
                                                                            return Promise.resolve();
                                                                        }
                                                                    },
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Refund Amount" type="number" />
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="refund_pay_type"
                                                            label="Payment Type"
                                                            rules={[
                                                                {
                                                                    message: "Choose a payment type to proceed",
                                                                    required: refundAmount > 0 ? true : false,
                                                                },
                                                            ]}
                                                        >
                                                            <Radio.Group
                                                                onChange={(e) => setPaymentType(e.target.value)}
                                                                value={PaymentType}
                                                                className="tick-radio"
                                                            >
                                                                <Radio.Button
                                                                    value="cash"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {PaymentType === "cash" ? (
                                                                        <svg
                                                                            width="13px"
                                                                            style={{ marginRight: "2px" }}
                                                                            viewBox="0 0 123 102"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                fill="#BE3D5D"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    Cash
                                                                </Radio.Button>
                                                                <Radio.Button
                                                                    value="card"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {PaymentType === "card" ? (
                                                                        <svg
                                                                            width="13px"
                                                                            style={{ marginRight: "2px" }}
                                                                            viewBox="0 0 123 102"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                fill="#BE3D5D"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        ""
                                                                    )}{" "}
                                                                    Credit / Debit Card
                                                                </Radio.Button>
                                                                {PaymentTypeList.map((val, index) => {
                                                                    return (
                                                                        <Radio.Button
                                                                            value={val.name}
                                                                            style={{
                                                                                marginRight: "10px",
                                                                                marginBottom: "10px",
                                                                            }}
                                                                        >
                                                                            {PaymentType === val.name ? (
                                                                                <svg
                                                                                    width="13px"
                                                                                    style={{ marginRight: "2px" }}
                                                                                    viewBox="0 0 123 102"
                                                                                    fill="none"
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                >
                                                                                    <path
                                                                                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                        fill="#BE3D5D"
                                                                                    />
                                                                                </svg>
                                                                            ) : (
                                                                                ""
                                                                            )}
                                                                            {val.name}
                                                                        </Radio.Button>
                                                                    );
                                                                })}
                                                                <Radio.Button
                                                                    value="other"
                                                                    style={{
                                                                        marginRight: "10px",
                                                                        marginBottom: "10px",
                                                                    }}
                                                                >
                                                                    {PaymentType === "other" ? (
                                                                        <svg
                                                                            width="13px"
                                                                            style={{ marginRight: "2px" }}
                                                                            viewBox="0 0 123 102"
                                                                            fill="none"
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                        >
                                                                            <path
                                                                                d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                                                                                fill="#BE3D5D"
                                                                            />
                                                                        </svg>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                    Other
                                                                </Radio.Button>
                                                            </Radio.Group>
                                                        </Form.Item>
                                                        <Form.Item
                                                            name="cancellation_reason"
                                                            label="Cancellation Notes2"
                                                            rules={[
                                                                {
                                                                    max: 50,
                                                                    message: "Cancellation Notes cannot be more than 50 characters long.",
                                                                },
                                                            ]}
                                                        >
                                                            <Input placeholder="Cancellation notes" />
                                                        </Form.Item>
                                                    </Form>
                                                </Modal>
                                                <br />
                                                {RecepitsData?.order_id?.details?.priceSummery?.orderCacel ? (
                                                    <>
                                                        <Row style={{ textAlign: "center" }}>
                                                            <p>All items were removed. 33</p>
                                                        </Row>

                                                        <Row>
                                                            Order Tickets :
                                                            {RecepitsData.order_id.details.orderTicketsData?.map((i, index) => {
                                                                let privewsOrderTiket = [];
                                                                RecepitsData.order_id.details.orderTicketsData
                                                                    .slice(0, index)
                                                                    .map((val) => privewsOrderTiket.push(val.tiketNumber));
                                                                return (
                                                                    <div>
                                                                        <span>
                                                                            <span
                                                                                style={{
                                                                                    color: "rgb(0, 140, 186)",
                                                                                    cursor: "pointer",
                                                                                }}
                                                                                onClick={() => {
                                                                                    window.frames[
                                                                                        "print_frame"
                                                                                    ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                                        <OrderTicketPrint
                                                                                            categoryDetails={i}
                                                                                            PreviousTikets={privewsOrderTiket}
                                                                                            ReceiptNumber={i.receiptNumberDetails?.number}
                                                                                            TableName={i?.table_name ? i?.table_name : ""}
                                                                                        />
                                                                                    );
                                                                                    window.frames["print_frame"].window.focus();
                                                                                    window.frames["print_frame"].window.print();
                                                                                }}
                                                                            >
                                                                                {index == RecepitsData.order_id.details.orderTicketsData.length - 1
                                                                                    ? `#${i.tiketNumber}`
                                                                                    : `#${i.tiketNumber},`}
                                                                            </span>
                                                                        </span>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Row>
                                                        <Row>
                                                            <Col lg={22} md={18} sm={24} offset={0}>
                                                                {RecepitsData && RecepitsData.order_id.details.bookingDetails ? (
                                                                    <>
                                                                        <span>
                                                                            <span className="other-details">Booking Details</span>
                                                                            {`${RecepitsData.order_id.details.bookingDetails.delivery_date} , ${RecepitsData.order_id.details.bookingDetails.delivery_time}`}
                                                                            {RecepitsData.order_id.details.bookingDetails.is_door_delivery
                                                                                ? " | Door Delivery"
                                                                                : null}
                                                                        </span>
                                                                    </>
                                                                ) : null}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {RecepitsData?.order_id?.details?.bookingDetails?.booking_advance_payment_type?.map((val) => {
                                                                return (
                                                                    <>
                                                                        <Col lg={4} md={18} sm={24} offset={0}>
                                                                            <div className="receipt-payment-transactions">
                                                                                <p>{`${rsSymbol}${val.value} on Card`}</p>
                                                                                <p className="text-muted">
                                                                                    {commonFunction.convertToDate(val.bookingDate, "MMM DD, Y h:mm A")}
                                                                                </p>
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                );
                                                            })}

                                                            {RecepitsData &&
                                                                RecepitsData.order_id.cancellation &&
                                                                RecepitsData.order_id.cancellation.refund_amount > 0 && (
                                                                    <Col lg={4} md={18} sm={24} offset={0}>
                                                                        <div className="receipt-payment-transactions">
                                                                            <p>{`${rsSymbol}${RecepitsData.order_id.cancellation.refund_amount} ${RecepitsData.order_id.cancellation.refund_pay_type} refund`}</p>
                                                                            <p className="text-muted">
                                                                                {RecepitsData?.order_id?.actual_time
                                                                                    ? commonFunction.convertToDate(
                                                                                          RecepitsData.order_id.actual_time,
                                                                                          "MMM DD, Y h:mm A"
                                                                                      )
                                                                                    : commonFunction.convertToDate(RecepitsData.created_at, "MMM DD, Y h:mm A")}
                                                                            </p>
                                                                        </div>
                                                                    </Col>
                                                                )}
                                                        </Row>
                                                    </>
                                                ) : (
                                                    <>
                                                        <ProductTable>
                                                            <div className="table-invoice table-responsive">
                                                                <Table
                                                                    dataSource={dataSource}
                                                                    columns={invoiceTableColumns}
                                                                    pagination={false}
                                                                    rowClassName="invoice-table"
                                                                />
                                                            </div>
                                                        </ProductTable>
                                                        <Row justify="end" style={{ paddingRight: "17px" }}>
                                                            <Col xxl={4} xl={5} sm={8} xs={14} offset={rtl ? 0 : 10}>
                                                                <OrderSummary>
                                                                    <div className="invoice-summary-inner">
                                                                        <ul className="summary-list">
                                                                            <li>
                                                                                <span className="summary-list-title">Subtotal :</span>
                                                                                <span className="summary-list-text">
                                                                                    {`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.sub_total}`}
                                                                                </span>
                                                                            </li>
                                                                            {RecepitsData?.order_id.details.priceSummery.totalItemDisocunts > 0 && (
                                                                                <li>
                                                                                    <span className="summary-list-title">Item Discounts :</span>
                                                                                    <span className="summary-list-text">
                                                                                        {`-${rsSymbol}${RecepitsData?.order_id.details.priceSummery.totalItemDisocunts}`}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                            {RecepitsData?.order_id?.details?.bulckDiscountValue && (
                                                                                <li>
                                                                                    <span className="summary-list-title">
                                                                                        Bulk Discount{" "}
                                                                                        {RecepitsData?.order_id?.details?.bingageDetails && (
                                                                                            <Tooltip title={<div>Bingage wallet</div>}>
                                                                                                <ExclamationCircleOutlined
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                    }}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        )}
                                                                                    </span>

                                                                                    <span className="summary-list-text">
                                                                                        {`-${rsSymbol}${RecepitsData?.order_id?.details?.bulckDiscountValue}`}
                                                                                    </span>
                                                                                </li>
                                                                            )}
                                                                            {RecepitsData?.order_id?.details?.AddtionChargeValue?.length > 0 &&
                                                                                RecepitsData?.order_id?.details?.AddtionChargeValue.map(
                                                                                    (charge) =>
                                                                                        charge.is_automatically_added && (
                                                                                            <li>
                                                                                                <span className="summary-list-title">
                                                                                                    {charge.charge_name}{" "}
                                                                                                    {charge.tax_group &&
                                                                                                        `(Tax ${charge.tax_group.Totaltax}%) :`}
                                                                                                </span>

                                                                                                <span className="summary-list-text">
                                                                                                    {rsSymbol}
                                                                                                    {Number(charge.AddtionalCalculatedValue).toFixed(2)}
                                                                                                </span>
                                                                                            </li>
                                                                                        )
                                                                                )}
                                                                            {RecepitsData?.order_id.details.priceSummery.taxexArray &&
                                                                                RecepitsData?.order_id.details.priceSummery.taxexArray.map((val) => {
                                                                                    return (
                                                                                        <li>
                                                                                            <span className="summary-list-title">{val.name} :</span>
                                                                                            <span className="summary-list-text">{`${rsSymbol}${Number(
                                                                                                val.value
                                                                                            ).toFixed(2)}`}</span>
                                                                                        </li>
                                                                                    );
                                                                                })}
                                                                            {RecepitsData?.order_id.details.priceSummery.round_off_value && (
                                                                                <li>
                                                                                    <span className="summary-list-title">Roundoff :</span>
                                                                                    <span className="summary-list-text">{`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.round_off_value}`}</span>
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                        <Heading className="summary-total" as="h4">
                                                                            <span className="summary-total-label">Total : </span>
                                                                            <span className="summary-total-amount">{`${rsSymbol}${RecepitsData?.order_id.details.priceSummery.total}`}</span>
                                                                        </Heading>
                                                                    </div>
                                                                </OrderSummary>
                                                            </Col>
                                                            <Col></Col>
                                                        </Row>

                                                        {RecepitsData && RecepitsData.order_id.details.paymentStatus == "paid" ? (
                                                            <>
                                                                <div className="border-top">
                                                                    {RecepitsData.order_id.details.orderTicketsData ? (
                                                                        <Row>
                                                                            Order Tickets :
                                                                            {RecepitsData.order_id.details.orderTicketsData.map((i, index) => {
                                                                                let privewsOrderTiket = [];
                                                                                RecepitsData.order_id.details.orderTicketsData
                                                                                    .slice(0, index)
                                                                                    .map((val) => privewsOrderTiket.push(val.tiketNumber));
                                                                                return (
                                                                                    <div>
                                                                                        <span>
                                                                                            <span
                                                                                                style={{
                                                                                                    color: "rgb(0, 140, 186)",
                                                                                                    cursor: "pointer",
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    window.frames[
                                                                                                        "print_frame"
                                                                                                    ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
                                                                                                        <OrderTicketPrint
                                                                                                            categoryDetails={i}
                                                                                                            PreviousTikets={privewsOrderTiket}
                                                                                                            ReceiptNumber={i.receiptNumberDetails?.number}
                                                                                                            TableName={i?.table_name ? i?.table_name : ""}
                                                                                                        />
                                                                                                    );
                                                                                                    window.frames["print_frame"].window.focus();
                                                                                                    window.frames["print_frame"].window.print();
                                                                                                }}
                                                                                            >
                                                                                                {index ==
                                                                                                RecepitsData.order_id.details.orderTicketsData.length - 1
                                                                                                    ? `#${i.tiketNumber}`
                                                                                                    : `#${i.tiketNumber},`}
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        </Row>
                                                                    ) : null}
                                                                    <Row>
                                                                        <Col lg={22} md={18} sm={24} offset={0}>
                                                                            {RecepitsData && RecepitsData.order_id.details.bookingDetails ? (
                                                                                <>
                                                                                    <span>
                                                                                        <span className="other-details">Booking Details</span>
                                                                                        {`${RecepitsData.order_id.details.bookingDetails.delivery_date} , ${RecepitsData.order_id.details.bookingDetails.delivery_time}`}
                                                                                        {RecepitsData.order_id.details.bookingDetails.is_door_delivery
                                                                                            ? " | Door Delivery"
                                                                                            : null}
                                                                                    </span>
                                                                                </>
                                                                            ) : null}
                                                                        </Col>

                                                                        {RecepitsData.order_id.details.fulfillmentStatus == "Unfulfilled" && (
                                                                            <Col lg={2} md={18} sm={24} offset={0}>
                                                                                <span
                                                                                    style={{
                                                                                        paddingTop: "15px",
                                                                                        color: "#008cba",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                    onClick={() => editRef.current.showModal()}
                                                                                >
                                                                                    Edit1
                                                                                </span>
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                    <Row>
                                                                        <Col lg={12} md={18} sm={24} offset={0}>
                                                                            {RecepitsData && RecepitsData.order_id.details.bookingDetails.booking_notes ? (
                                                                                <>
                                                                                    <span>
                                                                                        <span className="other-details">Booking Notes</span>
                                                                                        {`${RecepitsData.order_id.details.bookingDetails.booking_notes}`}
                                                                                    </span>
                                                                                </>
                                                                            ) : null}
                                                                        </Col>
                                                                    </Row>
                                                                </div>

                                                                <div className="border-top">
                                                                    <Row>
                                                                        {RecepitsData.order_id.details.bookingDetails.booking_advance_payment_type.map(
                                                                            (val) => {
                                                                                if (val.name != false) {
                                                                                    return (
                                                                                        <Col lg={6} md={18} sm={24} offset={0}>
                                                                                            <div className="receipt-payment-transactions">
                                                                                                <p>{`${rsSymbol}${val.value} on ${val.name}`}</p>
                                                                                                <p className="text-muted">
                                                                                                    {val.bookingDate
                                                                                                        ? commonFunction.convertToDate(
                                                                                                              val.bookingDate,
                                                                                                              "MMM DD, Y h:mm A"
                                                                                                          )
                                                                                                        : commonFunction.convertToDate(
                                                                                                              val.paymentDate,
                                                                                                              "MMM DD, Y h:mm A"
                                                                                                          )}
                                                                                                </p>
                                                                                            </div>
                                                                                        </Col>
                                                                                    );
                                                                                }
                                                                            }
                                                                        )}
                                                                        {RecepitsData && RecepitsData.order_id.cancellation && (
                                                                            <Col lg={6} md={18} sm={24} offset={0}>
                                                                                <div className="receipt-payment-transactions">
                                                                                    <p>{`${rsSymbol}${RecepitsData.order_id.cancellation.refund_amount} ${RecepitsData.order_id.cancellation.refund_pay_type} refund`}</p>
                                                                                    <p className="text-muted">
                                                                                        {commonFunction.convertToDate(
                                                                                            RecepitsData.order_id.cancellation.cancel_Date,
                                                                                            "MMM DD, Y h:mm A"
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            </Col>
                                                                        )}
                                                                    </Row>
                                                                    {RecepitsData.order_id.details.fulfillmentStatus == "Unfulfilled" && (
                                                                        <div className="border-top">
                                                                            <Row style={{ padding: "10px" }}>
                                                                                <Col lg={12} md={18} sm={24} offset={0}>
                                                                                    <Button type="primary" onClick={fullFillOrder}>
                                                                                        Fulfil
                                                                                    </Button>
                                                                                </Col>
                                                                            </Row>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <div style={{ display: "none" }}>{PaymentTypeList.length}</div>
                                                                <UnpaidBookingReceipts
                                                                    RecepitsDataDetails={RecepitsData}
                                                                    PaymentTypeList={PaymentTypeList}
                                                                    updateFetch={fetchRecepitsData}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Cards>
                                        </Col>
                                    </Row>
                                    <EditBookingReceipts ref={editRef} RecepitsDataDetails={RecepitsData} updateFetch={fetchRecepitsData} />
                                </Main>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <Main>
                    <Spin
                        style={{
                            color: "#BD025D",
                            position: "absolute",
                            marginLeft: "48%",
                            marginTop: "21%",
                            transform: "translate(-50%,-50%)",
                        }}
                    />
                </Main>
            )}
        </div>
    );
};

export default Invoice;
